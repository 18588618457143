// @ts-nocheck

import React, { Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Switch, Route, Redirect, useLocation } from "react-router-dom";
//import logo from './logo.svg';
import "./App.scss";
import Loading from "components/utils/Loading";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import useUser from "utils/hooks/useUser";
import PageTracker from "./utils/hooks/PageTracker";
//import useFacebookAuth from 'utils/hooks/useFacebookAuth';

//import AnimatedSwitch from 'utils/animatedSwitch';
//import Page from 'components/utils/Page';
import ErrorFallback from "components/utils/ErrorFallback";
import { useTranslation } from "react-i18next";
import { A2HSProvider } from "utils/hooks/useA2HS";
import { CookieConsentProvider } from "utils/hooks/useCookieConsent";
import "./decs.d.ts";

const CookiePolicyPage = React.lazy(
  () => import("components/main/CookiePolicyPage")
);
const PrivateRoute = React.lazy(() => import("components/utils/PrivateRoute"));
const LoginPage = React.lazy(() => import("components/login/LoginPage"));
const HomePage = React.lazy(() => import("components/main/HomePage"));
//const LandingPage = React.lazy(() => import('components/main/LandingPage'));
const MenuPage = React.lazy(() => import("components/main/MenuPage"));
const Profile = React.lazy(() => import("components/main/Profile"));
const UserStatPage = React.lazy(() => import("components/main/UserStatPage"));
const CompanyLoaderPage = React.lazy(
  () => import("components/main/CompanyLoaderPage")
);
const TermsPage = React.lazy(() => import("components/main/TermsPage"));
const PrivacyPage = React.lazy(() => import("components/main/PrivacyPage"));
const Unsubscribe = React.lazy(() => import("components/main/Unsubscribe"));

function App() {
  //useFacebookAuth("XXXX", true)
  const { pathname } = useLocation();
  const { user } = useUser();
  const { i18n } = useTranslation();
  //const { isAppInstalled, isAppInstallable, deferredPrompt: installApp } = useA2HS();
  //console.log("useA2HS", isAppInstalled, isAppInstallable, installApp)

  let user_lang = user?.profile?.language;
  useEffect(() => {
    if (user_lang && user_lang !== i18n.language) {
      i18n.changeLanguage(user_lang);
    }
  }, [i18n, user_lang]); // update on user change
  //console.log("current language", i18n.language, user?.profile?.language)

  return (
    <CookieConsentProvider>
      <PageTracker>
        <A2HSProvider>
          <Suspense fallback={<Loading />}>
            {/* <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error: Error, info: {componentStack: string})=>console.log("ERROR", error.message, info)}> */}
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
              limit={5}
            />

            <Switch>
              {/* removing trailing slash         */}
              <Redirect from="/app/:url*(/+)" to={pathname.slice(0, -1)} />

              <Route path="/app/terms">
                <TermsPage />
              </Route>

              <Route path="/app/privacy">
                <PrivacyPage />
              </Route>

              <Route path="/app/cookiePolicy">
                <CookiePolicyPage />
              </Route>

              <Route path="/app/unsubscribe">
                <Unsubscribe />
              </Route>

              <Route path="/app/login">
                <LoginPage />
              </Route>
              {/* MAIN PAGES         */}
              <PrivateRoute path="/app/profile">
                <Profile />
              </PrivateRoute>

              <Route path="/app/stats">
                <UserStatPage />
              </Route>

              <Route path="/app/home">
                <HomePage />
              </Route>

              <Route path="/app/menu/:uuid">
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onError={(error: Error, info: { componentStack: string }) =>
                    console.log("ERROR", error.message, info)
                  }
                >
                  <MenuPage />
                </ErrorBoundary>
              </Route>

              <Route path="/app/resto/:company_uuid/menu">
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onError={(error: Error, info: { componentStack: string }) =>
                    console.log("ERROR", error.message, info)
                  }
                >
                  <MenuPage />
                </ErrorBoundary>
              </Route>

              <Route path="/app/company/:uuid">
                <CompanyLoaderPage />
              </Route>

              {/* SUB PAGES         */}
              {/* <RouteUnderConstruction enabled exact path={`${path}/species/:id/`} name={"Espèces"} description={
              "Une fois terminé, ce module vous permetra de voir et d'éditer vos espèces de plantes:  informations pour prendre soins de vos espèces de plantes, \
              pour les multiplier, mettre des rappel calendriers, ou des alertes météos, etc."
            }>
              <SpeciesDetail />
          </RouteUnderConstruction> */}

              <Route path="/">
                {/* <LandingPage /> */}
                <HomePage />
              </Route>
            </Switch>
          </Suspense>
        </A2HSProvider>
      </PageTracker>
    </CookieConsentProvider>
  );
}

export default App;
