import React, { ReactNode } from "react";
import Header from "./Header";
import ErrorFallback from "components/utils/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { version } from "version.json";

interface Props {
  id?: string;
  topElement?: ReactNode;
  topHeight?: number;
  children: ReactNode;
  format?: string;
  mainPage?: boolean;
  showHeader?: boolean;
  showScroll?: boolean;
  autoHeight?: boolean;
  style?: object;
  [x: string]: any;
  resetErrorBoundary?: any;
  title?: string;
}

function Page(props: Props) {
  let {
    id,
    children,
    format,
    mainPage,
    showHeader,
    showScroll,
    style,
    autoHeight,
    topElement,
    topHeight,
    title,
    resetErrorBoundary,
    ...otherProps
  } = props;
  const { t } = useTranslation();

  let maxWidth = format === "narrow" ? "768px" : "100%";

  if (topElement === undefined) {
    topHeight = 0;
    topElement = null;
  }
  topHeight = topHeight !== undefined ? topHeight : 0;

  let headerHeight = showHeader ? 60 : 0;
  let footerHeight = 25;
  let contentHeight = `calc(100% - ${headerHeight}px - ${topHeight}px)`;
  let header = showHeader ? (
    <Header
      style={{ height: headerHeight, maxWidth: maxWidth }}
      title={title}
      resetErrorBoundary={resetErrorBoundary}
    />
  ) : null;

  //let Container = (props:any)=>showScroll?(<ScrollPanel {...props}/>):(<div  {...props}/>)
  let content = props.children;
  let footer = mainPage ? (
    <>
      <div style={{ flex: 1 }} />
      <div
        style={{
          width: "100%",
          minHeight: footerHeight,
          display: "flex",
          justifyContent: "space-between",
          borderTop: "solid 1px black",
          marginTop: "10px",
        }}
      >
        <div>© 2021 Youmeal SA (v{version})</div>
        <div></div>
        <div>
          <Link to="/app/terms">{t("Terms of service")}</Link>
        </div>
        <div>
          <Link to="/app/privacy">{t("Privacy")}</Link>
        </div>
      </div>
    </>
  ) : null;

  if (showScroll) {
    content = (
      <div
        id={id}
        className={"scrollContainer"}
        style={{
          height: contentHeight,
          width: "100%",
          overflowY: "auto",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div
          className={(mainPage ? "App-main" : "") + " Page"}
          style={{
            maxWidth: maxWidth,
            height: autoHeight ? "auto" : "100%",
            justifyContent: "space-between",
            ...style,
          }}
          {...otherProps}
        >
          {content}
          {footer}
        </div>
      </div>
    );
  } else {
    content = (
      <div
        className={(mainPage ? "App-main" : "") + " Page"}
        style={{ height: contentHeight, maxWidth: maxWidth, ...style }}
        {...otherProps}
      >
        {content}
        {footer}
      </div>
    );
  }

  if (!mainPage) {
    return (
      <React.Fragment>
        {header}
        {topElement}
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={(error: Error, info: { componentStack: string }) =>
            console.log("ERROR", error.message, info)
          }
        >
          {content}
        </ErrorBoundary>
      </React.Fragment>
    );
  }

  return (
    <div className={"App"}>
      {header}
      {topElement}
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error: Error, info: { componentStack: string }) =>
          console.log("ERROR", error.message, info)
        }
      >
        {content}
      </ErrorBoundary>
    </div>
  );
}

export default Page;
