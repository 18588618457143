import React from "react";
import { Navbar, NavDropdown } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { FaBars, FaSignInAlt, FaSignOutAlt } from "react-icons/fa";
import { ImPacman } from "react-icons/im";
import { MdAccountBox, MdGetApp, MdRestaurant } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import useA2HS from "utils/hooks/useA2HS";
import useUser from "utils/hooks/useUser";

interface Props {
  style: object;
  title?: string;
  resetErrorBoundary?: any;
}

interface NavItemProps {
  className?: string;
  style?: React.CSSProperties;
  text?: string;
  Icon?: React.ElementType;
  img?: string;
  to?: string | { pathname: string; state?: unknown };
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  asDropdownItem?: boolean;
  resetErrorBoundary?: any;
}

function NavItem(props: NavItemProps) {
  let history = useHistory();
  let location = useLocation();
  const {
    Icon,
    img,
    text,
    to,
    onClick,
    style,
    disabled,
    className,
    asDropdownItem,
    resetErrorBoundary,
  } = props;
  const iheight = text ? `70%` : `100%`;
  const theight = asDropdownItem ? undefined : "10px";

  const current_path = typeof to === "string" ? to : to?.pathname;
  const active: boolean =
    (current_path &&
      location.pathname.toLowerCase().startsWith(current_path.toLowerCase())) ||
    false;

  let buttonAction;
  if (onClick) buttonAction = onClick;
  else if (to)
    buttonAction = () => {
      resetErrorBoundary && resetErrorBoundary();
      to && history.push(to);
    };

  const OutContainer = asDropdownItem ? NavDropdown.Item : Nav.Item;

  return (
    <OutContainer className={className} style={{ height: "100%", ...style }}>
      <Button
        disabled={disabled}
        active={active}
        variant="outline-dark"
        style={{ height: "100%", border: 0 }}
        onClick={buttonAction}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            flexDirection: asDropdownItem ? "row" : "column",
          }}
        >
          <div style={{ height: iheight }}>
            {img ? (
              <img
                src={img}
                alt="logo"
                style={{ height: "100%", width: "auto" }}
              />
            ) : null}
            {Icon ? <Icon style={{ height: "100%", width: "auto" }} /> : null}
          </div>
          {text ? (
            <div style={{ fontSize: theight, fontWeight: "bolder" }}>
              &nbsp;{text}
            </div>
          ) : null}
        </div>
      </Button>
    </OutContainer>
  );
}

function Header(props: Props) {
  let location = useLocation();
  let { user, logoutUser } = useUser();
  const { t } = useTranslation();
  const { isAppInstallable, isAppInstalled, installApp } = useA2HS();
  //console.log(isAppInstallable, isAppInstalled, installApp);
  let { style, title, resetErrorBoundary } = props;

  //let colorOverwrite = "#FFFFFF"// window.location.href.match(/soft.youmeal.net/)?undefined:"#e49999"
  let colorOverwrite = window.location.href.match(/soft.youmeal.net/)
    ? "#56ba5b"
    : "#e49999";

  return (
    <header
      className="App-header"
      style={{ backgroundColor: colorOverwrite, color: "#FFFFFF" }}
    >
      <div
        style={{
          ...style,
          padding: "0px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Nav
          style={{
            height: "100%",
            width: "100%",
            alignContent: "center",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <NavItem img={"/app/logo512.png"} to={"/app/home/"} /> */}
          <Navbar.Text
            className="ellipsis"
            style={{
              flex: "1",
              textAlign: "left",
              fontSize: "1.5rem",
              paddingLeft: "5px",
            }}
          >
            {title || ""}
          </Navbar.Text>
          <NavDropdown
            className="no-caret no-caret-white"
            title={<FaBars size="2.0rem" />}
            id="burger-dropdown"
            style={{ padding: "0px" }}
          >
            <NavItem
              asDropdownItem
              resetErrorBoundary={resetErrorBoundary}
              text={"Restaurants"}
              Icon={MdRestaurant}
              to={"/app/home/"}
            />
            <NavItem
              asDropdownItem
              resetErrorBoundary={resetErrorBoundary}
              disabled={false}
              text={"Stats"}
              Icon={ImPacman}
              to={"/app/stats"}
            />
            <NavItem
              asDropdownItem
              resetErrorBoundary={resetErrorBoundary}
              disabled={false}
              text={"Profile"}
              Icon={MdAccountBox}
              to={"/app/profile"}
            />
            {user && (
              <NavItem
                asDropdownItem
                text={"Logout"}
                resetErrorBoundary={resetErrorBoundary}
                Icon={FaSignOutAlt}
                onClick={logoutUser}
              />
            )}
            {!user && (
              <NavItem
                asDropdownItem
                text={"Login"}
                resetErrorBoundary={resetErrorBoundary}
                className={"anim-tada"}
                Icon={FaSignInAlt}
                to={{ pathname: "/app/login", state: { from: location } }}
              />
            )}
            <NavDropdown.Divider />
            <NavItem
              asDropdownItem
              text={t("install_app")}
              disabled={isAppInstalled || !(isAppInstallable && installApp)}
              resetErrorBoundary={resetErrorBoundary}
              Icon={MdGetApp}
              onClick={() => installApp.prompt()}
            />
          </NavDropdown>
        </Nav>
      </div>
    </header>
  );
}

export default Header;
