import React from "react";
import { FallbackProps } from "react-error-boundary";
import "../../Animation.scss";
import { useTranslation, Trans } from "react-i18next";
import { ApiError } from "api";
import { Button, Row } from "react-bootstrap";
import Page from "./Page";
import { useHistory } from "react-router-dom";
import { FaExclamationTriangle, FaBan } from "react-icons/fa";

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const apiError = error instanceof ApiError ? (error as ApiError) : undefined;

  let content = null;
  if (
    apiError?.status === 404 &&
    apiError?.url.match(/\/api\/v3\/public\/menu\/.*?\/company\//i)
  ) {
    content = (
      <>
        <h2>
          <Trans t={t} i18nKey="menu_not_found">
            Menu not found
          </Trans>
        </h2>
        <p style={{ fontSize: "Larger" }}>
          <Trans t={t} i18nKey="menu_not_found_details">
            There is no menu available at the restaurant for the current week
          </Trans>
        </p>

        <br />
        <FaBan size="40%" />
        <br />

        <Button variant="secondary" onClick={() => history.goBack()}>
          <Trans t={t} i18nKey="go_back">
            Go back
          </Trans>
        </Button>
      </>
    );
  } else if (apiError?.status === 404) {
    content = (
      <>
        <h2>
          <Trans t={t} i18nKey="page_not_found">
            Page not found
          </Trans>
        </h2>

        <img src="/app/img/404_Error.jpg" alt="404" style={{ width: "75%" }} />

        <Button variant="secondary" onClick={() => history.goBack()}>
          <Trans t={t} i18nKey="go_back">
            Go back
          </Trans>
        </Button>
      </>
    );
  } else {
    content = (
      <>
        <h2>
          <Trans t={t} i18nKey="ooops">
            Oooops... Something went wrong:
          </Trans>
        </h2>
        <pre style={{ color: "red", whiteSpace: "break-spaces" }}>
          {error.message}
        </pre>

        <br />
        <FaExclamationTriangle size="50%" style={{ fill: "red" }} />
        <br />

        <Button variant="secondary" onClick={resetErrorBoundary}>
          <Trans t={t} i18nKey="try_again">
            Try again
          </Trans>
        </Button>
      </>
    );
  }

  return (
    <Page
      mainPage
      showHeader
      format="narrow"
      title={"Error"}
      style={{ justifyContent: "center", color: "black" }}
      resetErrorBoundary={resetErrorBoundary}
    >
      <Row style={{ paddingBottom: "10px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "30px",
          }}
        >
          {content}
        </div>
      </Row>
    </Page>
  );
}

export default ErrorFallback;
