import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useCookieConsent from "./useCookieConsent";

const GA_TRACKING_ID = "G-1TLHSJLFCS";

interface Props {
  children: ReactNode;
}

export const PageTracker = (props: Props) => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const { allowCookie } = useCookieConsent();

  useEffect(() => {
    if (allowCookie) {
      //if (!window.location.href.includes("localhost")) {
      gtag("js", new Date());
      gtag("config", GA_TRACKING_ID, { app_name: "youmeal-app" });

      setInitialized(true);
    }
  }, [allowCookie]);

  useEffect(() => {
    if (initialized) {
      gtag("event", "screen_view", {
        screen_name: location.pathname + location.search,
      });
    }
  }, [initialized, location]);

  return <>{props.children}</>;
};

export default PageTracker;
