import React, {
  useEffect,
  useState,
  useContext,
  ReactNode,
  createContext,
} from "react";

const A2HSContext = createContext({
  isAppInstallable: false,
  isAppInstalled: false,
  installApp: { prompt: () => {} },
});

interface Props {
  children: ReactNode;
}

export const A2HSProvider = function (props: Props) {
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [isAppInstallable, setIsAppInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>();
  const { children } = props;

  window.addEventListener("beforeinstallprompt", (e) => {
    setIsAppInstallable(true);
    setDeferredPrompt(e as any);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    //e.preventDefault()
  });

  window.addEventListener("appinstalled", (evt) => {
    setIsAppInstalled(true);
  });

  useEffect(() => {}, []);

  // {
  const contextData = {
    isAppInstalled: isAppInstalled,
    isAppInstallable: isAppInstallable,
    installApp: deferredPrompt,
  };

  return (
    <A2HSContext.Provider value={contextData}>{children}</A2HSContext.Provider>
  );
};

// Hook
export const useA2HS = () => useContext(A2HSContext);

export default useA2HS;
