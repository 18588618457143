import * as immutable from "object-path-immutable";
import { RootState } from "./state";

interface Action {
  type: string;
  stateLoc?: string | Array<string>;
  [x: string]: any;
}

const initialState: RootState = {};

const rootReducer = (state: RootState = initialState, action: Action) => {
  switch (action.type) {
    case "GLOBAL_SET":
      return immutable.set(state, action.stateLoc, action.data);
    case "GLOBAL_MERGE":
      return immutable.merge(state, action.stateLoc, action.data);
    case "GLOBAL_DEL":
      return immutable.del(state, action.stateLoc);
    default:
      return state;
  }
};

export default rootReducer;
