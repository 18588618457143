import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import store from "./store/index";
import "./i18n";

/// FORMS ARE MADE WITH https://react-hook-form.com/get-started

import { version } from "./version.json";
console.log(`v${version}`);

const FullApp = () => (
  <React.StrictMode>
    <Provider store={store}>
      <Router basename="/">
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);

const root = document.getElementById("root")!; //! is there to specify it is never null

//enabling concurent mode
ReactDOM.unstable_createRoot(root).render(<FullApp />);

//disabling concurent mode
//ReactDOM.render(<FullApp />, root );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register();
