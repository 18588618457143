/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { Allergen } from './models/Allergen';
export type { ChangePassword } from './models/ChangePassword';
export type { ChangePasswordRequest } from './models/ChangePasswordRequest';
export type { ColorE35Enum } from './models/ColorE35Enum';
export type { Company } from './models/Company';
export type { CompanyAddressSchema } from './models/CompanyAddressSchema';
export type { CompanyAddressSchemaList } from './models/CompanyAddressSchemaList';
export type { CompanyFullSchema } from './models/CompanyFullSchema';
export type { CompanyRequest } from './models/CompanyRequest';
export type { CompanyTypeEnum } from './models/CompanyTypeEnum';
export type { Concept } from './models/Concept';
export type { ConceptRequest } from './models/ConceptRequest';
export type { Concepts2Ingredient } from './models/Concepts2Ingredient';
export type { Concepts2IngredientRequest } from './models/Concepts2IngredientRequest';
export type { ConceptType } from './models/ConceptType';
export type { ConceptTypeRequest } from './models/ConceptTypeRequest';
export type { ConceptTypeTypeEnum } from './models/ConceptTypeTypeEnum';
export type { ConservationEnum } from './models/ConservationEnum';
export type { ConversionGroup } from './models/ConversionGroup';
export type { ConversionGroupRequest } from './models/ConversionGroupRequest';
export type { CurrencyEnum } from './models/CurrencyEnum';
export type { CustomerPreferences } from './models/CustomerPreferences';
export type { CustomerPreferencesRequest } from './models/CustomerPreferencesRequest';
export type { CustomPasswordReset } from './models/CustomPasswordReset';
export type { CustomPasswordResetRequest } from './models/CustomPasswordResetRequest';
export type { DefaultLoginRequest } from './models/DefaultLoginRequest';
export type { DefaultRegisterEmail } from './models/DefaultRegisterEmail';
export type { DefaultRegisterEmailRequest } from './models/DefaultRegisterEmailRequest';
export type { DefaultRegisterUser } from './models/DefaultRegisterUser';
export type { DefaultRegisterUserRequest } from './models/DefaultRegisterUserRequest';
export type { EditFieldEnum } from './models/EditFieldEnum';
export type { Environment } from './models/Environment';
export type { EnvironmentGroup } from './models/EnvironmentGroup';
export type { EnvironmentGroupRequest } from './models/EnvironmentGroupRequest';
export type { ExportFile } from './models/ExportFile';
export type { FactorCleaningGroup } from './models/FactorCleaningGroup';
export type { FactorCleaningGroupRequest } from './models/FactorCleaningGroupRequest';
export type { FactorFryingGroup } from './models/FactorFryingGroup';
export type { FactorFryingGroupRequest } from './models/FactorFryingGroupRequest';
export type { FactorHydratationGroup } from './models/FactorHydratationGroup';
export type { FactorHydratationGroupRequest } from './models/FactorHydratationGroupRequest';
export type { GetProductsRequest } from './models/GetProductsRequest';
export type { GuestSpecification } from './models/GuestSpecification';
export type { GuestSpecificationKindEnum } from './models/GuestSpecificationKindEnum';
export type { GuestSpecificationRequest } from './models/GuestSpecificationRequest';
export type { HighEnum } from './models/HighEnum';
export type { IngredientV3 } from './models/IngredientV3';
export type { IngredientV3Request } from './models/IngredientV3Request';
export type { intList } from './models/intList';
export type { LangEnum } from './models/LangEnum';
export type { LanguageEnum } from './models/LanguageEnum';
export type { Logout } from './models/Logout';
export type { LogoutRequest } from './models/LogoutRequest';
export type { LowEnum } from './models/LowEnum';
export type { MeanColorEnum } from './models/MeanColorEnum';
export type { MeanEnum } from './models/MeanEnum';
export type { MeanRangePerPortionEnum } from './models/MeanRangePerPortionEnum';
export type { MedEnum } from './models/MedEnum';
export type { Menu } from './models/Menu';
export type { MenuAllegationPropertySchema } from './models/MenuAllegationPropertySchema';
export type { MenuAllegationPropertyValueSchema } from './models/MenuAllegationPropertyValueSchema';
export type { MenuAllergenPropertySchema } from './models/MenuAllergenPropertySchema';
export type { MenuAllergenPropertyValueSchema } from './models/MenuAllergenPropertyValueSchema';
export type { MenuCategogyDefsSchema } from './models/MenuCategogyDefsSchema';
export type { MenuCategogyPriceSchema } from './models/MenuCategogyPriceSchema';
export type { MenuCategogySchema } from './models/MenuCategogySchema';
export type { MenuCategogyTagSchema } from './models/MenuCategogyTagSchema';
export type { MenuCostPropertySchema } from './models/MenuCostPropertySchema';
export type { MenuCostPropertySummarySchema } from './models/MenuCostPropertySummarySchema';
export type { MenuCostPropertyValueSchema } from './models/MenuCostPropertyValueSchema';
export type { MenuCostPropertyValueSchemaIdEnum } from './models/MenuCostPropertyValueSchemaIdEnum';
export type { MenuCostsApiSchema } from './models/MenuCostsApiSchema';
export type { MenuEntry } from './models/MenuEntry';
export type { MenuEntryRequest } from './models/MenuEntryRequest';
export type { MenuEnvironmentPropertyColorSchema } from './models/MenuEnvironmentPropertyColorSchema';
export type { MenuEnvironmentPropertySchema } from './models/MenuEnvironmentPropertySchema';
export type { MenuEnvironmentPropertyValueSchema } from './models/MenuEnvironmentPropertyValueSchema';
export type { MenuIdsRequest } from './models/MenuIdsRequest';
export type { MenuLabelPropertySchema } from './models/MenuLabelPropertySchema';
export type { MenuLabelPropertyValueSchema } from './models/MenuLabelPropertyValueSchema';
export type { MenuNutrientPropertyColorSchema } from './models/MenuNutrientPropertyColorSchema';
export type { MenuNutrientPropertySchema } from './models/MenuNutrientPropertySchema';
export type { MenuNutrientPropertyValueSchema } from './models/MenuNutrientPropertyValueSchema';
export type { MenuOriginPropertyValueSchema } from './models/MenuOriginPropertyValueSchema';
export type { MenuPurchaseList } from './models/MenuPurchaseList';
export type { MenuRequest } from './models/MenuRequest';
export type { MenuScorePropertySchema } from './models/MenuScorePropertySchema';
export type { MenuScorePropertySummarySchema } from './models/MenuScorePropertySummarySchema';
export type { MenuScorePropertyValueSchema } from './models/MenuScorePropertyValueSchema';
export type { MenuScoresApiSchema } from './models/MenuScoresApiSchema';
export type { MenuSeasonalityPropertyValueSchema } from './models/MenuSeasonalityPropertyValueSchema';
export type { MenuSeasonalityPropertyValueSchemaIdEnum } from './models/MenuSeasonalityPropertyValueSchemaIdEnum';
export type { MenuSeasonalityPropertyValueSchemaList } from './models/MenuSeasonalityPropertyValueSchemaList';
export type { MenuTagPropertySchema } from './models/MenuTagPropertySchema';
export type { MenuTagPropertyValueSchema } from './models/MenuTagPropertyValueSchema';
export type { Message } from './models/Message';
export type { Nutrient } from './models/Nutrient';
export type { NutritionalGroup } from './models/NutritionalGroup';
export type { NutritionalGroupRequest } from './models/NutritionalGroupRequest';
export type { OAuth2InputRequest } from './models/OAuth2InputRequest';
export type { Origin } from './models/Origin';
export type { OriginRequest } from './models/OriginRequest';
export type { Packaging } from './models/Packaging';
export type { PackagingRequest } from './models/PackagingRequest';
export type { PaginatedAllergenList } from './models/PaginatedAllergenList';
export type { PaginatedCompanyList } from './models/PaginatedCompanyList';
export type { PaginatedConceptList } from './models/PaginatedConceptList';
export type { PaginatedConcepts2IngredientList } from './models/PaginatedConcepts2IngredientList';
export type { PaginatedConceptTypeList } from './models/PaginatedConceptTypeList';
export type { PaginatedConversionGroupList } from './models/PaginatedConversionGroupList';
export type { PaginatedEnvironmentGroupList } from './models/PaginatedEnvironmentGroupList';
export type { PaginatedEnvironmentList } from './models/PaginatedEnvironmentList';
export type { PaginatedFactorCleaningGroupList } from './models/PaginatedFactorCleaningGroupList';
export type { PaginatedFactorFryingGroupList } from './models/PaginatedFactorFryingGroupList';
export type { PaginatedFactorHydratationGroupList } from './models/PaginatedFactorHydratationGroupList';
export type { PaginatedGuestSpecificationList } from './models/PaginatedGuestSpecificationList';
export type { PaginatedIngredientV3List } from './models/PaginatedIngredientV3List';
export type { PaginatedMenuEntryList } from './models/PaginatedMenuEntryList';
export type { PaginatedMenuList } from './models/PaginatedMenuList';
export type { PaginatedNutrientList } from './models/PaginatedNutrientList';
export type { PaginatedNutritionalGroupList } from './models/PaginatedNutritionalGroupList';
export type { PaginatedOriginList } from './models/PaginatedOriginList';
export type { PaginatedPackagingList } from './models/PaginatedPackagingList';
export type { PaginatedParsingPatternList } from './models/PaginatedParsingPatternList';
export type { PaginatedProductCategoryList } from './models/PaginatedProductCategoryList';
export type { PaginatedProductConversionList } from './models/PaginatedProductConversionList';
export type { PaginatedProductFileList } from './models/PaginatedProductFileList';
export type { PaginatedProductList } from './models/PaginatedProductList';
export type { PaginatedProductSimpleList } from './models/PaginatedProductSimpleList';
export type { PaginatedProductTagList } from './models/PaginatedProductTagList';
export type { PaginatedPublicUserList } from './models/PaginatedPublicUserList';
export type { PaginatedPurchasePriceGroupList } from './models/PaginatedPurchasePriceGroupList';
export type { PaginatedPurchasePriceList } from './models/PaginatedPurchasePriceList';
export type { PaginatedTaxonomyList } from './models/PaginatedTaxonomyList';
export type { PaginatedTranslationsList } from './models/PaginatedTranslationsList';
export type { PaginatedUnitList } from './models/PaginatedUnitList';
export type { ParsingPattern } from './models/ParsingPattern';
export type { ParsingPatternRequest } from './models/ParsingPatternRequest';
export type { Permission } from './models/Permission';
export type { Product } from './models/Product';
export type { ProductAllegationApiSchema } from './models/ProductAllegationApiSchema';
export type { ProductAllergenApiSchema } from './models/ProductAllergenApiSchema';
export type { ProductAllUnavailability } from './models/ProductAllUnavailability';
export type { ProductCategory } from './models/ProductCategory';
export type { ProductCategoryRequest } from './models/ProductCategoryRequest';
export type { ProductConversion } from './models/ProductConversion';
export type { ProductConversionRequest } from './models/ProductConversionRequest';
export type { ProductEnvironmentApiSchema } from './models/ProductEnvironmentApiSchema';
export type { ProductEnvironmentSchema } from './models/ProductEnvironmentSchema';
export type { ProductEnvironmentSourceSchema } from './models/ProductEnvironmentSourceSchema';
export type { ProductEnvironmentWithSourceSchema } from './models/ProductEnvironmentWithSourceSchema';
export type { ProductFile } from './models/ProductFile';
export type { ProductFileRequest } from './models/ProductFileRequest';
export type { ProductFileTypeEnum } from './models/ProductFileTypeEnum';
export type { ProductLink } from './models/ProductLink';
export type { ProductLinkRequest } from './models/ProductLinkRequest';
export type { ProductNaturalApiSchema } from './models/ProductNaturalApiSchema';
export type { ProductNutrientApiSchema } from './models/ProductNutrientApiSchema';
export type { ProductNutrientSchema } from './models/ProductNutrientSchema';
export type { ProductNutrientSourceSchema } from './models/ProductNutrientSourceSchema';
export type { ProductNutrientWithNoneSchema } from './models/ProductNutrientWithNoneSchema';
export type { ProductOneUnavailability } from './models/ProductOneUnavailability';
export type { ProductRaw } from './models/ProductRaw';
export type { ProductRawRequest } from './models/ProductRawRequest';
export type { ProductRequest } from './models/ProductRequest';
export type { ProductSimple } from './models/ProductSimple';
export type { ProductSimpleRequest } from './models/ProductSimpleRequest';
export type { ProductStatusApiSchema } from './models/ProductStatusApiSchema';
export type { ProductTag } from './models/ProductTag';
export type { ProductTypeEnum } from './models/ProductTypeEnum';
export type { ProductUnavailability } from './models/ProductUnavailability';
export type { ProductUnavailabilityRequest } from './models/ProductUnavailabilityRequest';
export type { ProfilePhoneSchema } from './models/ProfilePhoneSchema';
export type { ProfilePhoneSchemaList } from './models/ProfilePhoneSchemaList';
export type { PublicCompany } from './models/PublicCompany';
export type { PublicDish } from './models/PublicDish';
export type { PublicDishAndOrder } from './models/PublicDishAndOrder';
export type { PublicDishRequest } from './models/PublicDishRequest';
export type { PublicMenu } from './models/PublicMenu';
export type { PublicMenuEntry } from './models/PublicMenuEntry';
export type { PublicOrder } from './models/PublicOrder';
export type { PublicOrderRequest } from './models/PublicOrderRequest';
export type { PublicProduct } from './models/PublicProduct';
export type { PublicProfile } from './models/PublicProfile';
export type { PublicProfileRequest } from './models/PublicProfileRequest';
export type { PublicUser } from './models/PublicUser';
export type { PublicUserRequest } from './models/PublicUserRequest';
export type { PurchasePrice } from './models/PurchasePrice';
export type { PurchasePriceGroup } from './models/PurchasePriceGroup';
export type { PurchasePriceGroupRequest } from './models/PurchasePriceGroupRequest';
export type { QuantityDefEnum } from './models/QuantityDefEnum';
export type { ResetPassword } from './models/ResetPassword';
export type { ResetPasswordRequest } from './models/ResetPasswordRequest';
export type { SeasonalityDateSchema } from './models/SeasonalityDateSchema';
export type { StatusEnum } from './models/StatusEnum';
export type { StatusUpdateEnum } from './models/StatusUpdateEnum';
export type { TagKindEnum } from './models/TagKindEnum';
export type { Taxonomy } from './models/Taxonomy';
export type { TaxonomyRequest } from './models/TaxonomyRequest';
export type { TokenDetail } from './models/TokenDetail';
export type { TrackAction } from './models/TrackAction';
export type { TrackActionRequest } from './models/TrackActionRequest';
export type { Translations } from './models/Translations';
export type { TranslationsImport } from './models/TranslationsImport';
export type { TranslationsImportRequest } from './models/TranslationsImportRequest';
export type { TranslationsRequest } from './models/TranslationsRequest';
export type { Unit } from './models/Unit';
export type { UnitRequest } from './models/UnitRequest';
export type { UserStatsAggregateResponse } from './models/UserStatsAggregateResponse';
export type { UserStatsEnvironment } from './models/UserStatsEnvironment';
export type { UserStatsNutrient } from './models/UserStatsNutrient';
export type { UserStatsResponse } from './models/UserStatsResponse';
export type { UserToken } from './models/UserToken';
export type { UserTokenRequest } from './models/UserTokenRequest';
export type { VerifyEmail } from './models/VerifyEmail';
export type { VerifyEmailRequest } from './models/VerifyEmailRequest';
export type { VerifyRegistrationRequest } from './models/VerifyRegistrationRequest';
export type { YoumealSymbolEnum } from './models/YoumealSymbolEnum';

export { ApiService } from './services/ApiService';
