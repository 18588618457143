import React from "react";
import { Trans, useTranslation } from "react-i18next";
import "../../Animation.scss";

interface Props {
  style?: any;
  [others: string]: any;
}

function Loading(props: Props) {
  const { style } = props;
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        ...style,
      }}
    >
      <img
        src={"/app/logo512.png"}
        alt="logo"
        style={{ height: "20%", width: "auto" }}
      />
      <div className="anim-pulse" style={{ fontSize: "6vh" }}>
        <Trans t={t} i18nKey="loading">
          Loading...
        </Trans>
      </div>
    </div>
  );
}

export default Loading;
