import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import HttpApi from 'i18next-http-backend';
//import Backend from 'i18next-chained-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
//import 'moment/locale/en';
//import 'moment/locale/fr';
//import 'moment/locale/nl';
//import 'moment/locale/pt';
import "../node_modules/moment/locale/de";
import "../node_modules/moment/locale/fr";
import "../node_modules/moment/locale/kk";
import "../node_modules/moment/locale/nl";
import "../node_modules/moment/locale/pt";
import "../node_modules/moment/locale/ru";

import { OpenAPI } from "api/core/OpenAPI";
import de_app from "./i18n/de/app.json";
import en_app from "./i18n/en/app.json";
import fr_app from "./i18n/fr/app.json";
import kk_app from "./i18n/kk/app.json";
import nl_app from "./i18n/nl/app.json";
import pt_app from "./i18n/pt/app.json";
import ru_app from "./i18n/ru/app.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    app: en_app,
  },
  fr: {
    app: fr_app,
  },
  nl: {
    app: nl_app,
  },
  pt: {
    app: pt_app,
  },
  de: {
    app: de_app,
  },
  ru: {
    app: ru_app,
  },
  kk: {
    app: kk_app,
  },
};

i18n.on("initialized", function () {
  //console.log("SET MOMENT LOCALE TO", i18n.language)
  let lng = i18n.language;
  moment.locale(lng);
  OpenAPI.HEADERS = { ...(OpenAPI.HEADERS || {}), "Accept-Language": lng };
});

i18n.on("languageChanged", function (lng) {
  //console.log("language changed", lng)
  moment.locale(lng);
  OpenAPI.HEADERS = { ...(OpenAPI.HEADERS || {}), "Accept-Language": lng };
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  //  .use(Backend)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "fr",
    ns: "app",
    defaultNS: "app",
    saveMissing: true,

    keySeparator: ".",
    nsSeparator: ":",
    returnNull: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "bold", "b"],
    },

    // backend:{
    //     // array of existing i18next backends from https://www.i18next.com/plugins-and-utils.html#backends
    //     backends: [
    //         HttpApi
    //     ],

    //     // array of options in order of backends above
    //     backendOptions: [
    //         {
    //             loadPath: '/locales/{{lng}}/{{ns}}.json' // http api load path for my own fallback
    //         },
    //     ]
    // },
  });

export default i18n;
