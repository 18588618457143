import { toast, ToastOptions } from "react-toastify";

const default_config: ToastOptions = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};

export const notify_info = function (message: string) {
  toast.info(message, default_config);
};

export const notify_success = function (message: string) {
  toast.success(message, default_config);
};

export const notify_error = function (message: string) {
  toast.error(message, default_config);
};
