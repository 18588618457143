import { useEffect, useRef } from "react";
import usePersistentState from "./usePersistentState";
//import { AuthService } from 'api';
import { OpenAPI } from "api/core/OpenAPI";
import { UserToken } from "../../api/models/UserToken";
import { ApiService } from "../../api/services/ApiService";
import { notify_success } from "utils/toast";
import { useTranslation } from "react-i18next";
import { OAuth2InputRequest } from "../../api/models/OAuth2InputRequest";

// Hook
export const useUser = function () {
  const { item: user, setItem: setUser } =
    usePersistentState<UserToken>("user");
  const { t } = useTranslation();
  //let history = useHistory();
  const didMountRef = useRef(false);

  const token = user?.token;

  const logoutUser = async function () {
    try {
      await ApiService.apiUserAuthLogoutCreate({});
      setUser(undefined);
      OpenAPI.TOKEN = undefined;
      notify_success(t("user_was_logout"));
      //history.push("/");
    } catch (error) {
      console.log("ERROR", (error as any).message);
      setUser(undefined);
    }
  };

  const unsubscribe = async function () {
    try {
      user?.id && (await ApiService.apiUserUserUnsubscribeDestroy());
      setUser(undefined);
      OpenAPI.TOKEN = undefined;
      notify_success(t("user_was_deleted"));
    } catch (error) {
      console.log("ERROR", (error as any).message);
      setUser(undefined);
    }
  };

  const social_login = async function (data: OAuth2InputRequest) {
    const loginResponse = await ApiService.apiUserLoginSocialCreate({
      requestBody: data,
    });
    console.log("social loginResponse", loginResponse);
    let token = loginResponse?.token;
    OpenAPI.TOKEN = token; //set authorization token
    let userData = await ApiService.apiUserAuthProfileRetrieve();
    setUser(userData);
  };

  const updateUserProfile = async function () {
    // if (token) {
    //   try {
    //     let userData = await AuthService.authProfileRetrieve()
    //     setUser(userData)
    //   } catch (error) {
    //     console.log("ERROR", error.message)
    //     setUser(undefined)
    //   }
    // }
  };

  // on first render only
  useEffect(() => {
    didMountRef.current = true;
  }, []);

  useEffect(() => {
    if (token) {
      // if token changed, attached the new token to the request headers
      OpenAPI.TOKEN = token; //set authorization token
    }
  }, [token]);

  // // trigger on didMountRef.current to execute this only at second rendering (when user is ready)
  // // check that token is still valid and update information on the user
  // useEffect(() => {
  //   updateUserProfile()
  // }, [didMountRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    user,
    setUser,
    updateUserProfile,
    logoutUser,
    unsubscribe,
    social_login,
  };
};

export default useUser;
