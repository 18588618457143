import React, {
  useEffect,
  useState,
  useContext,
  ReactNode,
  createContext,
} from "react";
import { useTranslation, Trans } from "react-i18next";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

const CookieConsentContext = createContext({
  allowCookie: false,
  setAllowCookie: (a: boolean) => {},
});

export const CookieConsentProvider = function ({
  children,
}: {
  children: ReactNode;
}) {
  const [allowCookie, setAllowCookie] = useState(false);
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const { t } = useTranslation("app");

  useEffect(() => {
    console.log("init UserDataProvider", cookies.cookieConsent);
    if (!!cookies.cookieConsent) {
      setAllowCookie(true);
    }
  }, [cookies.cookieConsent]);

  // {
  const contextData = {
    allowCookie: allowCookie,
    setAllowCookie: (a: boolean) => setAllowCookie(a),
  };

  return (
    <CookieConsentContext.Provider value={contextData}>
      {!allowCookie && (
        <div
          style={{
            position: "fixed",
            left: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
            textAlign: "justify",
            width: "100%",
            padding: "10px",
            fontSize: "small",
            backgroundColor: "var(--warning)",
          }}
        >
          <div style={{ maxWidth: "768px" }}>
            <div>
              <Trans t={t} i18nKey="cookie_consent_text">
                Youmeal SA utilise des cookies pour enregistrer vos préférences
                personnelles et pour comprendre comment vous utilisez notre
                application mobile. Nous pouvons ainsi améliorer en permanence
                votre expérience de navigation.  En cliquant sur le bouton
                accepter, vous consentez à l’utilisation de ces technologies
              </Trans>
            </div>
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div>
                <Link to="/app/cookiePolicy">{t("plus d'information")}</Link>
              </div>

              <Button
                size={"sm"}
                variant="primary"
                onClick={() => {
                  setAllowCookie(true);
                  setCookie("cookieConsent", true, {
                    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
                  });
                }}
              >
                {t("accepter")}
              </Button>
            </div>
          </div>
        </div>
      )}
      {children}
    </CookieConsentContext.Provider>
  );
};

// Hook
export const useCookieConsent = () => useContext(CookieConsentContext);

export default useCookieConsent;
