/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePassword } from '../models/ChangePassword';
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { Company } from '../models/Company';
import type { CompanyRequest } from '../models/CompanyRequest';
import type { Concept } from '../models/Concept';
import type { ConceptRequest } from '../models/ConceptRequest';
import type { Concepts2Ingredient } from '../models/Concepts2Ingredient';
import type { Concepts2IngredientRequest } from '../models/Concepts2IngredientRequest';
import type { ConceptType } from '../models/ConceptType';
import type { ConceptTypeRequest } from '../models/ConceptTypeRequest';
import type { ConversionGroup } from '../models/ConversionGroup';
import type { ConversionGroupRequest } from '../models/ConversionGroupRequest';
import type { CustomPasswordReset } from '../models/CustomPasswordReset';
import type { CustomPasswordResetRequest } from '../models/CustomPasswordResetRequest';
import type { DefaultLoginRequest } from '../models/DefaultLoginRequest';
import type { DefaultRegisterEmail } from '../models/DefaultRegisterEmail';
import type { DefaultRegisterEmailRequest } from '../models/DefaultRegisterEmailRequest';
import type { DefaultRegisterUser } from '../models/DefaultRegisterUser';
import type { DefaultRegisterUserRequest } from '../models/DefaultRegisterUserRequest';
import type { EnvironmentGroup } from '../models/EnvironmentGroup';
import type { EnvironmentGroupRequest } from '../models/EnvironmentGroupRequest';
import type { ExportFile } from '../models/ExportFile';
import type { FactorCleaningGroup } from '../models/FactorCleaningGroup';
import type { FactorCleaningGroupRequest } from '../models/FactorCleaningGroupRequest';
import type { FactorFryingGroup } from '../models/FactorFryingGroup';
import type { FactorFryingGroupRequest } from '../models/FactorFryingGroupRequest';
import type { FactorHydratationGroup } from '../models/FactorHydratationGroup';
import type { FactorHydratationGroupRequest } from '../models/FactorHydratationGroupRequest';
import type { GetProductsRequest } from '../models/GetProductsRequest';
import type { GuestSpecification } from '../models/GuestSpecification';
import type { GuestSpecificationRequest } from '../models/GuestSpecificationRequest';
import type { IngredientV3 } from '../models/IngredientV3';
import type { IngredientV3Request } from '../models/IngredientV3Request';
import type { Logout } from '../models/Logout';
import type { LogoutRequest } from '../models/LogoutRequest';
import type { Menu } from '../models/Menu';
import type { MenuEntry } from '../models/MenuEntry';
import type { MenuEntryRequest } from '../models/MenuEntryRequest';
import type { MenuIdsRequest } from '../models/MenuIdsRequest';
import type { MenuPurchaseList } from '../models/MenuPurchaseList';
import type { MenuRequest } from '../models/MenuRequest';
import type { Message } from '../models/Message';
import type { NutritionalGroup } from '../models/NutritionalGroup';
import type { NutritionalGroupRequest } from '../models/NutritionalGroupRequest';
import type { OAuth2InputRequest } from '../models/OAuth2InputRequest';
import type { Origin } from '../models/Origin';
import type { OriginRequest } from '../models/OriginRequest';
import type { Packaging } from '../models/Packaging';
import type { PackagingRequest } from '../models/PackagingRequest';
import type { PaginatedAllergenList } from '../models/PaginatedAllergenList';
import type { PaginatedCompanyList } from '../models/PaginatedCompanyList';
import type { PaginatedConceptList } from '../models/PaginatedConceptList';
import type { PaginatedConcepts2IngredientList } from '../models/PaginatedConcepts2IngredientList';
import type { PaginatedConceptTypeList } from '../models/PaginatedConceptTypeList';
import type { PaginatedConversionGroupList } from '../models/PaginatedConversionGroupList';
import type { PaginatedEnvironmentGroupList } from '../models/PaginatedEnvironmentGroupList';
import type { PaginatedEnvironmentList } from '../models/PaginatedEnvironmentList';
import type { PaginatedFactorCleaningGroupList } from '../models/PaginatedFactorCleaningGroupList';
import type { PaginatedFactorFryingGroupList } from '../models/PaginatedFactorFryingGroupList';
import type { PaginatedFactorHydratationGroupList } from '../models/PaginatedFactorHydratationGroupList';
import type { PaginatedGuestSpecificationList } from '../models/PaginatedGuestSpecificationList';
import type { PaginatedIngredientV3List } from '../models/PaginatedIngredientV3List';
import type { PaginatedMenuEntryList } from '../models/PaginatedMenuEntryList';
import type { PaginatedMenuList } from '../models/PaginatedMenuList';
import type { PaginatedNutrientList } from '../models/PaginatedNutrientList';
import type { PaginatedNutritionalGroupList } from '../models/PaginatedNutritionalGroupList';
import type { PaginatedOriginList } from '../models/PaginatedOriginList';
import type { PaginatedPackagingList } from '../models/PaginatedPackagingList';
import type { PaginatedParsingPatternList } from '../models/PaginatedParsingPatternList';
import type { PaginatedProductCategoryList } from '../models/PaginatedProductCategoryList';
import type { PaginatedProductConversionList } from '../models/PaginatedProductConversionList';
import type { PaginatedProductFileList } from '../models/PaginatedProductFileList';
import type { PaginatedProductList } from '../models/PaginatedProductList';
import type { PaginatedProductSimpleList } from '../models/PaginatedProductSimpleList';
import type { PaginatedProductTagList } from '../models/PaginatedProductTagList';
import type { PaginatedPublicUserList } from '../models/PaginatedPublicUserList';
import type { PaginatedPurchasePriceGroupList } from '../models/PaginatedPurchasePriceGroupList';
import type { PaginatedPurchasePriceList } from '../models/PaginatedPurchasePriceList';
import type { PaginatedTaxonomyList } from '../models/PaginatedTaxonomyList';
import type { PaginatedTranslationsList } from '../models/PaginatedTranslationsList';
import type { PaginatedUnitList } from '../models/PaginatedUnitList';
import type { ParsingPattern } from '../models/ParsingPattern';
import type { ParsingPatternRequest } from '../models/ParsingPatternRequest';
import type { Product } from '../models/Product';
import type { ProductAllUnavailability } from '../models/ProductAllUnavailability';
import type { ProductCategory } from '../models/ProductCategory';
import type { ProductCategoryRequest } from '../models/ProductCategoryRequest';
import type { ProductConversion } from '../models/ProductConversion';
import type { ProductConversionRequest } from '../models/ProductConversionRequest';
import type { ProductFile } from '../models/ProductFile';
import type { ProductFileRequest } from '../models/ProductFileRequest';
import type { ProductLink } from '../models/ProductLink';
import type { ProductLinkRequest } from '../models/ProductLinkRequest';
import type { ProductRequest } from '../models/ProductRequest';
import type { ProductSimple } from '../models/ProductSimple';
import type { ProductSimpleRequest } from '../models/ProductSimpleRequest';
import type { ProductUnavailability } from '../models/ProductUnavailability';
import type { ProductUnavailabilityRequest } from '../models/ProductUnavailabilityRequest';
import type { PublicCompany } from '../models/PublicCompany';
import type { PublicDishAndOrder } from '../models/PublicDishAndOrder';
import type { PublicDishRequest } from '../models/PublicDishRequest';
import type { PublicMenu } from '../models/PublicMenu';
import type { PublicOrderRequest } from '../models/PublicOrderRequest';
import type { PublicProduct } from '../models/PublicProduct';
import type { PublicUser } from '../models/PublicUser';
import type { PublicUserRequest } from '../models/PublicUserRequest';
import type { PurchasePriceGroup } from '../models/PurchasePriceGroup';
import type { PurchasePriceGroupRequest } from '../models/PurchasePriceGroupRequest';
import type { ResetPassword } from '../models/ResetPassword';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { Taxonomy } from '../models/Taxonomy';
import type { TaxonomyRequest } from '../models/TaxonomyRequest';
import type { TokenDetail } from '../models/TokenDetail';
import type { TrackAction } from '../models/TrackAction';
import type { TrackActionRequest } from '../models/TrackActionRequest';
import type { Translations } from '../models/Translations';
import type { TranslationsImport } from '../models/TranslationsImport';
import type { TranslationsImportRequest } from '../models/TranslationsImportRequest';
import type { TranslationsRequest } from '../models/TranslationsRequest';
import type { Unit } from '../models/Unit';
import type { UnitRequest } from '../models/UnitRequest';
import type { UserStatsResponse } from '../models/UserStatsResponse';
import type { UserToken } from '../models/UserToken';
import type { UserTokenRequest } from '../models/UserTokenRequest';
import type { VerifyEmail } from '../models/VerifyEmail';
import type { VerifyEmailRequest } from '../models/VerifyEmailRequest';
import type { VerifyRegistrationRequest } from '../models/VerifyRegistrationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiService {

    /**
     * Single endpoint to monitor liveness of the server
     * @returns any
     * @throws ApiError
     */
    public static apiHealthzRetrieve(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/healthz/',
        });
    }

    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.
     *
     * - YAML: application/vnd.oai.openapi
     * - JSON: application/vnd.oai.openapi+json
     * @returns any
     * @throws ApiError
     */
    public static apiSchemaRetrieve({
        format,
        lang,
    }: {
        format?: 'json' | 'yaml',
        lang?: 'de' | 'en' | 'es' | 'fr' | 'it' | 'kk' | 'kz' | 'nl' | 'pt' | 'ru',
    }): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/schema/',
            query: {
                'format': format,
                'lang': lang,
            },
        });
    }

    /**
     * Change the user password.
     * @returns ChangePassword
     * @throws ApiError
     */
    public static apiUserAuthChangePasswordCreate({
        requestBody,
    }: {
        requestBody: ChangePasswordRequest,
    }): CancelablePromise<ChangePassword> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/auth/change-password/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Logs in the user via given login and password.
     * @returns TokenDetail
     * @throws ApiError
     */
    public static apiUserAuthLoginCreate({
        requestBody,
    }: {
        requestBody: DefaultLoginRequest,
    }): CancelablePromise<TokenDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/auth/login/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Logs out the user. returns an error if the user is not
     * authenticated.
     * @returns Logout
     * @throws ApiError
     */
    public static apiUserAuthLogoutCreate({
        requestBody,
    }: {
        requestBody?: LogoutRequest,
    }): CancelablePromise<Logout> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/auth/logout/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserToken
     * @throws ApiError
     */
    public static apiUserAuthProfileRetrieve(): CancelablePromise<UserToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/auth/profile/',
        });
    }

    /**
     * @returns UserToken
     * @throws ApiError
     */
    public static apiUserAuthProfileCreate({
        requestBody,
    }: {
        requestBody: UserTokenRequest,
    }): CancelablePromise<UserToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/auth/profile/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserToken
     * @throws ApiError
     */
    public static apiUserAuthProfilePartialUpdate({
        requestBody,
    }: {
        requestBody: UserTokenRequest,
    }): CancelablePromise<UserToken> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/user/auth/profile/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Register new user.
     * @returns DefaultRegisterUser
     * @throws ApiError
     */
    public static apiUserAuthRegisterCreate({
        requestBody,
    }: {
        requestBody: DefaultRegisterUserRequest,
    }): CancelablePromise<DefaultRegisterUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/auth/register/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Register new email.
     * @returns DefaultRegisterEmail
     * @throws ApiError
     */
    public static apiUserAuthRegisterEmailCreate({
        requestBody,
    }: {
        requestBody: DefaultRegisterEmailRequest,
    }): CancelablePromise<DefaultRegisterEmail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/auth/register-email/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Reset password, given the signature and timestamp from the link.
     * @returns ResetPassword
     * @throws ApiError
     */
    public static apiUserAuthResetPasswordCreate({
        requestBody,
    }: {
        requestBody: ResetPasswordRequest,
    }): CancelablePromise<ResetPassword> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/auth/reset-password/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Send email with reset password link.
     * @returns CustomPasswordReset
     * @throws ApiError
     */
    public static apiUserAuthSendResetPasswordLinkCreate({
        requestBody,
    }: {
        requestBody: CustomPasswordResetRequest,
    }): CancelablePromise<CustomPasswordReset> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/auth/send-reset-password-link/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Verify email via signature.
     * @returns VerifyEmail
     * @throws ApiError
     */
    public static apiUserAuthVerifyEmailCreate({
        requestBody,
    }: {
        requestBody: VerifyEmailRequest,
    }): CancelablePromise<VerifyEmail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/auth/verify-email/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Verify registration via signature.
     * @returns TokenDetail
     * @throws ApiError
     */
    public static apiUserAuthVerifyRegistrationCreate({
        requestBody,
    }: {
        requestBody: VerifyRegistrationRequest,
    }): CancelablePromise<TokenDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/auth/verify-registration/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserToken
     * @throws ApiError
     */
    public static apiUserLoginSocialCreate({
        requestBody,
    }: {
        requestBody: OAuth2InputRequest,
    }): CancelablePromise<UserToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/login_social/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static apiUserSamlIdpsRetrieve(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/saml/idps',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static apiUserSamlIdpsWebRetrieve(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/saml/idps_web',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static apiUserSamlMetadataRetrieve(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/saml/metadata',
        });
    }

    /**
     * GenericViewSet ViewSet allowing to change user langage and update profile
     * @returns PaginatedPublicUserList
     * @throws ApiError
     */
    public static apiUserUserList({
        id,
        ids,
        ordering,
        page,
        pageSize,
        profileCompany,
        search,
    }: {
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        profileCompany?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedPublicUserList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/user/',
            query: {
                'id': id,
                'ids': ids,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'profile__company': profileCompany,
                'search': search,
            },
        });
    }

    /**
     * GenericViewSet ViewSet allowing to change user langage and update profile
     * @returns PublicUser
     * @throws ApiError
     */
    public static apiUserUserCreate({
        requestBody,
    }: {
        requestBody: PublicUserRequest,
    }): CancelablePromise<PublicUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/user/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * GenericViewSet ViewSet allowing to change user langage and update profile
     * @returns PublicUser
     * @throws ApiError
     */
    public static apiUserUserRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this user.
         */
        id: number,
    }): CancelablePromise<PublicUser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/user/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * GenericViewSet ViewSet allowing to change user langage and update profile
     * @returns PublicUser
     * @throws ApiError
     */
    public static apiUserUserPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this user.
         */
        id: number,
        requestBody: PublicUserRequest,
    }): CancelablePromise<PublicUser> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/user/user/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * GenericViewSet ViewSet allowing to change user langage and update profile
     * @returns void
     * @throws ApiError
     */
    public static apiUserUserDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this user.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/user/user/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Send email with reset password link.
     * @returns any
     * @throws ApiError
     */
    public static apiUserUserSendInviteCreate({
        id,
    }: {
        /**
         * A unique integer value identifying this user.
         */
        id: number,
    }): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/user/{id}/send_invite/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * GenericViewSet ViewSet allowing to change user langage and update profile
     * @returns PublicUser
     * @throws ApiError
     */
    public static apiUserUserLanguageCreate({
        requestBody,
    }: {
        requestBody: PublicUserRequest,
    }): CancelablePromise<PublicUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/user/language/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Single endpoint to get all user stats
     * @returns UserStatsResponse
     * @throws ApiError
     */
    public static apiUserUserStatsRetrieve(): CancelablePromise<UserStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/user/stats/',
        });
    }

    /**
     * GenericViewSet ViewSet allowing to change user langage and update profile
     * @returns void
     * @throws ApiError
     */
    public static apiUserUserUnsubscribeDestroy(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/user/user/unsubscribe/',
        });
    }

    /**
     * list:
     * Retrieve the list of allergens (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific allergen.
     *
     * create:
     * Create a new allergen.
     *
     * delete:
     * Remove an existing allergen.
     *
     * partial_update:
     * Update one or more fields on an existing allergen.
     *
     * update:
     * Update an allergen.
     * @returns PaginatedAllergenList
     * @throws ApiError
     */
    public static apiV3AllergenList({
        id,
        ids,
        legal,
        names,
        ordering,
        page,
        pageSize,
        search,
    }: {
        id?: number,
        /**
         * Multiple values may be separated by commas.
         */
        ids?: Array<string>,
        legal?: boolean,
        names?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedAllergenList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/allergen/',
            query: {
                'id': id,
                'ids': ids,
                'legal': legal,
                'names': names,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * Single endpoint to get all youmeal assets
     * @returns any
     * @throws ApiError
     */
    public static apiV3AssetRetrieve(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/asset/',
        });
    }

    /**
     * list:
     * Retrieve the list of companies/entities in your organigram (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each company/entity (use GET on a specific company/entity to get all the details)
     *
     * retrieve:
     * Retrieve all information about a specific company/entity.
     *
     * create:
     * Create a new company/entity.
     *
     * delete:
     * Remove an existing company/entity.
     *
     * partial_update:
     * Update one or more fields on an existing company/entity.
     *
     * update:
     * Update a company/entity.
     * @returns PaginatedCompanyList
     * @throws ApiError
     */
    public static apiV3CompanyList({
        ancestors,
        children,
        descendants,
        family,
        ids,
        name,
        ordering,
        owned,
        page,
        pageSize,
        path,
        search,
    }: {
        /**
         * Up  family from the id
         */
        ancestors?: number,
        /**
         * Direct children of the id
         */
        children?: number,
        /**
         * Down  family from the id
         */
        descendants?: number,
        /**
         * Up and Down family from the id
         */
        family?: number,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * Company Name
         */
        name?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        owned?: boolean,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * Path startswith
         */
        path?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedCompanyList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/company/',
            query: {
                'ancestors': ancestors,
                'children': children,
                'descendants': descendants,
                'family': family,
                'ids': ids,
                'name': name,
                'ordering': ordering,
                'owned': owned,
                'page': page,
                'page_size': pageSize,
                'path': path,
                'search': search,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of companies/entities in your organigram (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each company/entity (use GET on a specific company/entity to get all the details)
     *
     * retrieve:
     * Retrieve all information about a specific company/entity.
     *
     * create:
     * Create a new company/entity.
     *
     * delete:
     * Remove an existing company/entity.
     *
     * partial_update:
     * Update one or more fields on an existing company/entity.
     *
     * update:
     * Update a company/entity.
     * @returns Company
     * @throws ApiError
     */
    public static apiV3CompanyCreate({
        requestBody,
    }: {
        requestBody: CompanyRequest,
    }): CancelablePromise<Company> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/company/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * list:
     * Retrieve the list of companies/entities in your organigram (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each company/entity (use GET on a specific company/entity to get all the details)
     *
     * retrieve:
     * Retrieve all information about a specific company/entity.
     *
     * create:
     * Create a new company/entity.
     *
     * delete:
     * Remove an existing company/entity.
     *
     * partial_update:
     * Update one or more fields on an existing company/entity.
     *
     * update:
     * Update a company/entity.
     * @returns Company
     * @throws ApiError
     */
    public static apiV3CompanyRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this company.
         */
        id: number,
    }): CancelablePromise<Company> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/company/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of companies/entities in your organigram (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each company/entity (use GET on a specific company/entity to get all the details)
     *
     * retrieve:
     * Retrieve all information about a specific company/entity.
     *
     * create:
     * Create a new company/entity.
     *
     * delete:
     * Remove an existing company/entity.
     *
     * partial_update:
     * Update one or more fields on an existing company/entity.
     *
     * update:
     * Update a company/entity.
     * @returns Company
     * @throws ApiError
     */
    public static apiV3CompanyPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this company.
         */
        id: number,
        requestBody: CompanyRequest,
    }): CancelablePromise<Company> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/company/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * list:
     * Retrieve the list of companies/entities in your organigram (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each company/entity (use GET on a specific company/entity to get all the details)
     *
     * retrieve:
     * Retrieve all information about a specific company/entity.
     *
     * create:
     * Create a new company/entity.
     *
     * delete:
     * Remove an existing company/entity.
     *
     * partial_update:
     * Update one or more fields on an existing company/entity.
     *
     * update:
     * Update a company/entity.
     * @returns void
     * @throws ApiError
     */
    public static apiV3CompanyDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this company.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/company/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of companies/entities in your organigram (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each company/entity (use GET on a specific company/entity to get all the details)
     *
     * retrieve:
     * Retrieve all information about a specific company/entity.
     *
     * create:
     * Create a new company/entity.
     *
     * delete:
     * Remove an existing company/entity.
     *
     * partial_update:
     * Update one or more fields on an existing company/entity.
     *
     * update:
     * Update a company/entity.
     * @returns Company
     * @throws ApiError
     */
    public static apiV3CompanyExportOrderListRetrieve({
        id,
        fromDate,
        includeChildren,
        toDate,
    }: {
        /**
         * A unique integer value identifying this company.
         */
        id: number,
        fromDate?: string,
        /**
         * shall we include sub companies in the stat
         */
        includeChildren?: boolean,
        toDate?: string,
    }): CancelablePromise<Company> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/company/{id}/export/order_list/',
            path: {
                'id': id,
            },
            query: {
                'from_date': fromDate,
                'includeChildren': includeChildren,
                'to_date': toDate,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of companies/entities in your organigram (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each company/entity (use GET on a specific company/entity to get all the details)
     *
     * retrieve:
     * Retrieve all information about a specific company/entity.
     *
     * create:
     * Create a new company/entity.
     *
     * delete:
     * Remove an existing company/entity.
     *
     * partial_update:
     * Update one or more fields on an existing company/entity.
     *
     * update:
     * Update a company/entity.
     * @returns Company
     * @throws ApiError
     */
    public static apiV3CompanyExportPurchaseListRetrieve({
        id,
        expandRecipe,
        fromDate,
        includeChildren,
        toDate,
    }: {
        /**
         * A unique integer value identifying this company.
         */
        id: number,
        expandRecipe?: boolean,
        fromDate?: string,
        includeChildren?: boolean,
        toDate?: string,
    }): CancelablePromise<Company> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/company/{id}/export/purchase_list/',
            path: {
                'id': id,
            },
            query: {
                'expandRecipe': expandRecipe,
                'from_date': fromDate,
                'includeChildren': includeChildren,
                'to_date': toDate,
            },
        });
    }

    /**
     * @returns PaginatedConceptList
     * @throws ApiError
     */
    public static apiV3ConceptList({
        hasUnit,
        id,
        ids,
        ordering,
        page,
        pageSize,
        search,
        type,
        typeForIngredient,
        typeType,
    }: {
        /**
         * Is this concept associated to a unit
         */
        hasUnit?: 'true' | 'false',
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
        /**
         * concept_type (comma separated)
         */
        type?: string,
        /**
         * is it a concept used in ingredient (comma separated)
         */
        typeForIngredient?: 'true' | 'false' | 'valid' | 'invalid' | 'unknown',
        /**
         * Type of the concept_type (comma separated)
         */
        typeType?: string,
    }): CancelablePromise<PaginatedConceptList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/concept/',
            query: {
                'hasUnit': hasUnit,
                'id': id,
                'ids': ids,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
                'type': type,
                'type__forIngredient': typeForIngredient,
                'type__type': typeType,
            },
        });
    }

    /**
     * @returns Concept
     * @throws ApiError
     */
    public static apiV3ConceptCreate({
        requestBody,
    }: {
        requestBody: ConceptRequest,
    }): CancelablePromise<Concept> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/concept/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Concept
     * @throws ApiError
     */
    public static apiV3ConceptRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this concept.
         */
        id: number,
    }): CancelablePromise<Concept> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/concept/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Concept
     * @throws ApiError
     */
    public static apiV3ConceptPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this concept.
         */
        id: number,
        requestBody: ConceptRequest,
    }): CancelablePromise<Concept> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/concept/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3ConceptDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this concept.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/concept/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Concept
     * @throws ApiError
     */
    public static apiV3ConceptTranslationGoogleRetrieve({
        id,
        transLang,
    }: {
        /**
         * A unique integer value identifying this concept.
         */
        id: number,
        transLang: string,
    }): CancelablePromise<Concept> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/concept/{id}/translation/{trans_lang}/google/',
            path: {
                'id': id,
                'trans_lang': transLang,
            },
        });
    }

    /**
     * @returns Concept
     * @throws ApiError
     */
    public static apiV3ConceptTranslationResolvedRetrieve({
        id,
        transLang,
    }: {
        /**
         * A unique integer value identifying this concept.
         */
        id: number,
        transLang: string,
    }): CancelablePromise<Concept> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/concept/{id}/translation/{trans_lang}/resolved/',
            path: {
                'id': id,
                'trans_lang': transLang,
            },
        });
    }

    /**
     * @returns PaginatedConceptTypeList
     * @throws ApiError
     */
    public static apiV3ConceptTypeList({
        forIngredient,
        page,
        pageSize,
        search,
        type,
    }: {
        forIngredient?: boolean,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
        /**
         * * `subject` - subject
         * * `qualifier` - qualifier
         * * `quantity` - quantity
         * * `other` - other
         */
        type?: 'other' | 'qualifier' | 'quantity' | 'subject' | null,
    }): CancelablePromise<PaginatedConceptTypeList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/conceptType/',
            query: {
                'forIngredient': forIngredient,
                'page': page,
                'page_size': pageSize,
                'search': search,
                'type': type,
            },
        });
    }

    /**
     * @returns ConceptType
     * @throws ApiError
     */
    public static apiV3ConceptTypeCreate({
        requestBody,
    }: {
        requestBody: ConceptTypeRequest,
    }): CancelablePromise<ConceptType> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/conceptType/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ConceptType
     * @throws ApiError
     */
    public static apiV3ConceptTypeRetrieve({
        id,
    }: {
        /**
         * A unique value identifying this concept type.
         */
        id: string,
    }): CancelablePromise<ConceptType> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/conceptType/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConceptType
     * @throws ApiError
     */
    public static apiV3ConceptTypePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique value identifying this concept type.
         */
        id: string,
        requestBody: ConceptTypeRequest,
    }): CancelablePromise<ConceptType> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/conceptType/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3ConceptTypeDestroy({
        id,
    }: {
        /**
         * A unique value identifying this concept type.
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/conceptType/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedConcepts2IngredientList
     * @throws ApiError
     */
    public static apiV3Concepts2IngredientList({
        hasIngredient,
        hidden,
        id,
        ids,
        ignored,
        ordering,
        page,
        pageSize,
        search,
    }: {
        /**
         * is it associated to an ingredient
         */
        hasIngredient?: 'yes' | 'no',
        /**
         * is it hidden
         */
        hidden?: 'yes' | 'no',
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * is it ignored
         */
        ignored?: 'yes' | 'no',
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedConcepts2IngredientList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/concepts2Ingredient/',
            query: {
                'hasIngredient': hasIngredient,
                'hidden': hidden,
                'id': id,
                'ids': ids,
                'ignored': ignored,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns Concepts2Ingredient
     * @throws ApiError
     */
    public static apiV3Concepts2IngredientCreate({
        requestBody,
    }: {
        requestBody?: Concepts2IngredientRequest,
    }): CancelablePromise<Concepts2Ingredient> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/concepts2Ingredient/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Concepts2Ingredient
     * @throws ApiError
     */
    public static apiV3Concepts2IngredientRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this concepts2 ingredient.
         */
        id: number,
    }): CancelablePromise<Concepts2Ingredient> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/concepts2Ingredient/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Concepts2Ingredient
     * @throws ApiError
     */
    public static apiV3Concepts2IngredientPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this concepts2 ingredient.
         */
        id: number,
        requestBody?: Concepts2IngredientRequest,
    }): CancelablePromise<Concepts2Ingredient> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/concepts2Ingredient/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3Concepts2IngredientDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this concepts2 ingredient.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/concepts2Ingredient/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedProductConversionList
     * @throws ApiError
     */
    public static apiV3ConversionList({
        fromUnit,
        ids,
        ordering,
        page,
        pageSize,
        toUnit,
    }: {
        /**
         * Multiple values may be separated by commas.
         */
        fromUnit?: Array<string>,
        /**
         * Multiple values may be separated by commas.
         */
        ids?: Array<string>,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * Multiple values may be separated by commas.
         */
        toUnit?: Array<string>,
    }): CancelablePromise<PaginatedProductConversionList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/conversion/',
            query: {
                'from_unit': fromUnit,
                'ids': ids,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'to_unit': toUnit,
            },
        });
    }

    /**
     * @returns ProductConversion
     * @throws ApiError
     */
    public static apiV3ConversionCreate({
        requestBody,
    }: {
        requestBody: ProductConversionRequest,
    }): CancelablePromise<ProductConversion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/conversion/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductConversion
     * @throws ApiError
     */
    public static apiV3ConversionRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product conversion.
         */
        id: number,
    }): CancelablePromise<ProductConversion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/conversion/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductConversion
     * @throws ApiError
     */
    public static apiV3ConversionPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product conversion.
         */
        id: number,
        requestBody: ProductConversionRequest,
    }): CancelablePromise<ProductConversion> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/conversion/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3ConversionDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product conversion.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/conversion/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedConversionGroupList
     * @throws ApiError
     */
    public static apiV3ConversionGroupList({
        factorLt,
        fromUnit,
        id,
        ids,
        ordering,
        page,
        pageSize,
        rejected,
        search,
        source,
    }: {
        /**
         * Filter on factor less than
         */
        factorLt?: number,
        /**
         * Filter on unit to convert in gr (comma separated)
         */
        fromUnit?: string,
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * Filter on rejected (! to negate)
         */
        rejected?: string,
        /**
         * A search term.
         */
        search?: string,
        /**
         * Filter on source (comma separated)
         */
        source?: string,
    }): CancelablePromise<PaginatedConversionGroupList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/conversionGroup/',
            query: {
                'factor__lt': factorLt,
                'from_unit': fromUnit,
                'id': id,
                'ids': ids,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'rejected': rejected,
                'search': search,
                'source': source,
            },
        });
    }

    /**
     * @returns ConversionGroup
     * @throws ApiError
     */
    public static apiV3ConversionGroupCreate({
        requestBody,
    }: {
        requestBody: ConversionGroupRequest,
    }): CancelablePromise<ConversionGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/conversionGroup/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ConversionGroup
     * @throws ApiError
     */
    public static apiV3ConversionGroupRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this conversion group.
         */
        id: number,
    }): CancelablePromise<ConversionGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/conversionGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ConversionGroup
     * @throws ApiError
     */
    public static apiV3ConversionGroupPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this conversion group.
         */
        id: number,
        requestBody: ConversionGroupRequest,
    }): CancelablePromise<ConversionGroup> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/conversionGroup/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3ConversionGroupDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this conversion group.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/conversionGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of nutrients (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific nutrient.
     *
     * create:
     * Create a new nutrient.
     *
     * delete:
     * Remove an existing nutrient.
     *
     * partial_update:
     * Update one or more fields on an existing nutrient.
     *
     * update:
     * Update an nutrient.
     * @returns PaginatedEnvironmentList
     * @throws ApiError
     */
    public static apiV3EnvironmentList({
        id,
        ids,
        names,
        ordering,
        page,
        pageSize,
        search,
    }: {
        id?: number,
        /**
         * Multiple values may be separated by commas.
         */
        ids?: Array<string>,
        names?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedEnvironmentList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/environment/',
            query: {
                'id': id,
                'ids': ids,
                'names': names,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns PaginatedEnvironmentGroupList
     * @throws ApiError
     */
    public static apiV3EnvironmentGroupList({
        id,
        ids,
        ordering,
        page,
        pageSize,
        search,
        source,
        status,
        type,
    }: {
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
        /**
         * Filter on source (comma separated)
         */
        source?: string,
        /**
         * Filter on status (comma separated)
         */
        status?: string,
        /**
         * Filter on type (comma separated)
         */
        type?: string,
    }): CancelablePromise<PaginatedEnvironmentGroupList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/environmentGroup/',
            query: {
                'id': id,
                'ids': ids,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
                'source': source,
                'status': status,
                'type': type,
            },
        });
    }

    /**
     * @returns EnvironmentGroup
     * @throws ApiError
     */
    public static apiV3EnvironmentGroupCreate({
        requestBody,
    }: {
        requestBody?: EnvironmentGroupRequest,
    }): CancelablePromise<EnvironmentGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/environmentGroup/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EnvironmentGroup
     * @throws ApiError
     */
    public static apiV3EnvironmentGroupRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this environment group.
         */
        id: number,
    }): CancelablePromise<EnvironmentGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/environmentGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EnvironmentGroup
     * @throws ApiError
     */
    public static apiV3EnvironmentGroupPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this environment group.
         */
        id: number,
        requestBody?: EnvironmentGroupRequest,
    }): CancelablePromise<EnvironmentGroup> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/environmentGroup/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3EnvironmentGroupDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this environment group.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/environmentGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedFactorCleaningGroupList
     * @throws ApiError
     */
    public static apiV3FactorCleaningGroupList({
        factorLt,
        id,
        ids,
        ordering,
        page,
        pageSize,
        rejected,
        search,
        source,
    }: {
        /**
         * Filter on factor less than
         */
        factorLt?: number,
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * Filter on rejected (! to negate)
         */
        rejected?: string,
        /**
         * A search term.
         */
        search?: string,
        /**
         * Filter on source (comma separated)
         */
        source?: string,
    }): CancelablePromise<PaginatedFactorCleaningGroupList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/factorCleaningGroup/',
            query: {
                'factor__lt': factorLt,
                'id': id,
                'ids': ids,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'rejected': rejected,
                'search': search,
                'source': source,
            },
        });
    }

    /**
     * @returns FactorCleaningGroup
     * @throws ApiError
     */
    public static apiV3FactorCleaningGroupCreate({
        requestBody,
    }: {
        requestBody: FactorCleaningGroupRequest,
    }): CancelablePromise<FactorCleaningGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/factorCleaningGroup/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FactorCleaningGroup
     * @throws ApiError
     */
    public static apiV3FactorCleaningGroupRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this factor cleaning group.
         */
        id: number,
    }): CancelablePromise<FactorCleaningGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/factorCleaningGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns FactorCleaningGroup
     * @throws ApiError
     */
    public static apiV3FactorCleaningGroupPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this factor cleaning group.
         */
        id: number,
        requestBody: FactorCleaningGroupRequest,
    }): CancelablePromise<FactorCleaningGroup> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/factorCleaningGroup/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3FactorCleaningGroupDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this factor cleaning group.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/factorCleaningGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedFactorFryingGroupList
     * @throws ApiError
     */
    public static apiV3FactorFryingGroupList({
        factorGt,
        id,
        ids,
        ordering,
        page,
        pageSize,
        rejected,
        search,
        source,
    }: {
        /**
         * Filter on factor less than
         */
        factorGt?: number,
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * Filter on rejected (! to negate)
         */
        rejected?: string,
        /**
         * A search term.
         */
        search?: string,
        /**
         * Filter on source (comma separated)
         */
        source?: string,
    }): CancelablePromise<PaginatedFactorFryingGroupList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/factorFryingGroup/',
            query: {
                'factor__gt': factorGt,
                'id': id,
                'ids': ids,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'rejected': rejected,
                'search': search,
                'source': source,
            },
        });
    }

    /**
     * @returns FactorFryingGroup
     * @throws ApiError
     */
    public static apiV3FactorFryingGroupCreate({
        requestBody,
    }: {
        requestBody: FactorFryingGroupRequest,
    }): CancelablePromise<FactorFryingGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/factorFryingGroup/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FactorFryingGroup
     * @throws ApiError
     */
    public static apiV3FactorFryingGroupRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this factor frying group.
         */
        id: number,
    }): CancelablePromise<FactorFryingGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/factorFryingGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns FactorFryingGroup
     * @throws ApiError
     */
    public static apiV3FactorFryingGroupPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this factor frying group.
         */
        id: number,
        requestBody: FactorFryingGroupRequest,
    }): CancelablePromise<FactorFryingGroup> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/factorFryingGroup/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3FactorFryingGroupDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this factor frying group.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/factorFryingGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedFactorHydratationGroupList
     * @throws ApiError
     */
    public static apiV3FactorHydratationGroupList({
        factorGt,
        id,
        ids,
        ordering,
        page,
        pageSize,
        rejected,
        search,
        source,
    }: {
        /**
         * Filter on factor less than
         */
        factorGt?: number,
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * Filter on rejected (! to negate)
         */
        rejected?: string,
        /**
         * A search term.
         */
        search?: string,
        /**
         * Filter on source (comma separated)
         */
        source?: string,
    }): CancelablePromise<PaginatedFactorHydratationGroupList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/factorHydratationGroup/',
            query: {
                'factor__gt': factorGt,
                'id': id,
                'ids': ids,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'rejected': rejected,
                'search': search,
                'source': source,
            },
        });
    }

    /**
     * @returns FactorHydratationGroup
     * @throws ApiError
     */
    public static apiV3FactorHydratationGroupCreate({
        requestBody,
    }: {
        requestBody: FactorHydratationGroupRequest,
    }): CancelablePromise<FactorHydratationGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/factorHydratationGroup/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FactorHydratationGroup
     * @throws ApiError
     */
    public static apiV3FactorHydratationGroupRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this factor hydratation group.
         */
        id: number,
    }): CancelablePromise<FactorHydratationGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/factorHydratationGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns FactorHydratationGroup
     * @throws ApiError
     */
    public static apiV3FactorHydratationGroupPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this factor hydratation group.
         */
        id: number,
        requestBody: FactorHydratationGroupRequest,
    }): CancelablePromise<FactorHydratationGroup> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/factorHydratationGroup/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3FactorHydratationGroupDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this factor hydratation group.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/factorHydratationGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ExportFile
     * @throws ApiError
     */
    public static apiV3FileRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this export file.
         */
        id: number,
    }): CancelablePromise<ExportFile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/file/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedGuestSpecificationList
     * @throws ApiError
     */
    public static apiV3GuestSpecificationList({
        id,
        kind,
        names,
        ordering,
        page,
        pageSize,
    }: {
        /**
         * object Id
         */
        id?: string,
        /**
         * Filter on kind (comma separated)
         */
        kind?: string,
        /**
         * object name (comma separated)
         */
        names?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedGuestSpecificationList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/guestSpecification/',
            query: {
                'id': id,
                'kind': kind,
                'names': names,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns GuestSpecification
     * @throws ApiError
     */
    public static apiV3GuestSpecificationCreate({
        requestBody,
    }: {
        requestBody: GuestSpecificationRequest,
    }): CancelablePromise<GuestSpecification> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/guestSpecification/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GuestSpecification
     * @throws ApiError
     */
    public static apiV3GuestSpecificationRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this guest specification.
         */
        id: number,
    }): CancelablePromise<GuestSpecification> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/guestSpecification/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns GuestSpecification
     * @throws ApiError
     */
    public static apiV3GuestSpecificationPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this guest specification.
         */
        id: number,
        requestBody: GuestSpecificationRequest,
    }): CancelablePromise<GuestSpecification> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/guestSpecification/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3GuestSpecificationDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this guest specification.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/guestSpecification/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedIngredientV3List
     * @throws ApiError
     */
    public static apiV3IngredientV3List({
        fnNames,
        id,
        ids,
        names,
        ordering,
        page,
        pageSize,
        search,
    }: {
        /**
         * filter on factor group name
         */
        fnNames?: string,
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * filter on name
         */
        names?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedIngredientV3List> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/ingredientV3/',
            query: {
                'fn_names': fnNames,
                'id': id,
                'ids': ids,
                'names': names,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns IngredientV3
     * @throws ApiError
     */
    public static apiV3IngredientV3Create({
        requestBody,
    }: {
        requestBody?: IngredientV3Request,
    }): CancelablePromise<IngredientV3> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/ingredientV3/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IngredientV3
     * @throws ApiError
     */
    public static apiV3IngredientV3Retrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this ingredient v3.
         */
        id: number,
    }): CancelablePromise<IngredientV3> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/ingredientV3/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns IngredientV3
     * @throws ApiError
     */
    public static apiV3IngredientV3PartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this ingredient v3.
         */
        id: number,
        requestBody?: IngredientV3Request,
    }): CancelablePromise<IngredientV3> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/ingredientV3/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3IngredientV3Destroy({
        id,
    }: {
        /**
         * A unique integer value identifying this ingredient v3.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/ingredientV3/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedMenuList
     * @throws ApiError
     */
    public static apiV3MenuList({
        abnomalAmountOfDays,
        abnomalAmountOfMeals,
        after,
        allergens,
        before,
        children,
        companyId,
        companyPath,
        deleted,
        duration,
        environments,
        hasNullQuantities,
        highEnvironmentOffer,
        highNutrientOffer,
        highPurchasePriceOffer,
        id,
        ids,
        idsList,
        idsOlist,
        isPublished,
        isTemplate,
        labels,
        labelsExclNoKeys,
        limit,
        localGreaterThan,
        lowLabelsOffer,
        lowMarginOffer,
        lowNutrientOffer,
        nutrients,
        offSeasonGreaterThan,
        ongoing,
        ordering,
        otherCountryGreaterThan,
        owned,
        page,
        pageSize,
        parent,
        productIds,
        search,
        status,
        statusLiTs,
        statusAllergens,
        statusCosts,
        statusEcoscore,
        statusEnvironments,
        statusLabels,
        statusNames,
        statusNutrients,
        statusNutriscore,
        statusOrigin,
        statusParsing,
        statusSeasonality,
        statusUpdate,
        uuid,
        worldGreaterThan,
    }: {
        /**
         * show menu with an abnomal amount of days compare to the average
         */
        abnomalAmountOfDays?: 'yes' | 'no',
        /**
         * show menu with an abnomal amount of meals compare to the average
         */
        abnomalAmountOfMeals?: 'yes' | 'no',
        /**
         * Menu started after a given date
         */
        after?: string,
        /**
         * filter on allergens (comma separated).  Syntax is: allergenKey1:A|T,allergenKey2:S
         */
        allergens?: string,
        /**
         * Menu started before to a given date
         */
        before?: string,
        /**
         * Children object Id
         */
        children?: string,
        /**
         * Id of product company (comma separated)
         */
        companyId?: number,
        /**
         * start path of product company, the request can be negated with !
         */
        companyPath?: string,
        /**
         * show deleted objects
         */
        deleted?: 'yes' | 'no',
        /**
         * Number of days in the menu (comma serparated)
         */
        duration?: number,
        /**
         * filter on envirionment (comma separated).  Syntax is: envKey1:minValue|maxValue,envKey2:minValue|maxValue
         */
        environments?: string,
        /**
         * show menu with menuEntries without quantity field
         */
        hasNullQuantities?: 'yes' | 'no',
        /**
         * filter on menu with high amount of recipe with a high environment foot print.  Syntax is: envKey
         */
        highEnvironmentOffer?: string,
        /**
         * filter on menu with high amount of recipe with a certain nutrient.  Syntax is: nutKey
         */
        highNutrientOffer?: string,
        /**
         * filter on menu with high amount of recipe with a high purchase price.  Syntax is: price
         */
        highPurchasePriceOffer?: string,
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * object Id (comma separated). Return a list view not detailed
         */
        idsList?: string,
        /**
         * object Id (comma separated). Return an ordered list view not detailed
         */
        idsOlist?: string,
        /**
         * show published objects
         */
        isPublished?: 'yes' | 'no',
        /**
         * is it a template ?
         */
        isTemplate?: 'yes' | 'no',
        /**
         * filter on labels (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labels?: string,
        /**
         * Exclude produts that have not the label key (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsExclNoKeys?: string,
        /**
         * Limit the number of results
         */
        limit?: number,
        /**
         * Has the menu more than x local entries ?
         */
        localGreaterThan?: number,
        /**
         * parent object Id (comma separated)
         */
        lowLabelsOffer?: string,
        /**
         * filter on menu with high amount of recipe with a high purchase price.  Syntax is: price
         */
        lowMarginOffer?: string,
        /**
         * filter on menu with low amount of recipe with a certain nutrient.  Syntax is: nutKey
         */
        lowNutrientOffer?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1:minValue|maxValue,nutrientKey2:minValue|maxValue
         */
        nutrients?: string,
        /**
         * Has the menu more than x not seasonal entries ?
         */
        offSeasonGreaterThan?: number,
        /**
         * currently available
         */
        ongoing?: 'yes' | 'no',
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * Has the menu more than x entries from another country (non border) ?
         */
        otherCountryGreaterThan?: number,
        owned?: boolean,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * Parent object Id
         */
        parent?: string,
        /**
         * products object Id (comma separated)
         */
        productIds?: string,
        /**
         * A search term.
         */
        search?: string,
        /**
         * global status
         */
        status?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusLiTs?: 'valid' | 'invalid' | 'unknown',
        /**
         * allergens status
         */
        statusAllergens?: 'valid' | 'invalid' | 'unknown',
        /**
         * costs status
         */
        statusCosts?: 'valid' | 'invalid' | 'unknown',
        /**
         * ecoscore status
         */
        statusEcoscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironments?: 'valid' | 'invalid' | 'unknown',
        /**
         * Labels status
         */
        statusLabels?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusNames?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusNutrients?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutriscore status
         */
        statusNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * Origin status
         */
        statusOrigin?: 'valid' | 'invalid' | 'unknown',
        /**
         * parsing status (comma separated)
         */
        statusParsing?: 'valid' | 'invalid',
        /**
         * Seasonality status
         */
        statusSeasonality?: 'valid' | 'invalid' | 'unknown',
        /**
         * product update status (comma separated)
         */
        statusUpdate?: 'undefined' | 'pending' | 'notified' | 'errors' | 'success' | '!undefined' | '!pending' | '!notified' | '!errors' | '!success',
        /**
         * object UUID
         */
        uuid?: string,
        /**
         * Has the menu more than x entries from another country (non border) ?
         */
        worldGreaterThan?: number,
    }): CancelablePromise<PaginatedMenuList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/',
            query: {
                'abnomal_amount_of_days': abnomalAmountOfDays,
                'abnomal_amount_of_meals': abnomalAmountOfMeals,
                'after': after,
                'allergens': allergens,
                'before': before,
                'children': children,
                'company_id': companyId,
                'company_path': companyPath,
                'deleted': deleted,
                'duration': duration,
                'environments': environments,
                'has_null_quantities': hasNullQuantities,
                'high_environment_offer': highEnvironmentOffer,
                'high_nutrient_offer': highNutrientOffer,
                'high_purchase_price_offer': highPurchasePriceOffer,
                'id': id,
                'ids': ids,
                'ids_list': idsList,
                'ids_olist': idsOlist,
                'isPublished': isPublished,
                'isTemplate': isTemplate,
                'labels': labels,
                'labels_excl_no_keys': labelsExclNoKeys,
                'limit': limit,
                'local_greaterThan': localGreaterThan,
                'low_labels_offer': lowLabelsOffer,
                'low_margin_offer': lowMarginOffer,
                'low_nutrient_offer': lowNutrientOffer,
                'nutrients': nutrients,
                'off_season_greaterThan': offSeasonGreaterThan,
                'ongoing': ongoing,
                'ordering': ordering,
                'other_country_greaterThan': otherCountryGreaterThan,
                'owned': owned,
                'page': page,
                'page_size': pageSize,
                'parent': parent,
                'product_ids': productIds,
                'search': search,
                'status': status,
                'status_LITs': statusLiTs,
                'status_allergens': statusAllergens,
                'status_costs': statusCosts,
                'status_ecoscore': statusEcoscore,
                'status_environments': statusEnvironments,
                'status_labels': statusLabels,
                'status_names': statusNames,
                'status_nutrients': statusNutrients,
                'status_nutriscore': statusNutriscore,
                'status_origin': statusOrigin,
                'status_parsing': statusParsing,
                'status_seasonality': statusSeasonality,
                'status_update': statusUpdate,
                'uuid': uuid,
                'world_greaterThan': worldGreaterThan,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuCreate({
        requestBody,
    }: {
        requestBody: MenuRequest,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
        requestBody: MenuRequest,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/menu/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3MenuDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/menu/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuArchiveCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
        requestBody: MenuRequest,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/{id}/archive/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuCopyCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
        requestBody: MenuRequest,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/{id}/copy/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuExportExcelRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/{id}/export/excel/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuExportJpegRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/{id}/export/jpeg/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuExportOrderListRetrieve({
        id,
        copyToQuantity,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
        copyToQuantity?: boolean,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/{id}/export/order_list/',
            path: {
                'id': id,
            },
            query: {
                'copy_to_quantity': copyToQuantity,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuExportPdfRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/{id}/export/pdf/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuExportPngRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/{id}/export/png/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuExportPurchaseListRetrieve({
        id,
        copyToQuantity,
        expandRecipe,
        fromDate,
        toDate,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
        copyToQuantity?: boolean,
        expandRecipe?: boolean,
        fromDate?: string,
        toDate?: string,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/{id}/export/purchase_list/',
            path: {
                'id': id,
            },
            query: {
                'copy_to_quantity': copyToQuantity,
                'expandRecipe': expandRecipe,
                'from_date': fromDate,
                'to_date': toDate,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuMoveCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
        requestBody: MenuRequest,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/{id}/move/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MenuPurchaseList
     * @throws ApiError
     */
    public static apiV3MenuPurchaseListRetrieve({
        id,
        expandRecipe,
        fromDate,
        toDate,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
        expandRecipe?: boolean,
        fromDate?: string,
        toDate?: string,
    }): CancelablePromise<MenuPurchaseList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/{id}/purchase_list/',
            path: {
                'id': id,
            },
            query: {
                'expandRecipe': expandRecipe,
                'from_date': fromDate,
                'to_date': toDate,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuRefreshRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/{id}/refresh/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuRepeatCreate({
        id,
        startValidities,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
        /**
         * comma separated list of menu start validities (must be mondays)
         */
        startValidities: string,
        requestBody: MenuRequest,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/{id}/repeat/',
            path: {
                'id': id,
            },
            query: {
                'start_validities': startValidities,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuUpdateQuantityOrderedCreate({
        id,
        requestBody,
        copyToQuantity,
    }: {
        /**
         * A unique integer value identifying this menu.
         */
        id: number,
        requestBody: MenuRequest,
        copyToQuantity?: boolean,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/{id}/update_quantity_ordered/',
            path: {
                'id': id,
            },
            query: {
                'copy_to_quantity': copyToQuantity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuArchiveMultiCreate({
        ids,
        requestBody,
    }: {
        /**
         * comma separated list of product ids
         */
        ids: string,
        requestBody: MenuRequest,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/archive_multi/',
            query: {
                'ids': ids,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuCopyMultiCreate({
        requestBody,
    }: {
        requestBody: MenuRequest,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/copy_multi/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuCopyMultiAsyncCreate({
        requestBody,
    }: {
        requestBody: MenuRequest,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/copy_multi_async/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3MenuDeleteMultiDestroy({
        ids,
    }: {
        /**
         * comma separated list of product ids
         */
        ids: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/menu/delete_multi/',
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static menuExportOrderListList({
        copyToQuantity,
    }: {
        copyToQuantity?: boolean,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/export/order_list/',
            query: {
                'copy_to_quantity': copyToQuantity,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static menuExportPurchaseListList({
        abnomalAmountOfDays,
        abnomalAmountOfMeals,
        after,
        allergens,
        before,
        children,
        companyId,
        companyPath,
        deleted,
        duration,
        environments,
        expandRecipe,
        hasNullQuantities,
        highEnvironmentOffer,
        highNutrientOffer,
        highPurchasePriceOffer,
        id,
        ids,
        idsList,
        idsOlist,
        isPublished,
        isTemplate,
        labels,
        labelsExclNoKeys,
        limit,
        localGreaterThan,
        lowLabelsOffer,
        lowMarginOffer,
        lowNutrientOffer,
        nutrients,
        offSeasonGreaterThan,
        ongoing,
        ordering,
        otherCountryGreaterThan,
        owned,
        parent,
        productIds,
        search,
        status,
        statusLiTs,
        statusAllergens,
        statusCosts,
        statusEcoscore,
        statusEnvironments,
        statusLabels,
        statusNames,
        statusNutrients,
        statusNutriscore,
        statusOrigin,
        statusParsing,
        statusSeasonality,
        statusUpdate,
        uuid,
        worldGreaterThan,
    }: {
        /**
         * show menu with an abnomal amount of days compare to the average
         */
        abnomalAmountOfDays?: 'yes' | 'no',
        /**
         * show menu with an abnomal amount of meals compare to the average
         */
        abnomalAmountOfMeals?: 'yes' | 'no',
        /**
         * Menu started after a given date
         */
        after?: string,
        /**
         * filter on allergens (comma separated).  Syntax is: allergenKey1:A|T,allergenKey2:S
         */
        allergens?: string,
        /**
         * Menu started before to a given date
         */
        before?: string,
        /**
         * Children object Id
         */
        children?: string,
        /**
         * Id of product company (comma separated)
         */
        companyId?: number,
        /**
         * start path of product company, the request can be negated with !
         */
        companyPath?: string,
        /**
         * show deleted objects
         */
        deleted?: 'yes' | 'no',
        /**
         * Number of days in the menu (comma serparated)
         */
        duration?: number,
        /**
         * filter on envirionment (comma separated).  Syntax is: envKey1:minValue|maxValue,envKey2:minValue|maxValue
         */
        environments?: string,
        expandRecipe?: boolean,
        /**
         * show menu with menuEntries without quantity field
         */
        hasNullQuantities?: 'yes' | 'no',
        /**
         * filter on menu with high amount of recipe with a high environment foot print.  Syntax is: envKey
         */
        highEnvironmentOffer?: string,
        /**
         * filter on menu with high amount of recipe with a certain nutrient.  Syntax is: nutKey
         */
        highNutrientOffer?: string,
        /**
         * filter on menu with high amount of recipe with a high purchase price.  Syntax is: price
         */
        highPurchasePriceOffer?: string,
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * object Id (comma separated). Return a list view not detailed
         */
        idsList?: string,
        /**
         * object Id (comma separated). Return an ordered list view not detailed
         */
        idsOlist?: string,
        /**
         * show published objects
         */
        isPublished?: 'yes' | 'no',
        /**
         * is it a template ?
         */
        isTemplate?: 'yes' | 'no',
        /**
         * filter on labels (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labels?: string,
        /**
         * Exclude produts that have not the label key (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsExclNoKeys?: string,
        /**
         * Limit the number of results
         */
        limit?: number,
        /**
         * Has the menu more than x local entries ?
         */
        localGreaterThan?: number,
        /**
         * parent object Id (comma separated)
         */
        lowLabelsOffer?: string,
        /**
         * filter on menu with high amount of recipe with a high purchase price.  Syntax is: price
         */
        lowMarginOffer?: string,
        /**
         * filter on menu with low amount of recipe with a certain nutrient.  Syntax is: nutKey
         */
        lowNutrientOffer?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1:minValue|maxValue,nutrientKey2:minValue|maxValue
         */
        nutrients?: string,
        /**
         * Has the menu more than x not seasonal entries ?
         */
        offSeasonGreaterThan?: number,
        /**
         * currently available
         */
        ongoing?: 'yes' | 'no',
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * Has the menu more than x entries from another country (non border) ?
         */
        otherCountryGreaterThan?: number,
        owned?: boolean,
        /**
         * Parent object Id
         */
        parent?: string,
        /**
         * products object Id (comma separated)
         */
        productIds?: string,
        /**
         * A search term.
         */
        search?: string,
        /**
         * global status
         */
        status?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusLiTs?: 'valid' | 'invalid' | 'unknown',
        /**
         * allergens status
         */
        statusAllergens?: 'valid' | 'invalid' | 'unknown',
        /**
         * costs status
         */
        statusCosts?: 'valid' | 'invalid' | 'unknown',
        /**
         * ecoscore status
         */
        statusEcoscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironments?: 'valid' | 'invalid' | 'unknown',
        /**
         * Labels status
         */
        statusLabels?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusNames?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusNutrients?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutriscore status
         */
        statusNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * Origin status
         */
        statusOrigin?: 'valid' | 'invalid' | 'unknown',
        /**
         * parsing status (comma separated)
         */
        statusParsing?: 'valid' | 'invalid',
        /**
         * Seasonality status
         */
        statusSeasonality?: 'valid' | 'invalid' | 'unknown',
        /**
         * product update status (comma separated)
         */
        statusUpdate?: 'undefined' | 'pending' | 'notified' | 'errors' | 'success' | '!undefined' | '!pending' | '!notified' | '!errors' | '!success',
        /**
         * object UUID
         */
        uuid?: string,
        /**
         * Has the menu more than x entries from another country (non border) ?
         */
        worldGreaterThan?: number,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menu/export/purchase_list/',
            query: {
                'abnomal_amount_of_days': abnomalAmountOfDays,
                'abnomal_amount_of_meals': abnomalAmountOfMeals,
                'after': after,
                'allergens': allergens,
                'before': before,
                'children': children,
                'company_id': companyId,
                'company_path': companyPath,
                'deleted': deleted,
                'duration': duration,
                'environments': environments,
                'expandRecipe': expandRecipe,
                'has_null_quantities': hasNullQuantities,
                'high_environment_offer': highEnvironmentOffer,
                'high_nutrient_offer': highNutrientOffer,
                'high_purchase_price_offer': highPurchasePriceOffer,
                'id': id,
                'ids': ids,
                'ids_list': idsList,
                'ids_olist': idsOlist,
                'isPublished': isPublished,
                'isTemplate': isTemplate,
                'labels': labels,
                'labels_excl_no_keys': labelsExclNoKeys,
                'limit': limit,
                'local_greaterThan': localGreaterThan,
                'low_labels_offer': lowLabelsOffer,
                'low_margin_offer': lowMarginOffer,
                'low_nutrient_offer': lowNutrientOffer,
                'nutrients': nutrients,
                'off_season_greaterThan': offSeasonGreaterThan,
                'ongoing': ongoing,
                'ordering': ordering,
                'other_country_greaterThan': otherCountryGreaterThan,
                'owned': owned,
                'parent': parent,
                'product_ids': productIds,
                'search': search,
                'status': status,
                'status_LITs': statusLiTs,
                'status_allergens': statusAllergens,
                'status_costs': statusCosts,
                'status_ecoscore': statusEcoscore,
                'status_environments': statusEnvironments,
                'status_labels': statusLabels,
                'status_names': statusNames,
                'status_nutrients': statusNutrients,
                'status_nutriscore': statusNutriscore,
                'status_origin': statusOrigin,
                'status_parsing': statusParsing,
                'status_seasonality': statusSeasonality,
                'status_update': statusUpdate,
                'uuid': uuid,
                'world_greaterThan': worldGreaterThan,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuMoveMultiCreate({
        requestBody,
    }: {
        requestBody: MenuRequest,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/move_multi/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3MenuPublishMultiCreate({
        ids,
        requestBody,
    }: {
        /**
         * comma separated list of product ids
         */
        ids: string,
        requestBody: MenuRequest,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/publish_multi/',
            query: {
                'ids': ids,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static menuUpdateQuantityOrderedList({
        requestBody,
        copyToQuantity,
    }: {
        requestBody: MenuRequest,
        copyToQuantity?: boolean,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menu/update_quantity_ordered/',
            query: {
                'copy_to_quantity': copyToQuantity,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * list:
     * Retrieve the list of menu entries (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific menuEntries.
     *
     * create:
     * Create a new menu Entry.
     *
     * delete:
     * Remove an existing menu Entry.
     *
     * partial_update:
     * Update one or more fields on an existing menu Entry.
     *
     * update:
     * Update a menu Entry.
     * @returns PaginatedMenuEntryList
     * @throws ApiError
     */
    public static apiV3MenuEntryList({
        menuIds,
        owned,
        page,
        pageSize,
        productIds,
    }: {
        /**
         * object Id (comma separated)
         */
        menuIds?: string,
        owned?: boolean,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * object Id (comma separated)
         */
        productIds?: string,
    }): CancelablePromise<PaginatedMenuEntryList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/menuEntry/',
            query: {
                'menu_ids': menuIds,
                'owned': owned,
                'page': page,
                'page_size': pageSize,
                'product_ids': productIds,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of menu entries (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific menuEntries.
     *
     * create:
     * Create a new menu Entry.
     *
     * delete:
     * Remove an existing menu Entry.
     *
     * partial_update:
     * Update one or more fields on an existing menu Entry.
     *
     * update:
     * Update a menu Entry.
     * @returns MenuEntry
     * @throws ApiError
     */
    public static apiV3MenuEntryCreate({
        requestBody,
    }: {
        requestBody?: MenuEntryRequest,
    }): CancelablePromise<MenuEntry> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/menuEntry/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * list:
     * Retrieve the list of menu entries (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific menuEntries.
     *
     * create:
     * Create a new menu Entry.
     *
     * delete:
     * Remove an existing menu Entry.
     *
     * partial_update:
     * Update one or more fields on an existing menu Entry.
     *
     * update:
     * Update a menu Entry.
     * @returns MenuEntry
     * @throws ApiError
     */
    public static apiV3MenuEntryPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this menu entry.
         */
        id: number,
        requestBody?: MenuEntryRequest,
    }): CancelablePromise<MenuEntry> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/menuEntry/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * list:
     * Retrieve the list of menu entries (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific menuEntries.
     *
     * create:
     * Create a new menu Entry.
     *
     * delete:
     * Remove an existing menu Entry.
     *
     * partial_update:
     * Update one or more fields on an existing menu Entry.
     *
     * update:
     * Update a menu Entry.
     * @returns void
     * @throws ApiError
     */
    public static apiV3MenuEntryDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this menu entry.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/menuEntry/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Single endpoint to get all youmeal assets
     * @returns any
     * @throws ApiError
     */
    public static apiV3MonitorRetrieve(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/monitor/',
        });
    }

    /**
     * list:
     * Retrieve the list of nutrients (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific nutrient.
     *
     * create:
     * Create a new nutrient.
     *
     * delete:
     * Remove an existing nutrient.
     *
     * partial_update:
     * Update one or more fields on an existing nutrient.
     *
     * update:
     * Update an nutrient.
     * @returns PaginatedNutrientList
     * @throws ApiError
     */
    public static apiV3NutrientList({
        id,
        ids,
        legal,
        names,
        ordering,
        page,
        pageSize,
        search,
    }: {
        id?: number,
        /**
         * Multiple values may be separated by commas.
         */
        ids?: Array<string>,
        legal?: boolean,
        names?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedNutrientList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/nutrient/',
            query: {
                'id': id,
                'ids': ids,
                'legal': legal,
                'names': names,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns PaginatedNutritionalGroupList
     * @throws ApiError
     */
    public static apiV3NutritionalGroupList({
        id,
        ids,
        ordering,
        page,
        pageSize,
        search,
        source,
        status,
        statusNutrients,
    }: {
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
        /**
         * Filter on source (comma separated)
         */
        source?: string,
        /**
         * Filter on status (comma separated)
         */
        status?: string,
        /**
         * nutrients status (comma separated)
         */
        statusNutrients?: 'true' | 'false' | 'valid' | 'invalid' | 'unknown',
    }): CancelablePromise<PaginatedNutritionalGroupList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/nutritionalGroup/',
            query: {
                'id': id,
                'ids': ids,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
                'source': source,
                'status': status,
                'status_nutrients': statusNutrients,
            },
        });
    }

    /**
     * @returns NutritionalGroup
     * @throws ApiError
     */
    public static apiV3NutritionalGroupCreate({
        requestBody,
    }: {
        requestBody?: NutritionalGroupRequest,
    }): CancelablePromise<NutritionalGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/nutritionalGroup/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns NutritionalGroup
     * @throws ApiError
     */
    public static apiV3NutritionalGroupRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this nutritional group.
         */
        id: number,
    }): CancelablePromise<NutritionalGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/nutritionalGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns NutritionalGroup
     * @throws ApiError
     */
    public static apiV3NutritionalGroupPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this nutritional group.
         */
        id: number,
        requestBody?: NutritionalGroupRequest,
    }): CancelablePromise<NutritionalGroup> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/nutritionalGroup/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3NutritionalGroupDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this nutritional group.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/nutritionalGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedOriginList
     * @throws ApiError
     */
    public static apiV3OriginList({
        ids,
        name,
        ordering,
        page,
        pageSize,
        search,
    }: {
        /**
         * Multiple values may be separated by commas.
         */
        ids?: Array<string>,
        name?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedOriginList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/origin/',
            query: {
                'ids': ids,
                'name': name,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns Origin
     * @throws ApiError
     */
    public static apiV3OriginCreate({
        requestBody,
    }: {
        requestBody: OriginRequest,
    }): CancelablePromise<Origin> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/origin/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Origin
     * @throws ApiError
     */
    public static apiV3OriginRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this origin.
         */
        id: number,
    }): CancelablePromise<Origin> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/origin/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Origin
     * @throws ApiError
     */
    public static apiV3OriginPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this origin.
         */
        id: number,
        requestBody: OriginRequest,
    }): CancelablePromise<Origin> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/origin/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3OriginDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this origin.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/origin/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedPackagingList
     * @throws ApiError
     */
    public static apiV3PackagingList({
        ids,
        name,
        ordering,
        page,
        pageSize,
        search,
    }: {
        /**
         * Multiple values may be separated by commas.
         */
        ids?: Array<string>,
        name?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedPackagingList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/packaging/',
            query: {
                'ids': ids,
                'name': name,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns Packaging
     * @throws ApiError
     */
    public static apiV3PackagingCreate({
        requestBody,
    }: {
        requestBody: PackagingRequest,
    }): CancelablePromise<Packaging> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/packaging/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Packaging
     * @throws ApiError
     */
    public static apiV3PackagingRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this packaging.
         */
        id: number,
    }): CancelablePromise<Packaging> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/packaging/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Packaging
     * @throws ApiError
     */
    public static apiV3PackagingPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this packaging.
         */
        id: number,
        requestBody: PackagingRequest,
    }): CancelablePromise<Packaging> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/packaging/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3PackagingDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this packaging.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/packaging/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedParsingPatternList
     * @throws ApiError
     */
    public static apiV3ParsingPatternList({
        page,
        pageSize,
    }: {
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedParsingPatternList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/parsingPattern/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ParsingPattern
     * @throws ApiError
     */
    public static apiV3ParsingPatternCreate({
        requestBody,
    }: {
        requestBody: ParsingPatternRequest,
    }): CancelablePromise<ParsingPattern> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/parsingPattern/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ParsingPattern
     * @throws ApiError
     */
    public static apiV3ParsingPatternRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this parsing pattern.
         */
        id: number,
    }): CancelablePromise<ParsingPattern> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/parsingPattern/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ParsingPattern
     * @throws ApiError
     */
    public static apiV3ParsingPatternPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this parsing pattern.
         */
        id: number,
        requestBody: ParsingPatternRequest,
    }): CancelablePromise<ParsingPattern> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/parsingPattern/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3ParsingPatternDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this parsing pattern.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/parsingPattern/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of product (ingredient or recipe) owned by your company hierarchy (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each product (use GET on a specific product to get all the details)
     * The following products are returned:
     * - owned by your company
     * - owned by companies above your company in the hierarchy
     * - owned by companies below your company in the hiearchy
     * Product that are owned by companies that are not a direct ancestor or descendant won't be returned.
     *
     * retrieve:
     * Retrieve all information about a specific product (ingredient or recipe).
     *
     * create:
     * Create a new product (ingredient or recipe).
     *
     * delete:
     * Remove an existing product (ingredient or recipe).
     *
     * partial_update:
     * Update one or more fields on an existing product (ingredient or recipe).
     *
     * update:
     * Update a product (ingredient or recipe).
     * @returns PaginatedProductList
     * @throws ApiError
     */
    public static apiV3ProductList({
        ean,
        allegations,
        allegationsAny,
        allegationsAnyExcl,
        allegationsExcl,
        allergens,
        allergensChanges,
        allergensPotentialMissing,
        available,
        category,
        childrenCategory,
        childrenCompanyId,
        childrenCompanyName,
        childrenCompanyPath,
        childrenContainsIngredient,
        childrenDeleted,
        childrenEcoscore,
        childrenHasChild,
        childrenHasFile,
        childrenHasIngredient,
        childrenHasLit,
        childrenHasParent,
        childrenHasAnyLabelEcoscore,
        childrenHasUnkownRecipe,
        childrenIds,
        childrenIdsList,
        childrenIsPricePerGr,
        childrenLevel,
        childrenMenuId,
        childrenMenusAfter,
        childrenMenusBefore,
        childrenMenusCompanyId,
        childrenMenusFlatAfter,
        childrenMenusFlatBefore,
        childrenMenusFlatCompanyId,
        childrenMissingLiTs,
        childrenMissingNames,
        childrenMissingTitles,
        childrenNatural,
        childrenNutriscore,
        childrenNutriscoreDiff,
        childrenNutriscoreRaw,
        childrenOidsList,
        childrenOnTheMenu,
        childrenOrderByIngrFoodCost,
        childrenOrderByIngrQuantitySold,
        childrenOrderByMenuEntriesQuantity,
        childrenOrderByProductLinkFactorCooked,
        childrenOrderByProductLinkFactorRaw,
        childrenOrderByRecQuantitySold,
        childrenOrderByRecipFoodCost,
        childrenParsingLang,
        childrenParsingTrans,
        childrenPurchasePriceLocal,
        childrenSource,
        childrenStatus,
        childrenStatusLiTs,
        childrenStatusAllergens,
        childrenStatusAllergensAnimal,
        childrenStatusAllergensLegal,
        childrenStatusCosts,
        childrenStatusEcoscore,
        childrenStatusEnvironments,
        childrenStatusEnvironmentsCo2,
        childrenStatusEnvironmentsGnd,
        childrenStatusEnvironmentsH2Oc,
        childrenStatusEnvironmentsPefScore,
        childrenStatusLabels,
        childrenStatusNames,
        childrenStatusNutrients,
        childrenStatusNutrientsLegal,
        childrenStatusNutrientsNutriscore,
        childrenStatusNutrientsOther,
        childrenStatusNutriscore,
        childrenStatusOrigin,
        childrenStatusParsing,
        childrenStatusQunc,
        childrenStatusSeasonality,
        childrenStatusTranslations,
        childrenStatusUpdate,
        childrenTagsAll,
        childrenTagsAny,
        childrenTagsCodeAll,
        childrenTagsCodeAny,
        childrenTagsCodeExclAny,
        childrenTagsExclAny,
        childrenTippingEcoscore,
        childrenTippingNutriscore,
        childrenType,
        childrenUsedByMenu,
        childrenUsedByProduct,
        childrenUsingProduct,
        comment,
        companyId,
        companyName,
        companyPath,
        conservation,
        containsIngredient,
        couplingHasEan,
        couplingHasRef,
        couplingIsEmpty,
        deleted,
        ecoscore,
        environmentLevels,
        environments,
        generic,
        gln,
        hasChild,
        hasFactorCooked,
        hasFactorRaw,
        hasFile,
        hasIngredient,
        hasLit,
        hasParent,
        hasAnyLabelEcoscore,
        hasFileType,
        hasFryedIngredients,
        hasFryedProductLinkFactor,
        hasFryingBath,
        hasHighFryingBathQuantity,
        hasProductLink,
        hasProductLinkFactorRaw,
        hasProductLinkParent,
        hasProductLinkYmid,
        hasUnkownRecipe,
        hasUrl,
        highEnvironmentOfferIngredients,
        highEnvironmentOfferRecipes,
        highNutrientOfferIngredients,
        highNutrientOfferRecipes,
        highPurchasePriceOfferIngredients,
        highPurchasePriceOfferRecipes,
        id,
        ids,
        idsList,
        isPricePerGr,
        isProductLinkCooked,
        isVeggie,
        labels,
        labelsAny,
        labelsAnyExcl,
        labelsExcl,
        labelsExclNoKeys,
        labelsHasAny,
        level,
        limit,
        locality,
        lowMarginOfferRecipes,
        lowNutrientOfferIngredients,
        lowNutrientOfferRecipes,
        margin,
        markets,
        menuId,
        menusAfter,
        menusBefore,
        menusCompanyId,
        menusFlatAfter,
        menusFlatBefore,
        menusFlatCompanyId,
        missingLiTs,
        missingConversion,
        missingNames,
        missingTitles,
        names,
        namesLang,
        natural,
        nutrientAllMissing,
        nutrientHasAll,
        nutrientHasAny,
        nutrientLevels,
        nutrientNotAllMissing,
        nutrients,
        nutriscore,
        nutriscoreDiff,
        nutriscoreRaw,
        oidsList,
        onTheMenu,
        orderByIngrFoodCost,
        orderByIngrQuantitySold,
        orderByMenuEntriesQuantity,
        orderByProductLinkFactorCooked,
        orderByProductLinkFactorRaw,
        orderByRecQuantitySold,
        orderByRecipFoodCost,
        ordering,
        origin,
        owned,
        packaging,
        page,
        pageSize,
        parentsCategory,
        parentsCompanyId,
        parentsCompanyName,
        parentsCompanyPath,
        parentsContainsIngredient,
        parentsEcoscore,
        parentsHasChild,
        parentsHasFile,
        parentsHasIngredient,
        parentsHasLit,
        parentsHasParent,
        parentsHasAnyLabelEcoscore,
        parentsHasUnkownRecipe,
        parentsIds,
        parentsIdsList,
        parentsIsPricePerGr,
        parentsLevel,
        parentsMenuId,
        parentsMenusAfter,
        parentsMenusBefore,
        parentsMenusCompanyId,
        parentsMenusFlatAfter,
        parentsMenusFlatBefore,
        parentsMenusFlatCompanyId,
        parentsMissingLiTs,
        parentsMissingNames,
        parentsMissingTitles,
        parentsNatural,
        parentsNutriscore,
        parentsNutriscoreDiff,
        parentsNutriscoreRaw,
        parentsOidsList,
        parentsOnTheMenu,
        parentsOrderByIngrFoodCost,
        parentsOrderByIngrQuantitySold,
        parentsOrderByMenuEntriesQuantity,
        parentsOrderByProductLinkFactorCooked,
        parentsOrderByProductLinkFactorRaw,
        parentsOrderByRecQuantitySold,
        parentsOrderByRecipFoodCost,
        parentsParsingLang,
        parentsParsingTrans,
        parentsPurchasePriceLocal,
        parentsSource,
        parentsStatus,
        parentsStatusLiTs,
        parentsStatusAllergens,
        parentsStatusAllergensAnimal,
        parentsStatusAllergensLegal,
        parentsStatusCosts,
        parentsStatusEcoscore,
        parentsStatusEnvironments,
        parentsStatusEnvironmentsCo2,
        parentsStatusEnvironmentsGnd,
        parentsStatusEnvironmentsH2Oc,
        parentsStatusEnvironmentsPefScore,
        parentsStatusLabels,
        parentsStatusNames,
        parentsStatusNutrients,
        parentsStatusNutrientsLegal,
        parentsStatusNutrientsNutriscore,
        parentsStatusNutrientsOther,
        parentsStatusNutriscore,
        parentsStatusOrigin,
        parentsStatusParsing,
        parentsStatusQunc,
        parentsStatusSeasonality,
        parentsStatusTranslations,
        parentsStatusUpdate,
        parentsTagsAll,
        parentsTagsAny,
        parentsTagsCodeAll,
        parentsTagsCodeAny,
        parentsTagsCodeExclAny,
        parentsTagsExclAny,
        parentsTippingEcoscore,
        parentsTippingNutriscore,
        parentsType,
        parentsUsedByMenu,
        parentsUsedByProduct,
        parentsUsingProduct,
        parsingLang,
        parsingTrans,
        prices,
        productLinkFactorCookedGreaterThan,
        productLinkFactorCookedLowerThan,
        productLinkFactorRawGreaterThan,
        productLinkFactorRawLowerThan,
        purchasePrice,
        purchasePriceLevel,
        purchasePriceLocal,
        reference,
        sameCountry,
        sameRegion,
        sameSubregion,
        search,
        seasonal,
        seasonality,
        seasonalityNa,
        seasonalityRecipeMonth,
        source,
        splitCount,
        splitIndex,
        status,
        statusLiTs,
        statusAllergens,
        statusAllergensAnimal,
        statusAllergensLegal,
        statusCosts,
        statusEcoscore,
        statusEnvironments,
        statusEnvironmentsCo2,
        statusEnvironmentsGnd,
        statusEnvironmentsH2Oc,
        statusEnvironmentsPefScore,
        statusLabels,
        statusNames,
        statusNutrients,
        statusNutrientsLegal,
        statusNutrientsNutriscore,
        statusNutrientsOther,
        statusNutriscore,
        statusOrigin,
        statusParsing,
        statusQunc,
        statusSeasonality,
        statusTranslations,
        statusUpdate,
        subtitles,
        subtitlesLang,
        tagsAll,
        tagsAny,
        tagsCodeAll,
        tagsCodeAny,
        tagsCodeExclAny,
        tagsExclAny,
        tippingEcoscore,
        tippingNutriscore,
        titles,
        titlesLang,
        type,
        updatedAfter,
        updatedBefore,
        usedByMenu,
        usedByProduct,
        usingProduct,
        uuid,
        withConceptGroup,
    }: {
        /**
         * GS1 EAN Number
         */
        ean?: string,
        /**
         * filter on allegations (all of the allegations must be true) (comma separated).  Syntax is: allegationKey1,allegationKey2
         */
        allegations?: string,
        /**
         * filter on allegations (any of the allegations can be true) (comma separated).  Syntax is: allegationKey1,allegationKey2
         */
        allegationsAny?: string,
        /**
         * filter on allegations (any of the allegations can be false) (comma separated).  Syntax is: allegationKey1,allegationKey2
         */
        allegationsAnyExcl?: string,
        /**
         * filter on allegations (exclude them) (comma separated).  Syntax is: allegationKey1,allegationKey2
         */
        allegationsExcl?: string,
        /**
         * filter on allergens (comma separated).  Syntax is: allergenKey1:A|T,allergenKey2:S
         */
        allergens?: string,
        /**
         * allergen changes (comma separated)
         */
        allergensChanges?: string,
        /**
         * filter allergens (legal, animal, forStatus) where potentially A,T or P is missing in computed.  Syntax is: legal:A or forStatus:T or ....
         */
        allergensPotentialMissing?: string,
        /**
         * Is the product available between date_from and date_to. syntax is : date_from|date_to
         */
        available?: string,
        /**
         * product categories contains ANY of these  (comma separated)
         */
        category?: number,
        /**
         * [CHILDREN] product categories contains ANY of these  (comma separated)
         */
        childrenCategory?: number,
        /**
         * [CHILDREN] Id(s) of products company (comma separated)
         */
        childrenCompanyId?: number,
        /**
         * [CHILDREN] name of product company  (comma separated)
         */
        childrenCompanyName?: string,
        /**
         * [CHILDREN] start path of product company, the request can be negated with !
         */
        childrenCompanyPath?: string,
        /**
         * [CHILDREN] IngredientId contained in this product (comma separated)
         */
        childrenContainsIngredient?: number,
        /**
         * show deleted objects
         */
        childrenDeleted?: 'yes' | 'no',
        /**
         * [CHILDREN] subtitles exists for these langs contains (comma separated)
         */
        childrenEcoscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [CHILDREN] has product coupled to it ?
         */
        childrenHasChild?: 'yes' | 'no',
        /**
         * [CHILDREN] is there an attachement ?
         */
        childrenHasFile?: 'yes' | 'no',
        /**
         * [CHILDREN] is it associated to an ingredient ?
         */
        childrenHasIngredient?: 'yes' | 'no',
        /**
         * [CHILDREN] properties of the product LIT (comma separated)
         */
        childrenHasLit?: string,
        /**
         * [CHILDREN] is it coupled ?
         */
        childrenHasParent?: 'yes' | 'no',
        /**
         * [CHILDREN] Is there at least one label used in ecoscore for this product ?
         */
        childrenHasAnyLabelEcoscore?: 'yes' | 'no',
        /**
         * [CHILDREN] Is there a unknown recipe in the LITs ?
         */
        childrenHasUnkownRecipe?: 'yes' | 'no',
        /**
         * [CHILDREN] object Id (comma separated)
         */
        childrenIds?: string,
        /**
         * [CHILDREN] object Id (comma separated). Return a list view not a detailed view
         */
        childrenIdsList?: string,
        /**
         * [CHILDREN] is the price per gramm null or not? is_price_per_gr=null or is_price_per_gr=!null
         */
        childrenIsPricePerGr?: string,
        /**
         * [CHILDREN] object level (comma separated)
         */
        childrenLevel?: number,
        /**
         * [CHILDREN] Retrieve all products used by this menu id
         */
        childrenMenuId?: number,
        /**
         * [CHILDREN] Menu started after a given date
         */
        childrenMenusAfter?: string,
        /**
         * [CHILDREN] Menu started before to a given date
         */
        childrenMenusBefore?: string,
        /**
         * [CHILDREN] Id(s) of menu company (comma separated)
         */
        childrenMenusCompanyId?: number,
        /**
         * [CHILDREN] Menus flat started after a given date
         */
        childrenMenusFlatAfter?: string,
        /**
         * [CHILDREN] Menus flat started before to a given date
         */
        childrenMenusFlatBefore?: string,
        /**
         * [CHILDREN] Id(s) of flat menu company (comma separated)
         */
        childrenMenusFlatCompanyId?: number,
        /**
         * [CHILDREN] Missing LITs in products
         */
        childrenMissingLiTs?: 'yes' | 'no',
        /**
         * [CHILDREN] Missing Names in products
         */
        childrenMissingNames?: 'yes' | 'no',
        /**
         * [CHILDREN] Missing Titles in products
         */
        childrenMissingTitles?: 'yes' | 'no',
        /**
         * [CHILDREN] naturality
         */
        childrenNatural?: 'yes' | 'no' | 'unknown',
        /**
         * [CHILDREN] subtitles exists for these langs contains (comma separated)
         */
        childrenNutriscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [CHILDREN] Is there a difference between youmeal and computed nutriscore ?
         */
        childrenNutriscoreDiff?: 'yes' | 'no',
        /**
         * [CHILDREN] subtitles exists for these langs contains (comma separated)
         */
        childrenNutriscoreRaw?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [CHILDREN] ordered object Id (comma separated)
         */
        childrenOidsList?: string,
        /**
         * [CHILDREN] Filter product (directly 0 or indirectly 1) in menus between date_from and date_to for a certain company. syntax is : company_id:date_from|date_to|(0 or 1)
         */
        childrenOnTheMenu?: string,
        /**
         * [CHILDREN] Order result by food cost of ingredient sold in menuEntries
         */
        childrenOrderByIngrFoodCost?: 'price_per_gr',
        /**
         * [CHILDREN] Order result by food cost of ingredient sold in menuEntries
         */
        childrenOrderByIngrQuantitySold?: 'asc' | 'desc',
        /**
         * [CHILDREN] Order result by quantity of recipe sold in menuEntries (ascending or descending)
         */
        childrenOrderByMenuEntriesQuantity?: 'desc' | 'asc',
        /**
         * [CHILDREN] Order result by product link parent factor cooked (ascending or descending)
         */
        childrenOrderByProductLinkFactorCooked?: 'desc' | 'asc',
        /**
         * [CHILDREN] Order result by product link parent factor raw (ascending or descending)
         */
        childrenOrderByProductLinkFactorRaw?: 'desc' | 'asc',
        /**
         * [CHILDREN] Order result by food cost of recipes sold in menuEntries
         */
        childrenOrderByRecQuantitySold?: 'asc' | 'desc',
        /**
         * [CHILDREN] Order result by food cost of ingredient sold in menuEntries
         */
        childrenOrderByRecipFoodCost?: 'price',
        /**
         * [CHILDREN] language used for parsing (comma separated)
         */
        childrenParsingLang?: 'fr' | 'nl' | 'en' | 'de' | 'sv' | 'pt' | 'es' | 'it' | 'ru' | 'kk' | 'unknown',
        /**
         * [CHILDREN] lang with missing (generated) translations (comma separated)
         */
        childrenParsingTrans?: 'fr' | 'en' | 'nl' | 'de' | 'sv' | 'pt' | 'it' | 'es' | 'ru' | 'kk',
        /**
         * [CHILDREN] is the local purchase price empty? purchase_price_local={} or purchase_price_local=!{}
         */
        childrenPurchasePriceLocal?: '{}',
        /**
         * [CHILDREN] source
         */
        childrenSource?: string,
        /**
         * [CHILDREN] global status
         */
        childrenStatus?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients status
         */
        childrenStatusLiTs?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] allergens legal status
         */
        childrenStatusAllergens?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] allergens status
         */
        childrenStatusAllergensAnimal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] allergens status
         */
        childrenStatusAllergensLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] costs status
         */
        childrenStatusCosts?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] ecoscore status
         */
        childrenStatusEcoscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] environments status
         */
        childrenStatusEnvironments?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] environments status
         */
        childrenStatusEnvironmentsCo2?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] environments status
         */
        childrenStatusEnvironmentsGnd?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] environments status
         */
        childrenStatusEnvironmentsH2Oc?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] environments status
         */
        childrenStatusEnvironmentsPefScore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] Labels status
         */
        childrenStatusLabels?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients status
         */
        childrenStatusNames?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients status
         */
        childrenStatusNutrients?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients legal status
         */
        childrenStatusNutrientsLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients used in nutriscore status
         */
        childrenStatusNutrientsNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients not legal status
         */
        childrenStatusNutrientsOther?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutriscore status
         */
        childrenStatusNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] Origin status
         */
        childrenStatusOrigin?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] parsing status (comma separated)
         */
        childrenStatusParsing?: 'valid' | 'invalid',
        /**
         * [CHILDREN] qunc status
         */
        childrenStatusQunc?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] Seasonality status
         */
        childrenStatusSeasonality?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] translation status (comma separated)
         */
        childrenStatusTranslations?: string,
        /**
         * [CHILDREN] product update status (comma separated)
         */
        childrenStatusUpdate?: 'undefined' | 'pending' | 'notified' | 'errors' | 'success' | '!undefined' | '!pending' | '!notified' | '!errors' | '!success',
        /**
         * [CHILDREN] product tags contains ALL of these  (comma separated)
         */
        childrenTagsAll?: number,
        /**
         * [CHILDREN] product tags contains ANY of these  (comma separated)
         */
        childrenTagsAny?: number,
        /**
         * [CHILDREN] product tags contains ALL of these  (comma separated)
         */
        childrenTagsCodeAll?: string,
        /**
         * [CHILDREN] product tags contains ANY of these  (comma separated)
         */
        childrenTagsCodeAny?: string,
        /**
         * [CHILDREN] product tags contains ANY of these  (comma separated)
         */
        childrenTagsCodeExclAny?: string,
        /**
         * [CHILDREN] product tags contains ANY of these  (comma separated)
         */
        childrenTagsExclAny?: number,
        /**
         * [CHILDREN] Product that are about to tip to the superior score
         */
        childrenTippingEcoscore?: 'yes' | 'no',
        /**
         * [CHILDREN] Product that are about to tip to the superior score
         */
        childrenTippingNutriscore?: 'yes' | 'no',
        /**
         * [CHILDREN] product type  (comma separated)
         */
        childrenType?: 'P' | 'R' | 'FT' | 'YMP' | '!P' | '!R' | '!FT' | '!YMP',
        /**
         * [CHILDREN] Retrieve all products used by this menu ids (comma separated)
         */
        childrenUsedByMenu?: number,
        /**
         * [CHILDREN] Retrieve all products used by this product ids (comma separated)
         */
        childrenUsedByProduct?: number,
        /**
         * [CHILDREN] Retrieve all product using this product ids (comma separated)
         */
        childrenUsingProduct?: number,
        /**
         * comment contains (comma separated)
         */
        comment?: string,
        /**
         * Id(s) of products company (comma separated)
         */
        companyId?: number,
        /**
         * name of product company  (comma separated)
         */
        companyName?: string,
        /**
         * start path of product company, the request can be negated with !
         */
        companyPath?: string,
        /**
         * Is the conservation field equal to ... ?
         */
        conservation?: any,
        /**
         * IngredientId contained in this product (comma separated)
         */
        containsIngredient?: number,
        /**
         * is there an EAN specified in productLinks
         */
        couplingHasEan?: 'yes' | 'no',
        /**
         * is there a reference specified in productLinks
         */
        couplingHasRef?: 'yes' | 'no',
        /**
         * is there a reference specified in productLinks
         */
        couplingIsEmpty?: 'yes' | 'no',
        /**
         * show deleted objects
         */
        deleted?: 'yes' | 'no',
        /**
         * subtitles exists for these langs contains (comma separated)
         */
        ecoscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * filter on environments (comma separated).  Syntax is: envKey1:low,envKey2:high,,envKey3:med
         */
        environmentLevels?: string,
        /**
         * filter on environments (comma separated).  Syntax is: envKey1:minValue|maxValue,envKey2:minValue|maxValue
         */
        environments?: string,
        /**
         * generic vs comercial
         */
        generic?: 'true' | 'false' | 'unknown',
        /**
         * GS1 GLN Number
         */
        gln?: string,
        /**
         * has product coupled to it ?
         */
        hasChild?: 'yes' | 'no',
        /**
         * is there a cooked factors ?
         */
        hasFactorCooked?: 'yes' | 'no',
        /**
         * is there a raw factors ?
         */
        hasFactorRaw?: 'yes' | 'no',
        /**
         * is there an attachement ?
         */
        hasFile?: 'yes' | 'no',
        /**
         * is it associated to an ingredient ?
         */
        hasIngredient?: 'yes' | 'no',
        /**
         * properties of the product LIT (comma separated)
         */
        hasLit?: string,
        /**
         * is it coupled ?
         */
        hasParent?: 'yes' | 'no',
        /**
         * Is there at least one label used in ecoscore for this product ?
         */
        hasAnyLabelEcoscore?: 'yes' | 'no',
        /**
         * is there a file from a specific type attached to the product ?
         */
        hasFileType?: string,
        /**
         * Does the recipes has a fryed ingredients ?
         */
        hasFryedIngredients?: 'yes' | 'no',
        /**
         * Does the parent has the factor (fryed, cooked) when the productlink is fryed ?
         */
        hasFryedProductLinkFactor?: string,
        /**
         * Does the recipe has a frying bath ?
         */
        hasFryingBath?: 'yes' | 'no',
        /**
         * Does the product has a high frying bath quantity?
         */
        hasHighFryingBathQuantity?: 'yes' | 'no',
        /**
         * is there a reference on all productLinks ?
         */
        hasProductLink?: 'yes' | 'no',
        /**
         * product productlink raw parent Factor is not checked
         */
        hasProductLinkFactorRaw?: 'yes' | 'no',
        /**
         * is there a reference on all productLinks ?
         */
        hasProductLinkParent?: 'yes' | 'no',
        /**
         * is there a ymid on productLinks ?
         */
        hasProductLinkYmid?: 'yes' | 'no',
        /**
         * Is there a unknown recipe in the LITs ?
         */
        hasUnkownRecipe?: 'yes' | 'no',
        /**
         * is there an url on product ?
         */
        hasUrl?: 'yes' | 'no',
        /**
         * filter on ingredient menu offer with high environment foot print.  Syntax is: envKey
         */
        highEnvironmentOfferIngredients?: string,
        /**
         * filter on recipe menu offer with high environment foot print.  Syntax is: envKey
         */
        highEnvironmentOfferRecipes?: string,
        /**
         * filter on ingredient menu offer with high nutrient quantities.  Syntax is: nutKey
         */
        highNutrientOfferIngredients?: string,
        /**
         * filter on recipe menu offer with high nutrient quantities.  Syntax is: nutKey
         */
        highNutrientOfferRecipes?: string,
        /**
         * filter on ingredient menu offer with high purchase prices.  Syntax is: price_per_gr
         */
        highPurchasePriceOfferIngredients?: string,
        /**
         * filter on recipe menu offer with high prices.  Syntax is: price
         */
        highPurchasePriceOfferRecipes?: string,
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * object Id (comma separated). Return a list view not a detailed view
         */
        idsList?: string,
        /**
         * is the price per gramm null or not? is_price_per_gr=null or is_price_per_gr=!null
         */
        isPricePerGr?: string,
        /**
         * product productlink raw parent Cooked is not checked
         */
        isProductLinkCooked?: 'yes' | 'no',
        /**
         * is the product veggie (vegan included) ?
         */
        isVeggie?: 'yes' | 'no',
        /**
         * filter on labels (all of the labels must be higher than threshold) (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labels?: string,
        /**
         * filter on labels (any of the labels can be higher than threshold) (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsAny?: string,
        /**
         * filter on labels (any of the labels can be lower than threshold) (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsAnyExcl?: string,
        /**
         * filter on labels (exclude them) (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsExcl?: string,
        /**
         * Exclude produts that have not the label key (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsExclNoKeys?: string,
        /**
         * filter on labels (all of the labels must be present) (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsHasAny?: string,
        /**
         * object level (comma separated)
         */
        level?: number,
        /**
         * Limit the number of results
         */
        limit?: number,
        /**
         * Is the origin of the product : unknown, subregion, region, country, continent ?
         */
        locality?: 'unknown' | 'subregion' | 'region' | 'country' | 'bordering' | 'continent' | 'world',
        /**
         * filter on recipe menu offer with low margin.  Syntax is: price
         */
        lowMarginOfferRecipes?: string,
        /**
         * filter on recipe menu offer with low nutrient quantities.  Syntax is: nutKey
         */
        lowNutrientOfferIngredients?: string,
        /**
         * filter on recipe menu offer with low nutrient quantities.  Syntax is: nutKey
         */
        lowNutrientOfferRecipes?: string,
        /**
         * filter on margin price.  Syntax is: minValue|maxValue
         */
        margin?: string,
        /**
         * GS1 Target Market (comma separated)
         */
        markets?: number,
        /**
         * Retrieve all products used by this menu id
         */
        menuId?: number,
        /**
         * Menu started after a given date
         */
        menusAfter?: string,
        /**
         * Menu started before to a given date
         */
        menusBefore?: string,
        /**
         * Id(s) of menu company (comma separated)
         */
        menusCompanyId?: number,
        /**
         * Menus flat started after a given date
         */
        menusFlatAfter?: string,
        /**
         * Menus flat started before to a given date
         */
        menusFlatBefore?: string,
        /**
         * Id(s) of flat menu company (comma separated)
         */
        menusFlatCompanyId?: number,
        /**
         * Missing LITs in products
         */
        missingLiTs?: 'yes' | 'no',
        /**
         * missing_conversion
         */
        missingConversion?: 'yes' | 'no',
        /**
         * Missing Names in products
         */
        missingNames?: 'yes' | 'no',
        /**
         * Missing Titles in products
         */
        missingTitles?: 'yes' | 'no',
        /**
         * Product Name
         */
        names?: string,
        /**
         * names exists for these langs contains (comma separated)
         */
        namesLang?: string,
        /**
         * naturality
         */
        natural?: 'yes' | 'no' | 'unknown',
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1,nutrientKey2,nutrientKey3,...
         */
        nutrientAllMissing?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1,nutrientKey2,nutrientKey3
         */
        nutrientHasAll?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1,nutrientKey2,nutrientKey3
         */
        nutrientHasAny?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1:low,nutrientKey2:high,,nutrientKey3:med
         */
        nutrientLevels?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1,nutrientKey2,nutrientKey3,...
         */
        nutrientNotAllMissing?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1:minValue|maxValue,nutrientKey2:minValue|maxValue
         */
        nutrients?: string,
        /**
         * subtitles exists for these langs contains (comma separated)
         */
        nutriscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * Is there a difference between youmeal and computed nutriscore ?
         */
        nutriscoreDiff?: 'yes' | 'no',
        /**
         * subtitles exists for these langs contains (comma separated)
         */
        nutriscoreRaw?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * ordered object Id (comma separated)
         */
        oidsList?: string,
        /**
         * Filter product (directly 0 or indirectly 1) in menus between date_from and date_to for a certain company. syntax is : company_id:date_from|date_to|(0 or 1)
         */
        onTheMenu?: string,
        /**
         * Order result by food cost of ingredient sold in menuEntries
         */
        orderByIngrFoodCost?: 'price_per_gr',
        /**
         * Order result by food cost of ingredient sold in menuEntries
         */
        orderByIngrQuantitySold?: 'asc' | 'desc',
        /**
         * Order result by quantity of recipe sold in menuEntries (ascending or descending)
         */
        orderByMenuEntriesQuantity?: 'desc' | 'asc',
        /**
         * Order result by product link parent factor cooked (ascending or descending)
         */
        orderByProductLinkFactorCooked?: 'desc' | 'asc',
        /**
         * Order result by product link parent factor raw (ascending or descending)
         */
        orderByProductLinkFactorRaw?: 'desc' | 'asc',
        /**
         * Order result by food cost of recipes sold in menuEntries
         */
        orderByRecQuantitySold?: 'asc' | 'desc',
        /**
         * Order result by food cost of ingredient sold in menuEntries
         */
        orderByRecipFoodCost?: 'price',
        /**
         * Which field to use when ordering the results.  Multiple valuees might be separated by coma
         */
        ordering?: string,
        /**
         * Is the origin field an empty list or not? origin=[] or origin=![]
         */
        origin?: '[]',
        owned?: boolean,
        /**
         * Is the packaging field an empty list or not?  packaging=[] or packaging=![]
         */
        packaging?: '[]',
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * [PARENTS] product categories contains ANY of these  (comma separated)
         */
        parentsCategory?: number,
        /**
         * [PARENTS] Id(s) of products company (comma separated)
         */
        parentsCompanyId?: number,
        /**
         * [PARENTS] name of product company  (comma separated)
         */
        parentsCompanyName?: string,
        /**
         * [PARENTS] start path of product company, the request can be negated with !
         */
        parentsCompanyPath?: string,
        /**
         * [PARENTS] IngredientId contained in this product (comma separated)
         */
        parentsContainsIngredient?: number,
        /**
         * [PARENTS] subtitles exists for these langs contains (comma separated)
         */
        parentsEcoscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [PARENTS] has product coupled to it ?
         */
        parentsHasChild?: 'yes' | 'no',
        /**
         * [PARENTS] is there an attachement ?
         */
        parentsHasFile?: 'yes' | 'no',
        /**
         * [PARENTS] is it associated to an ingredient ?
         */
        parentsHasIngredient?: 'yes' | 'no',
        /**
         * [PARENTS] properties of the product LIT (comma separated)
         */
        parentsHasLit?: string,
        /**
         * [PARENTS] is it coupled ?
         */
        parentsHasParent?: 'yes' | 'no',
        /**
         * [PARENTS] Is there at least one label used in ecoscore for this product ?
         */
        parentsHasAnyLabelEcoscore?: 'yes' | 'no',
        /**
         * [PARENTS] Is there a unknown recipe in the LITs ?
         */
        parentsHasUnkownRecipe?: 'yes' | 'no',
        /**
         * [PARENTS] object Id (comma separated)
         */
        parentsIds?: string,
        /**
         * [PARENTS] object Id (comma separated). Return a list view not a detailed view
         */
        parentsIdsList?: string,
        /**
         * [PARENTS] is the price per gramm null or not? is_price_per_gr=null or is_price_per_gr=!null
         */
        parentsIsPricePerGr?: string,
        /**
         * [PARENTS] object level (comma separated)
         */
        parentsLevel?: number,
        /**
         * [PARENTS] Retrieve all products used by this menu id
         */
        parentsMenuId?: number,
        /**
         * [PARENTS] Menu started after a given date
         */
        parentsMenusAfter?: string,
        /**
         * [PARENTS] Menu started before to a given date
         */
        parentsMenusBefore?: string,
        /**
         * [PARENTS] Id(s) of menu company (comma separated)
         */
        parentsMenusCompanyId?: number,
        /**
         * [PARENTS] Menus flat started after a given date
         */
        parentsMenusFlatAfter?: string,
        /**
         * [PARENTS] Menus flat started before to a given date
         */
        parentsMenusFlatBefore?: string,
        /**
         * [PARENTS] Id(s) of flat menu company (comma separated)
         */
        parentsMenusFlatCompanyId?: number,
        /**
         * [PARENTS] Missing LITs in products
         */
        parentsMissingLiTs?: 'yes' | 'no',
        /**
         * [PARENTS] Missing Names in products
         */
        parentsMissingNames?: 'yes' | 'no',
        /**
         * [PARENTS] Missing Titles in products
         */
        parentsMissingTitles?: 'yes' | 'no',
        /**
         * [PARENTS] naturality
         */
        parentsNatural?: 'yes' | 'no' | 'unknown',
        /**
         * [PARENTS] subtitles exists for these langs contains (comma separated)
         */
        parentsNutriscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [PARENTS] Is there a difference between youmeal and computed nutriscore ?
         */
        parentsNutriscoreDiff?: 'yes' | 'no',
        /**
         * [PARENTS] subtitles exists for these langs contains (comma separated)
         */
        parentsNutriscoreRaw?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [PARENTS] ordered object Id (comma separated)
         */
        parentsOidsList?: string,
        /**
         * [PARENTS] Filter product (directly 0 or indirectly 1) in menus between date_from and date_to for a certain company. syntax is : company_id:date_from|date_to|(0 or 1)
         */
        parentsOnTheMenu?: string,
        /**
         * [PARENTS] Order result by food cost of ingredient sold in menuEntries
         */
        parentsOrderByIngrFoodCost?: 'price_per_gr',
        /**
         * [PARENTS] Order result by food cost of ingredient sold in menuEntries
         */
        parentsOrderByIngrQuantitySold?: 'asc' | 'desc',
        /**
         * [PARENTS] Order result by quantity of recipe sold in menuEntries (ascending or descending)
         */
        parentsOrderByMenuEntriesQuantity?: 'desc' | 'asc',
        /**
         * [PARENTS] Order result by product link parent factor cooked (ascending or descending)
         */
        parentsOrderByProductLinkFactorCooked?: 'desc' | 'asc',
        /**
         * [PARENTS] Order result by product link parent factor raw (ascending or descending)
         */
        parentsOrderByProductLinkFactorRaw?: 'desc' | 'asc',
        /**
         * [PARENTS] Order result by food cost of recipes sold in menuEntries
         */
        parentsOrderByRecQuantitySold?: 'asc' | 'desc',
        /**
         * [PARENTS] Order result by food cost of ingredient sold in menuEntries
         */
        parentsOrderByRecipFoodCost?: 'price',
        /**
         * [PARENTS] language used for parsing (comma separated)
         */
        parentsParsingLang?: 'fr' | 'nl' | 'en' | 'de' | 'sv' | 'pt' | 'es' | 'it' | 'ru' | 'kk' | 'unknown',
        /**
         * [PARENTS] lang with missing (generated) translations (comma separated)
         */
        parentsParsingTrans?: 'fr' | 'en' | 'nl' | 'de' | 'sv' | 'pt' | 'it' | 'es' | 'ru' | 'kk',
        /**
         * [PARENTS] is the local purchase price empty? purchase_price_local={} or purchase_price_local=!{}
         */
        parentsPurchasePriceLocal?: '{}',
        /**
         * [PARENTS] source
         */
        parentsSource?: string,
        /**
         * [PARENTS] global status
         */
        parentsStatus?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients status
         */
        parentsStatusLiTs?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] allergens legal status
         */
        parentsStatusAllergens?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] allergens status
         */
        parentsStatusAllergensAnimal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] allergens status
         */
        parentsStatusAllergensLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] costs status
         */
        parentsStatusCosts?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] ecoscore status
         */
        parentsStatusEcoscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] environments status
         */
        parentsStatusEnvironments?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] environments status
         */
        parentsStatusEnvironmentsCo2?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] environments status
         */
        parentsStatusEnvironmentsGnd?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] environments status
         */
        parentsStatusEnvironmentsH2Oc?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] environments status
         */
        parentsStatusEnvironmentsPefScore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] Labels status
         */
        parentsStatusLabels?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients status
         */
        parentsStatusNames?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients status
         */
        parentsStatusNutrients?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients legal status
         */
        parentsStatusNutrientsLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients used in nutriscore status
         */
        parentsStatusNutrientsNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients not legal status
         */
        parentsStatusNutrientsOther?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutriscore status
         */
        parentsStatusNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] Origin status
         */
        parentsStatusOrigin?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] parsing status (comma separated)
         */
        parentsStatusParsing?: 'valid' | 'invalid',
        /**
         * [PARENTS] qunc status
         */
        parentsStatusQunc?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] Seasonality status
         */
        parentsStatusSeasonality?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] translation status (comma separated)
         */
        parentsStatusTranslations?: string,
        /**
         * [PARENTS] product update status (comma separated)
         */
        parentsStatusUpdate?: 'undefined' | 'pending' | 'notified' | 'errors' | 'success' | '!undefined' | '!pending' | '!notified' | '!errors' | '!success',
        /**
         * [PARENTS] product tags contains ALL of these  (comma separated)
         */
        parentsTagsAll?: number,
        /**
         * [PARENTS] product tags contains ANY of these  (comma separated)
         */
        parentsTagsAny?: number,
        /**
         * [PARENTS] product tags contains ALL of these  (comma separated)
         */
        parentsTagsCodeAll?: string,
        /**
         * [PARENTS] product tags contains ANY of these  (comma separated)
         */
        parentsTagsCodeAny?: string,
        /**
         * [PARENTS] product tags contains ANY of these  (comma separated)
         */
        parentsTagsCodeExclAny?: string,
        /**
         * [PARENTS] product tags contains ANY of these  (comma separated)
         */
        parentsTagsExclAny?: number,
        /**
         * [PARENTS] Product that are about to tip to the superior score
         */
        parentsTippingEcoscore?: 'yes' | 'no',
        /**
         * [PARENTS] Product that are about to tip to the superior score
         */
        parentsTippingNutriscore?: 'yes' | 'no',
        /**
         * [PARENTS] product type  (comma separated)
         */
        parentsType?: 'P' | 'R' | 'FT' | 'YMP' | '!P' | '!R' | '!FT' | '!YMP',
        /**
         * [PARENTS] Retrieve all products used by this menu ids (comma separated)
         */
        parentsUsedByMenu?: number,
        /**
         * [PARENTS] Retrieve all products used by this product ids (comma separated)
         */
        parentsUsedByProduct?: number,
        /**
         * [PARENTS] Retrieve all product using this product ids (comma separated)
         */
        parentsUsingProduct?: number,
        /**
         * language used for parsing (comma separated)
         */
        parsingLang?: 'fr' | 'nl' | 'en' | 'de' | 'sv' | 'pt' | 'es' | 'it' | 'ru' | 'kk' | 'unknown',
        /**
         * lang with missing (generated) translations (comma separated)
         */
        parsingTrans?: 'fr' | 'en' | 'nl' | 'de' | 'sv' | 'pt' | 'it' | 'es' | 'ru' | 'kk',
        /**
         * filter on prices.  Syntax is: minValue|maxValue
         */
        prices?: string,
        /**
         * Product productlink has a cooked parent factor lower than a value
         */
        productLinkFactorCookedGreaterThan?: number,
        /**
         * Product productlink has a cooked parent factor lower than a value
         */
        productLinkFactorCookedLowerThan?: number,
        /**
         * product productlink has a parent raw Factor lower than a value
         */
        productLinkFactorRawGreaterThan?: number,
        /**
         * product productlink has a parent raw Factor lower than a value
         */
        productLinkFactorRawLowerThan?: number,
        /**
         * filter on purchase_price.  Syntax is: unit:minValue|maxValue
         */
        purchasePrice?: string,
        /**
         * filter on purchase_price based on threshold.  Value is: low, med or high
         */
        purchasePriceLevel?: string,
        /**
         * is the local purchase price empty? purchase_price_local={} or purchase_price_local=!{}
         */
        purchasePriceLocal?: '{}',
        /**
         * Filter on product reference (comma separated)
         */
        reference?: string,
        /**
         * Is the origin country the same as the company location?
         */
        sameCountry?: 'yes' | 'no',
        /**
         * Is the origin region the same as the company location?
         */
        sameRegion?: 'yes' | 'no',
        /**
         * Is the origin subregion the same as the company location?
         */
        sameSubregion?: 'yes' | 'no',
        search?: string,
        /**
         * seasonal
         */
        seasonal?: string,
        /**
         * Is there month in seasonality field ? seasonality={} or seasonality=!{}
         */
        seasonality?: '{}',
        /**
         * Is the seasonality field not applicable for this product ?
         */
        seasonalityNa?: 'yes' | 'no',
        /**
         * Is it a season product for month x,y, ... (comma separeted value) ?
         */
        seasonalityRecipeMonth?: string,
        /**
         * source
         */
        source?: string,
        /**
         * splitCount
         */
        splitCount?: number,
        /**
         * splitIndex
         */
        splitIndex?: number,
        /**
         * global status
         */
        status?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusLiTs?: 'valid' | 'invalid' | 'unknown',
        /**
         * allergens legal status
         */
        statusAllergens?: 'valid' | 'invalid' | 'unknown',
        /**
         * allergens status
         */
        statusAllergensAnimal?: 'valid' | 'invalid' | 'unknown',
        /**
         * allergens status
         */
        statusAllergensLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * costs status
         */
        statusCosts?: 'valid' | 'invalid' | 'unknown',
        /**
         * ecoscore status
         */
        statusEcoscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironments?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironmentsCo2?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironmentsGnd?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironmentsH2Oc?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironmentsPefScore?: 'valid' | 'invalid' | 'unknown',
        /**
         * Labels status
         */
        statusLabels?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusNames?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusNutrients?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients legal status
         */
        statusNutrientsLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients used in nutriscore status
         */
        statusNutrientsNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients not legal status
         */
        statusNutrientsOther?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutriscore status
         */
        statusNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * Origin status
         */
        statusOrigin?: 'valid' | 'invalid' | 'unknown',
        /**
         * parsing status (comma separated)
         */
        statusParsing?: 'valid' | 'invalid',
        /**
         * qunc status
         */
        statusQunc?: 'valid' | 'invalid' | 'unknown',
        /**
         * Seasonality status
         */
        statusSeasonality?: 'valid' | 'invalid' | 'unknown',
        /**
         * translation status (comma separated)
         */
        statusTranslations?: string,
        /**
         * product update status (comma separated)
         */
        statusUpdate?: 'undefined' | 'pending' | 'notified' | 'errors' | 'success' | '!undefined' | '!pending' | '!notified' | '!errors' | '!success',
        /**
         * Product Subtitle
         */
        subtitles?: string,
        /**
         * subtitles exists for these langs contains (comma separated)
         */
        subtitlesLang?: string,
        /**
         * product tags contains ALL of these  (comma separated)
         */
        tagsAll?: number,
        /**
         * product tags contains ANY of these  (comma separated)
         */
        tagsAny?: number,
        /**
         * product tags contains ALL of these  (comma separated)
         */
        tagsCodeAll?: string,
        /**
         * product tags contains ANY of these  (comma separated)
         */
        tagsCodeAny?: string,
        /**
         * product tags contains ANY of these  (comma separated)
         */
        tagsCodeExclAny?: string,
        /**
         * product tags contains ANY of these  (comma separated)
         */
        tagsExclAny?: number,
        /**
         * Product that are about to tip to the superior score
         */
        tippingEcoscore?: 'yes' | 'no',
        /**
         * Product that are about to tip to the superior score
         */
        tippingNutriscore?: 'yes' | 'no',
        /**
         * Product Title
         */
        titles?: string,
        /**
         * titles exists for these langs contains (comma separated)
         */
        titlesLang?: string,
        /**
         * product type  (comma separated)
         */
        type?: 'P' | 'R' | 'FT' | 'YMP' | '!P' | '!R' | '!FT' | '!YMP',
        /**
         * Product that have been updated since a given date
         */
        updatedAfter?: string,
        /**
         * Product that have been updated prior to a given date
         */
        updatedBefore?: string,
        /**
         * Retrieve all products used by this menu ids (comma separated)
         */
        usedByMenu?: number,
        /**
         * Retrieve all products used by this product ids (comma separated)
         */
        usedByProduct?: number,
        /**
         * Retrieve all product using this product ids (comma separated)
         */
        usingProduct?: number,
        /**
         * object UUID
         */
        uuid?: string,
        /**
         * filter on parsing with a specific concept group
         */
        withConceptGroup?: number,
    }): CancelablePromise<PaginatedProductList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/product/',
            query: {
                'EAN': ean,
                'allegations': allegations,
                'allegations_any': allegationsAny,
                'allegations_any_excl': allegationsAnyExcl,
                'allegations_excl': allegationsExcl,
                'allergens': allergens,
                'allergens_changes': allergensChanges,
                'allergens_potential_missing': allergensPotentialMissing,
                'available': available,
                'category': category,
                'children_category': childrenCategory,
                'children_company_id': childrenCompanyId,
                'children_company_name': childrenCompanyName,
                'children_company_path': childrenCompanyPath,
                'children_containsIngredient': childrenContainsIngredient,
                'children_deleted': childrenDeleted,
                'children_ecoscore': childrenEcoscore,
                'children_hasChild': childrenHasChild,
                'children_hasFile': childrenHasFile,
                'children_hasIngredient': childrenHasIngredient,
                'children_hasLIT': childrenHasLit,
                'children_hasParent': childrenHasParent,
                'children_has_any_label_ecoscore': childrenHasAnyLabelEcoscore,
                'children_has_unkownRecipe': childrenHasUnkownRecipe,
                'children_ids': childrenIds,
                'children_ids_list': childrenIdsList,
                'children_is_price_per_gr': childrenIsPricePerGr,
                'children_level': childrenLevel,
                'children_menu_id': childrenMenuId,
                'children_menus_after': childrenMenusAfter,
                'children_menus_before': childrenMenusBefore,
                'children_menus_company_id': childrenMenusCompanyId,
                'children_menus_flat_after': childrenMenusFlatAfter,
                'children_menus_flat_before': childrenMenusFlatBefore,
                'children_menus_flat_company_id': childrenMenusFlatCompanyId,
                'children_missing_LITs': childrenMissingLiTs,
                'children_missing_names': childrenMissingNames,
                'children_missing_titles': childrenMissingTitles,
                'children_natural': childrenNatural,
                'children_nutriscore': childrenNutriscore,
                'children_nutriscore_diff': childrenNutriscoreDiff,
                'children_nutriscore_raw': childrenNutriscoreRaw,
                'children_oids_list': childrenOidsList,
                'children_on_the_menu': childrenOnTheMenu,
                'children_order_by_ingr_food_cost': childrenOrderByIngrFoodCost,
                'children_order_by_ingr_quantity_sold': childrenOrderByIngrQuantitySold,
                'children_order_by_menuEntries_quantity': childrenOrderByMenuEntriesQuantity,
                'children_order_by_productLink_factorCooked': childrenOrderByProductLinkFactorCooked,
                'children_order_by_productLink_factorRaw': childrenOrderByProductLinkFactorRaw,
                'children_order_by_rec_quantity_sold': childrenOrderByRecQuantitySold,
                'children_order_by_recip_food_cost': childrenOrderByRecipFoodCost,
                'children_parsing_lang': childrenParsingLang,
                'children_parsing_trans': childrenParsingTrans,
                'children_purchase_price_local': childrenPurchasePriceLocal,
                'children_source': childrenSource,
                'children_status': childrenStatus,
                'children_status_LITs': childrenStatusLiTs,
                'children_status_allergens': childrenStatusAllergens,
                'children_status_allergens_animal': childrenStatusAllergensAnimal,
                'children_status_allergens_legal': childrenStatusAllergensLegal,
                'children_status_costs': childrenStatusCosts,
                'children_status_ecoscore': childrenStatusEcoscore,
                'children_status_environments': childrenStatusEnvironments,
                'children_status_environments_CO2': childrenStatusEnvironmentsCo2,
                'children_status_environments_GND': childrenStatusEnvironmentsGnd,
                'children_status_environments_H2OC': childrenStatusEnvironmentsH2Oc,
                'children_status_environments_PEF_SCORE': childrenStatusEnvironmentsPefScore,
                'children_status_labels': childrenStatusLabels,
                'children_status_names': childrenStatusNames,
                'children_status_nutrients': childrenStatusNutrients,
                'children_status_nutrients_legal': childrenStatusNutrientsLegal,
                'children_status_nutrients_nutriscore': childrenStatusNutrientsNutriscore,
                'children_status_nutrients_other': childrenStatusNutrientsOther,
                'children_status_nutriscore': childrenStatusNutriscore,
                'children_status_origin': childrenStatusOrigin,
                'children_status_parsing': childrenStatusParsing,
                'children_status_qunc': childrenStatusQunc,
                'children_status_seasonality': childrenStatusSeasonality,
                'children_status_translations': childrenStatusTranslations,
                'children_status_update': childrenStatusUpdate,
                'children_tags_all': childrenTagsAll,
                'children_tags_any': childrenTagsAny,
                'children_tags_code_all': childrenTagsCodeAll,
                'children_tags_code_any': childrenTagsCodeAny,
                'children_tags_code_excl_any': childrenTagsCodeExclAny,
                'children_tags_excl_any': childrenTagsExclAny,
                'children_tipping_ecoscore': childrenTippingEcoscore,
                'children_tipping_nutriscore': childrenTippingNutriscore,
                'children_type': childrenType,
                'children_usedByMenu': childrenUsedByMenu,
                'children_usedByProduct': childrenUsedByProduct,
                'children_usingProduct': childrenUsingProduct,
                'comment': comment,
                'company_id': companyId,
                'company_name': companyName,
                'company_path': companyPath,
                'conservation': conservation,
                'containsIngredient': containsIngredient,
                'coupling_has_EAN': couplingHasEan,
                'coupling_has_REF': couplingHasRef,
                'coupling_is_empty': couplingIsEmpty,
                'deleted': deleted,
                'ecoscore': ecoscore,
                'environment_levels': environmentLevels,
                'environments': environments,
                'generic': generic,
                'gln': gln,
                'hasChild': hasChild,
                'hasFactorCooked': hasFactorCooked,
                'hasFactorRaw': hasFactorRaw,
                'hasFile': hasFile,
                'hasIngredient': hasIngredient,
                'hasLIT': hasLit,
                'hasParent': hasParent,
                'has_any_label_ecoscore': hasAnyLabelEcoscore,
                'has_file_type': hasFileType,
                'has_fryed_ingredients': hasFryedIngredients,
                'has_fryed_productLink_factor': hasFryedProductLinkFactor,
                'has_frying_bath': hasFryingBath,
                'has_high_frying_bath_quantity': hasHighFryingBathQuantity,
                'has_productLink': hasProductLink,
                'has_productLink_factorRaw': hasProductLinkFactorRaw,
                'has_productLink_parent': hasProductLinkParent,
                'has_productLink_ymid': hasProductLinkYmid,
                'has_unkownRecipe': hasUnkownRecipe,
                'has_url': hasUrl,
                'high_environment_offer_ingredients': highEnvironmentOfferIngredients,
                'high_environment_offer_recipes': highEnvironmentOfferRecipes,
                'high_nutrient_offer_ingredients': highNutrientOfferIngredients,
                'high_nutrient_offer_recipes': highNutrientOfferRecipes,
                'high_purchase_price_offer_ingredients': highPurchasePriceOfferIngredients,
                'high_purchase_price_offer_recipes': highPurchasePriceOfferRecipes,
                'id': id,
                'ids': ids,
                'ids_list': idsList,
                'is_price_per_gr': isPricePerGr,
                'is_productLink_cooked': isProductLinkCooked,
                'is_veggie': isVeggie,
                'labels': labels,
                'labels_any': labelsAny,
                'labels_any_excl': labelsAnyExcl,
                'labels_excl': labelsExcl,
                'labels_excl_no_keys': labelsExclNoKeys,
                'labels_has_any': labelsHasAny,
                'level': level,
                'limit': limit,
                'locality': locality,
                'low_margin_offer_recipes': lowMarginOfferRecipes,
                'low_nutrient_offer_ingredients': lowNutrientOfferIngredients,
                'low_nutrient_offer_recipes': lowNutrientOfferRecipes,
                'margin': margin,
                'markets': markets,
                'menu_id': menuId,
                'menus_after': menusAfter,
                'menus_before': menusBefore,
                'menus_company_id': menusCompanyId,
                'menus_flat_after': menusFlatAfter,
                'menus_flat_before': menusFlatBefore,
                'menus_flat_company_id': menusFlatCompanyId,
                'missing_LITs': missingLiTs,
                'missing_conversion': missingConversion,
                'missing_names': missingNames,
                'missing_titles': missingTitles,
                'names': names,
                'names_lang': namesLang,
                'natural': natural,
                'nutrient_all_missing': nutrientAllMissing,
                'nutrient_has_all': nutrientHasAll,
                'nutrient_has_any': nutrientHasAny,
                'nutrient_levels': nutrientLevels,
                'nutrient_not_all_missing': nutrientNotAllMissing,
                'nutrients': nutrients,
                'nutriscore': nutriscore,
                'nutriscore_diff': nutriscoreDiff,
                'nutriscore_raw': nutriscoreRaw,
                'oids_list': oidsList,
                'on_the_menu': onTheMenu,
                'order_by_ingr_food_cost': orderByIngrFoodCost,
                'order_by_ingr_quantity_sold': orderByIngrQuantitySold,
                'order_by_menuEntries_quantity': orderByMenuEntriesQuantity,
                'order_by_productLink_factorCooked': orderByProductLinkFactorCooked,
                'order_by_productLink_factorRaw': orderByProductLinkFactorRaw,
                'order_by_rec_quantity_sold': orderByRecQuantitySold,
                'order_by_recip_food_cost': orderByRecipFoodCost,
                'ordering': ordering,
                'origin': origin,
                'owned': owned,
                'packaging': packaging,
                'page': page,
                'page_size': pageSize,
                'parents_category': parentsCategory,
                'parents_company_id': parentsCompanyId,
                'parents_company_name': parentsCompanyName,
                'parents_company_path': parentsCompanyPath,
                'parents_containsIngredient': parentsContainsIngredient,
                'parents_ecoscore': parentsEcoscore,
                'parents_hasChild': parentsHasChild,
                'parents_hasFile': parentsHasFile,
                'parents_hasIngredient': parentsHasIngredient,
                'parents_hasLIT': parentsHasLit,
                'parents_hasParent': parentsHasParent,
                'parents_has_any_label_ecoscore': parentsHasAnyLabelEcoscore,
                'parents_has_unkownRecipe': parentsHasUnkownRecipe,
                'parents_ids': parentsIds,
                'parents_ids_list': parentsIdsList,
                'parents_is_price_per_gr': parentsIsPricePerGr,
                'parents_level': parentsLevel,
                'parents_menu_id': parentsMenuId,
                'parents_menus_after': parentsMenusAfter,
                'parents_menus_before': parentsMenusBefore,
                'parents_menus_company_id': parentsMenusCompanyId,
                'parents_menus_flat_after': parentsMenusFlatAfter,
                'parents_menus_flat_before': parentsMenusFlatBefore,
                'parents_menus_flat_company_id': parentsMenusFlatCompanyId,
                'parents_missing_LITs': parentsMissingLiTs,
                'parents_missing_names': parentsMissingNames,
                'parents_missing_titles': parentsMissingTitles,
                'parents_natural': parentsNatural,
                'parents_nutriscore': parentsNutriscore,
                'parents_nutriscore_diff': parentsNutriscoreDiff,
                'parents_nutriscore_raw': parentsNutriscoreRaw,
                'parents_oids_list': parentsOidsList,
                'parents_on_the_menu': parentsOnTheMenu,
                'parents_order_by_ingr_food_cost': parentsOrderByIngrFoodCost,
                'parents_order_by_ingr_quantity_sold': parentsOrderByIngrQuantitySold,
                'parents_order_by_menuEntries_quantity': parentsOrderByMenuEntriesQuantity,
                'parents_order_by_productLink_factorCooked': parentsOrderByProductLinkFactorCooked,
                'parents_order_by_productLink_factorRaw': parentsOrderByProductLinkFactorRaw,
                'parents_order_by_rec_quantity_sold': parentsOrderByRecQuantitySold,
                'parents_order_by_recip_food_cost': parentsOrderByRecipFoodCost,
                'parents_parsing_lang': parentsParsingLang,
                'parents_parsing_trans': parentsParsingTrans,
                'parents_purchase_price_local': parentsPurchasePriceLocal,
                'parents_source': parentsSource,
                'parents_status': parentsStatus,
                'parents_status_LITs': parentsStatusLiTs,
                'parents_status_allergens': parentsStatusAllergens,
                'parents_status_allergens_animal': parentsStatusAllergensAnimal,
                'parents_status_allergens_legal': parentsStatusAllergensLegal,
                'parents_status_costs': parentsStatusCosts,
                'parents_status_ecoscore': parentsStatusEcoscore,
                'parents_status_environments': parentsStatusEnvironments,
                'parents_status_environments_CO2': parentsStatusEnvironmentsCo2,
                'parents_status_environments_GND': parentsStatusEnvironmentsGnd,
                'parents_status_environments_H2OC': parentsStatusEnvironmentsH2Oc,
                'parents_status_environments_PEF_SCORE': parentsStatusEnvironmentsPefScore,
                'parents_status_labels': parentsStatusLabels,
                'parents_status_names': parentsStatusNames,
                'parents_status_nutrients': parentsStatusNutrients,
                'parents_status_nutrients_legal': parentsStatusNutrientsLegal,
                'parents_status_nutrients_nutriscore': parentsStatusNutrientsNutriscore,
                'parents_status_nutrients_other': parentsStatusNutrientsOther,
                'parents_status_nutriscore': parentsStatusNutriscore,
                'parents_status_origin': parentsStatusOrigin,
                'parents_status_parsing': parentsStatusParsing,
                'parents_status_qunc': parentsStatusQunc,
                'parents_status_seasonality': parentsStatusSeasonality,
                'parents_status_translations': parentsStatusTranslations,
                'parents_status_update': parentsStatusUpdate,
                'parents_tags_all': parentsTagsAll,
                'parents_tags_any': parentsTagsAny,
                'parents_tags_code_all': parentsTagsCodeAll,
                'parents_tags_code_any': parentsTagsCodeAny,
                'parents_tags_code_excl_any': parentsTagsCodeExclAny,
                'parents_tags_excl_any': parentsTagsExclAny,
                'parents_tipping_ecoscore': parentsTippingEcoscore,
                'parents_tipping_nutriscore': parentsTippingNutriscore,
                'parents_type': parentsType,
                'parents_usedByMenu': parentsUsedByMenu,
                'parents_usedByProduct': parentsUsedByProduct,
                'parents_usingProduct': parentsUsingProduct,
                'parsing_lang': parsingLang,
                'parsing_trans': parsingTrans,
                'prices': prices,
                'productLink_factorCooked_greaterThan': productLinkFactorCookedGreaterThan,
                'productLink_factorCooked_lowerThan': productLinkFactorCookedLowerThan,
                'productLink_factorRaw_greaterThan': productLinkFactorRawGreaterThan,
                'productLink_factorRaw_lowerThan': productLinkFactorRawLowerThan,
                'purchase_price': purchasePrice,
                'purchase_price_level': purchasePriceLevel,
                'purchase_price_local': purchasePriceLocal,
                'reference': reference,
                'same_country': sameCountry,
                'same_region': sameRegion,
                'same_subregion': sameSubregion,
                'search': search,
                'seasonal': seasonal,
                'seasonality': seasonality,
                'seasonality_na': seasonalityNa,
                'seasonality_recipe_month': seasonalityRecipeMonth,
                'source': source,
                'splitCount': splitCount,
                'splitIndex': splitIndex,
                'status': status,
                'status_LITs': statusLiTs,
                'status_allergens': statusAllergens,
                'status_allergens_animal': statusAllergensAnimal,
                'status_allergens_legal': statusAllergensLegal,
                'status_costs': statusCosts,
                'status_ecoscore': statusEcoscore,
                'status_environments': statusEnvironments,
                'status_environments_CO2': statusEnvironmentsCo2,
                'status_environments_GND': statusEnvironmentsGnd,
                'status_environments_H2OC': statusEnvironmentsH2Oc,
                'status_environments_PEF_SCORE': statusEnvironmentsPefScore,
                'status_labels': statusLabels,
                'status_names': statusNames,
                'status_nutrients': statusNutrients,
                'status_nutrients_legal': statusNutrientsLegal,
                'status_nutrients_nutriscore': statusNutrientsNutriscore,
                'status_nutrients_other': statusNutrientsOther,
                'status_nutriscore': statusNutriscore,
                'status_origin': statusOrigin,
                'status_parsing': statusParsing,
                'status_qunc': statusQunc,
                'status_seasonality': statusSeasonality,
                'status_translations': statusTranslations,
                'status_update': statusUpdate,
                'subtitles': subtitles,
                'subtitles_lang': subtitlesLang,
                'tags_all': tagsAll,
                'tags_any': tagsAny,
                'tags_code_all': tagsCodeAll,
                'tags_code_any': tagsCodeAny,
                'tags_code_excl_any': tagsCodeExclAny,
                'tags_excl_any': tagsExclAny,
                'tipping_ecoscore': tippingEcoscore,
                'tipping_nutriscore': tippingNutriscore,
                'titles': titles,
                'titles_lang': titlesLang,
                'type': type,
                'updated_after': updatedAfter,
                'updated_before': updatedBefore,
                'usedByMenu': usedByMenu,
                'usedByProduct': usedByProduct,
                'usingProduct': usingProduct,
                'uuid': uuid,
                'with_conceptGroup': withConceptGroup,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of product (ingredient or recipe) owned by your company hierarchy (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each product (use GET on a specific product to get all the details)
     * The following products are returned:
     * - owned by your company
     * - owned by companies above your company in the hierarchy
     * - owned by companies below your company in the hiearchy
     * Product that are owned by companies that are not a direct ancestor or descendant won't be returned.
     *
     * retrieve:
     * Retrieve all information about a specific product (ingredient or recipe).
     *
     * create:
     * Create a new product (ingredient or recipe).
     *
     * delete:
     * Remove an existing product (ingredient or recipe).
     *
     * partial_update:
     * Update one or more fields on an existing product (ingredient or recipe).
     *
     * update:
     * Update a product (ingredient or recipe).
     * @returns Product
     * @throws ApiError
     */
    public static apiV3ProductCreate({
        requestBody,
    }: {
        requestBody: ProductRequest,
    }): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/product/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * list:
     * Retrieve the list of product (ingredient or recipe) owned by your company hierarchy (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each product (use GET on a specific product to get all the details)
     * The following products are returned:
     * - owned by your company
     * - owned by companies above your company in the hierarchy
     * - owned by companies below your company in the hiearchy
     * Product that are owned by companies that are not a direct ancestor or descendant won't be returned.
     *
     * retrieve:
     * Retrieve all information about a specific product (ingredient or recipe).
     *
     * create:
     * Create a new product (ingredient or recipe).
     *
     * delete:
     * Remove an existing product (ingredient or recipe).
     *
     * partial_update:
     * Update one or more fields on an existing product (ingredient or recipe).
     *
     * update:
     * Update a product (ingredient or recipe).
     * @returns Product
     * @throws ApiError
     */
    public static apiV3ProductRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/product/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of product (ingredient or recipe) owned by your company hierarchy (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each product (use GET on a specific product to get all the details)
     * The following products are returned:
     * - owned by your company
     * - owned by companies above your company in the hierarchy
     * - owned by companies below your company in the hiearchy
     * Product that are owned by companies that are not a direct ancestor or descendant won't be returned.
     *
     * retrieve:
     * Retrieve all information about a specific product (ingredient or recipe).
     *
     * create:
     * Create a new product (ingredient or recipe).
     *
     * delete:
     * Remove an existing product (ingredient or recipe).
     *
     * partial_update:
     * Update one or more fields on an existing product (ingredient or recipe).
     *
     * update:
     * Update a product (ingredient or recipe).
     * @returns Product
     * @throws ApiError
     */
    public static apiV3ProductPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody: ProductRequest,
    }): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/product/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * list:
     * Retrieve the list of product (ingredient or recipe) owned by your company hierarchy (possibly filtered, ordered and paginated by query parameters).
     * Only a subset of information is given for each product (use GET on a specific product to get all the details)
     * The following products are returned:
     * - owned by your company
     * - owned by companies above your company in the hierarchy
     * - owned by companies below your company in the hiearchy
     * Product that are owned by companies that are not a direct ancestor or descendant won't be returned.
     *
     * retrieve:
     * Retrieve all information about a specific product (ingredient or recipe).
     *
     * create:
     * Create a new product (ingredient or recipe).
     *
     * delete:
     * Remove an existing product (ingredient or recipe).
     *
     * partial_update:
     * Update one or more fields on an existing product (ingredient or recipe).
     *
     * update:
     * Update a product (ingredient or recipe).
     * @returns void
     * @throws ApiError
     */
    public static apiV3ProductDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/product/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedProductSimpleList
     * @throws ApiError
     */
    public static apiV3ProductApiList({
        ean,
        allegations,
        allegationsAny,
        allegationsAnyExcl,
        allegationsExcl,
        allergens,
        allergensChanges,
        allergensPotentialMissing,
        available,
        category,
        childrenCategory,
        childrenCompanyId,
        childrenCompanyName,
        childrenCompanyPath,
        childrenContainsIngredient,
        childrenDeleted,
        childrenEcoscore,
        childrenHasChild,
        childrenHasFile,
        childrenHasIngredient,
        childrenHasLit,
        childrenHasParent,
        childrenHasAnyLabelEcoscore,
        childrenHasUnkownRecipe,
        childrenIds,
        childrenIdsList,
        childrenIsPricePerGr,
        childrenLevel,
        childrenMenuId,
        childrenMenusAfter,
        childrenMenusBefore,
        childrenMenusCompanyId,
        childrenMenusFlatAfter,
        childrenMenusFlatBefore,
        childrenMenusFlatCompanyId,
        childrenMissingLiTs,
        childrenMissingNames,
        childrenMissingTitles,
        childrenNatural,
        childrenNutriscore,
        childrenNutriscoreDiff,
        childrenNutriscoreRaw,
        childrenOidsList,
        childrenOnTheMenu,
        childrenOrderByIngrFoodCost,
        childrenOrderByIngrQuantitySold,
        childrenOrderByMenuEntriesQuantity,
        childrenOrderByProductLinkFactorCooked,
        childrenOrderByProductLinkFactorRaw,
        childrenOrderByRecQuantitySold,
        childrenOrderByRecipFoodCost,
        childrenParsingLang,
        childrenParsingTrans,
        childrenPurchasePriceLocal,
        childrenSource,
        childrenStatus,
        childrenStatusLiTs,
        childrenStatusAllergens,
        childrenStatusAllergensAnimal,
        childrenStatusAllergensLegal,
        childrenStatusCosts,
        childrenStatusEcoscore,
        childrenStatusEnvironments,
        childrenStatusEnvironmentsCo2,
        childrenStatusEnvironmentsGnd,
        childrenStatusEnvironmentsH2Oc,
        childrenStatusEnvironmentsPefScore,
        childrenStatusLabels,
        childrenStatusNames,
        childrenStatusNutrients,
        childrenStatusNutrientsLegal,
        childrenStatusNutrientsNutriscore,
        childrenStatusNutrientsOther,
        childrenStatusNutriscore,
        childrenStatusOrigin,
        childrenStatusParsing,
        childrenStatusQunc,
        childrenStatusSeasonality,
        childrenStatusTranslations,
        childrenStatusUpdate,
        childrenTagsAll,
        childrenTagsAny,
        childrenTagsCodeAll,
        childrenTagsCodeAny,
        childrenTagsCodeExclAny,
        childrenTagsExclAny,
        childrenTippingEcoscore,
        childrenTippingNutriscore,
        childrenType,
        childrenUsedByMenu,
        childrenUsedByProduct,
        childrenUsingProduct,
        comment,
        companyId,
        companyName,
        companyPath,
        conservation,
        containsIngredient,
        couplingHasEan,
        couplingHasRef,
        couplingIsEmpty,
        deleted,
        ecoscore,
        environmentLevels,
        environments,
        generic,
        gln,
        hasChild,
        hasFactorCooked,
        hasFactorRaw,
        hasFile,
        hasIngredient,
        hasLit,
        hasParent,
        hasAnyLabelEcoscore,
        hasFileType,
        hasFryedIngredients,
        hasFryedProductLinkFactor,
        hasFryingBath,
        hasHighFryingBathQuantity,
        hasProductLink,
        hasProductLinkFactorRaw,
        hasProductLinkParent,
        hasProductLinkYmid,
        hasUnkownRecipe,
        hasUrl,
        highEnvironmentOfferIngredients,
        highEnvironmentOfferRecipes,
        highNutrientOfferIngredients,
        highNutrientOfferRecipes,
        highPurchasePriceOfferIngredients,
        highPurchasePriceOfferRecipes,
        id,
        ids,
        idsList,
        isPricePerGr,
        isProductLinkCooked,
        isVeggie,
        labels,
        labelsAny,
        labelsAnyExcl,
        labelsExcl,
        labelsExclNoKeys,
        labelsHasAny,
        level,
        limit,
        locality,
        lowMarginOfferRecipes,
        lowNutrientOfferIngredients,
        lowNutrientOfferRecipes,
        margin,
        markets,
        menuCompanyId,
        menuDate,
        menuId,
        menusAfter,
        menusBefore,
        menusCompanyId,
        menusFlatAfter,
        menusFlatBefore,
        menusFlatCompanyId,
        missingLiTs,
        missingConversion,
        missingNames,
        missingTitles,
        names,
        namesLang,
        natural,
        nutrientAllMissing,
        nutrientHasAll,
        nutrientHasAny,
        nutrientLevels,
        nutrientNotAllMissing,
        nutrients,
        nutriscore,
        nutriscoreDiff,
        nutriscoreRaw,
        oidsList,
        onTheMenu,
        orderByIngrFoodCost,
        orderByIngrQuantitySold,
        orderByMenuEntriesQuantity,
        orderByProductLinkFactorCooked,
        orderByProductLinkFactorRaw,
        orderByRecQuantitySold,
        orderByRecipFoodCost,
        ordering,
        origin,
        owned,
        packaging,
        page,
        pageSize,
        parentsCategory,
        parentsCompanyId,
        parentsCompanyName,
        parentsCompanyPath,
        parentsContainsIngredient,
        parentsEcoscore,
        parentsHasChild,
        parentsHasFile,
        parentsHasIngredient,
        parentsHasLit,
        parentsHasParent,
        parentsHasAnyLabelEcoscore,
        parentsHasUnkownRecipe,
        parentsIds,
        parentsIdsList,
        parentsIsPricePerGr,
        parentsLevel,
        parentsMenuId,
        parentsMenusAfter,
        parentsMenusBefore,
        parentsMenusCompanyId,
        parentsMenusFlatAfter,
        parentsMenusFlatBefore,
        parentsMenusFlatCompanyId,
        parentsMissingLiTs,
        parentsMissingNames,
        parentsMissingTitles,
        parentsNatural,
        parentsNutriscore,
        parentsNutriscoreDiff,
        parentsNutriscoreRaw,
        parentsOidsList,
        parentsOnTheMenu,
        parentsOrderByIngrFoodCost,
        parentsOrderByIngrQuantitySold,
        parentsOrderByMenuEntriesQuantity,
        parentsOrderByProductLinkFactorCooked,
        parentsOrderByProductLinkFactorRaw,
        parentsOrderByRecQuantitySold,
        parentsOrderByRecipFoodCost,
        parentsParsingLang,
        parentsParsingTrans,
        parentsPurchasePriceLocal,
        parentsSource,
        parentsStatus,
        parentsStatusLiTs,
        parentsStatusAllergens,
        parentsStatusAllergensAnimal,
        parentsStatusAllergensLegal,
        parentsStatusCosts,
        parentsStatusEcoscore,
        parentsStatusEnvironments,
        parentsStatusEnvironmentsCo2,
        parentsStatusEnvironmentsGnd,
        parentsStatusEnvironmentsH2Oc,
        parentsStatusEnvironmentsPefScore,
        parentsStatusLabels,
        parentsStatusNames,
        parentsStatusNutrients,
        parentsStatusNutrientsLegal,
        parentsStatusNutrientsNutriscore,
        parentsStatusNutrientsOther,
        parentsStatusNutriscore,
        parentsStatusOrigin,
        parentsStatusParsing,
        parentsStatusQunc,
        parentsStatusSeasonality,
        parentsStatusTranslations,
        parentsStatusUpdate,
        parentsTagsAll,
        parentsTagsAny,
        parentsTagsCodeAll,
        parentsTagsCodeAny,
        parentsTagsCodeExclAny,
        parentsTagsExclAny,
        parentsTippingEcoscore,
        parentsTippingNutriscore,
        parentsType,
        parentsUsedByMenu,
        parentsUsedByProduct,
        parentsUsingProduct,
        parsingLang,
        parsingTrans,
        prices,
        productLinkFactorCookedGreaterThan,
        productLinkFactorCookedLowerThan,
        productLinkFactorRawGreaterThan,
        productLinkFactorRawLowerThan,
        purchasePrice,
        purchasePriceLevel,
        purchasePriceLocal,
        reference,
        sameCountry,
        sameRegion,
        sameSubregion,
        search,
        seasonal,
        seasonality,
        seasonalityNa,
        seasonalityRecipeMonth,
        source,
        splitCount,
        splitIndex,
        status,
        statusLiTs,
        statusAllergens,
        statusAllergensAnimal,
        statusAllergensLegal,
        statusCosts,
        statusEcoscore,
        statusEnvironments,
        statusEnvironmentsCo2,
        statusEnvironmentsGnd,
        statusEnvironmentsH2Oc,
        statusEnvironmentsPefScore,
        statusLabels,
        statusNames,
        statusNutrients,
        statusNutrientsLegal,
        statusNutrientsNutriscore,
        statusNutrientsOther,
        statusNutriscore,
        statusOrigin,
        statusParsing,
        statusQunc,
        statusSeasonality,
        statusTranslations,
        statusUpdate,
        subtitles,
        subtitlesLang,
        tagsAll,
        tagsAny,
        tagsCodeAll,
        tagsCodeAny,
        tagsCodeExclAny,
        tagsExclAny,
        tippingEcoscore,
        tippingNutriscore,
        titles,
        titlesLang,
        type,
        updatedAfter,
        updatedBefore,
        usedByMenu,
        usedByProduct,
        usingProduct,
        uuid,
        withConceptGroup,
    }: {
        /**
         * GS1 EAN Number
         */
        ean?: string,
        /**
         * filter on allegations (all of the allegations must be true) (comma separated).  Syntax is: allegationKey1,allegationKey2
         */
        allegations?: string,
        /**
         * filter on allegations (any of the allegations can be true) (comma separated).  Syntax is: allegationKey1,allegationKey2
         */
        allegationsAny?: string,
        /**
         * filter on allegations (any of the allegations can be false) (comma separated).  Syntax is: allegationKey1,allegationKey2
         */
        allegationsAnyExcl?: string,
        /**
         * filter on allegations (exclude them) (comma separated).  Syntax is: allegationKey1,allegationKey2
         */
        allegationsExcl?: string,
        /**
         * filter on allergens (comma separated).  Syntax is: allergenKey1:A|T,allergenKey2:S
         */
        allergens?: string,
        /**
         * allergen changes (comma separated)
         */
        allergensChanges?: string,
        /**
         * filter allergens (legal, animal, forStatus) where potentially A,T or P is missing in computed.  Syntax is: legal:A or forStatus:T or ....
         */
        allergensPotentialMissing?: string,
        /**
         * Is the product available between date_from and date_to. syntax is : date_from|date_to
         */
        available?: string,
        /**
         * product categories contains ANY of these  (comma separated)
         */
        category?: number,
        /**
         * [CHILDREN] product categories contains ANY of these  (comma separated)
         */
        childrenCategory?: number,
        /**
         * [CHILDREN] Id(s) of products company (comma separated)
         */
        childrenCompanyId?: number,
        /**
         * [CHILDREN] name of product company  (comma separated)
         */
        childrenCompanyName?: string,
        /**
         * [CHILDREN] start path of product company, the request can be negated with !
         */
        childrenCompanyPath?: string,
        /**
         * [CHILDREN] IngredientId contained in this product (comma separated)
         */
        childrenContainsIngredient?: number,
        /**
         * show deleted objects
         */
        childrenDeleted?: 'yes' | 'no',
        /**
         * [CHILDREN] subtitles exists for these langs contains (comma separated)
         */
        childrenEcoscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [CHILDREN] has product coupled to it ?
         */
        childrenHasChild?: 'yes' | 'no',
        /**
         * [CHILDREN] is there an attachement ?
         */
        childrenHasFile?: 'yes' | 'no',
        /**
         * [CHILDREN] is it associated to an ingredient ?
         */
        childrenHasIngredient?: 'yes' | 'no',
        /**
         * [CHILDREN] properties of the product LIT (comma separated)
         */
        childrenHasLit?: string,
        /**
         * [CHILDREN] is it coupled ?
         */
        childrenHasParent?: 'yes' | 'no',
        /**
         * [CHILDREN] Is there at least one label used in ecoscore for this product ?
         */
        childrenHasAnyLabelEcoscore?: 'yes' | 'no',
        /**
         * [CHILDREN] Is there a unknown recipe in the LITs ?
         */
        childrenHasUnkownRecipe?: 'yes' | 'no',
        /**
         * [CHILDREN] object Id (comma separated)
         */
        childrenIds?: string,
        /**
         * [CHILDREN] object Id (comma separated). Return a list view not a detailed view
         */
        childrenIdsList?: string,
        /**
         * [CHILDREN] is the price per gramm null or not? is_price_per_gr=null or is_price_per_gr=!null
         */
        childrenIsPricePerGr?: string,
        /**
         * [CHILDREN] object level (comma separated)
         */
        childrenLevel?: number,
        /**
         * [CHILDREN] Retrieve all products used by this menu id
         */
        childrenMenuId?: number,
        /**
         * [CHILDREN] Menu started after a given date
         */
        childrenMenusAfter?: string,
        /**
         * [CHILDREN] Menu started before to a given date
         */
        childrenMenusBefore?: string,
        /**
         * [CHILDREN] Id(s) of menu company (comma separated)
         */
        childrenMenusCompanyId?: number,
        /**
         * [CHILDREN] Menus flat started after a given date
         */
        childrenMenusFlatAfter?: string,
        /**
         * [CHILDREN] Menus flat started before to a given date
         */
        childrenMenusFlatBefore?: string,
        /**
         * [CHILDREN] Id(s) of flat menu company (comma separated)
         */
        childrenMenusFlatCompanyId?: number,
        /**
         * [CHILDREN] Missing LITs in products
         */
        childrenMissingLiTs?: 'yes' | 'no',
        /**
         * [CHILDREN] Missing Names in products
         */
        childrenMissingNames?: 'yes' | 'no',
        /**
         * [CHILDREN] Missing Titles in products
         */
        childrenMissingTitles?: 'yes' | 'no',
        /**
         * [CHILDREN] naturality
         */
        childrenNatural?: 'yes' | 'no' | 'unknown',
        /**
         * [CHILDREN] subtitles exists for these langs contains (comma separated)
         */
        childrenNutriscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [CHILDREN] Is there a difference between youmeal and computed nutriscore ?
         */
        childrenNutriscoreDiff?: 'yes' | 'no',
        /**
         * [CHILDREN] subtitles exists for these langs contains (comma separated)
         */
        childrenNutriscoreRaw?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [CHILDREN] ordered object Id (comma separated)
         */
        childrenOidsList?: string,
        /**
         * [CHILDREN] Filter product (directly 0 or indirectly 1) in menus between date_from and date_to for a certain company. syntax is : company_id:date_from|date_to|(0 or 1)
         */
        childrenOnTheMenu?: string,
        /**
         * [CHILDREN] Order result by food cost of ingredient sold in menuEntries
         */
        childrenOrderByIngrFoodCost?: 'price_per_gr',
        /**
         * [CHILDREN] Order result by food cost of ingredient sold in menuEntries
         */
        childrenOrderByIngrQuantitySold?: 'asc' | 'desc',
        /**
         * [CHILDREN] Order result by quantity of recipe sold in menuEntries (ascending or descending)
         */
        childrenOrderByMenuEntriesQuantity?: 'desc' | 'asc',
        /**
         * [CHILDREN] Order result by product link parent factor cooked (ascending or descending)
         */
        childrenOrderByProductLinkFactorCooked?: 'desc' | 'asc',
        /**
         * [CHILDREN] Order result by product link parent factor raw (ascending or descending)
         */
        childrenOrderByProductLinkFactorRaw?: 'desc' | 'asc',
        /**
         * [CHILDREN] Order result by food cost of recipes sold in menuEntries
         */
        childrenOrderByRecQuantitySold?: 'asc' | 'desc',
        /**
         * [CHILDREN] Order result by food cost of ingredient sold in menuEntries
         */
        childrenOrderByRecipFoodCost?: 'price',
        /**
         * [CHILDREN] language used for parsing (comma separated)
         */
        childrenParsingLang?: 'fr' | 'nl' | 'en' | 'de' | 'sv' | 'pt' | 'es' | 'it' | 'ru' | 'kk' | 'unknown',
        /**
         * [CHILDREN] lang with missing (generated) translations (comma separated)
         */
        childrenParsingTrans?: 'fr' | 'en' | 'nl' | 'de' | 'sv' | 'pt' | 'it' | 'es' | 'ru' | 'kk',
        /**
         * [CHILDREN] is the local purchase price empty? purchase_price_local={} or purchase_price_local=!{}
         */
        childrenPurchasePriceLocal?: '{}',
        /**
         * [CHILDREN] source
         */
        childrenSource?: string,
        /**
         * [CHILDREN] global status
         */
        childrenStatus?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients status
         */
        childrenStatusLiTs?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] allergens legal status
         */
        childrenStatusAllergens?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] allergens status
         */
        childrenStatusAllergensAnimal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] allergens status
         */
        childrenStatusAllergensLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] costs status
         */
        childrenStatusCosts?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] ecoscore status
         */
        childrenStatusEcoscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] environments status
         */
        childrenStatusEnvironments?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] environments status
         */
        childrenStatusEnvironmentsCo2?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] environments status
         */
        childrenStatusEnvironmentsGnd?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] environments status
         */
        childrenStatusEnvironmentsH2Oc?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] environments status
         */
        childrenStatusEnvironmentsPefScore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] Labels status
         */
        childrenStatusLabels?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients status
         */
        childrenStatusNames?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients status
         */
        childrenStatusNutrients?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients legal status
         */
        childrenStatusNutrientsLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients used in nutriscore status
         */
        childrenStatusNutrientsNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutrients not legal status
         */
        childrenStatusNutrientsOther?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] nutriscore status
         */
        childrenStatusNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] Origin status
         */
        childrenStatusOrigin?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] parsing status (comma separated)
         */
        childrenStatusParsing?: 'valid' | 'invalid',
        /**
         * [CHILDREN] qunc status
         */
        childrenStatusQunc?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] Seasonality status
         */
        childrenStatusSeasonality?: 'valid' | 'invalid' | 'unknown',
        /**
         * [CHILDREN] translation status (comma separated)
         */
        childrenStatusTranslations?: string,
        /**
         * [CHILDREN] product update status (comma separated)
         */
        childrenStatusUpdate?: 'undefined' | 'pending' | 'notified' | 'errors' | 'success' | '!undefined' | '!pending' | '!notified' | '!errors' | '!success',
        /**
         * [CHILDREN] product tags contains ALL of these  (comma separated)
         */
        childrenTagsAll?: number,
        /**
         * [CHILDREN] product tags contains ANY of these  (comma separated)
         */
        childrenTagsAny?: number,
        /**
         * [CHILDREN] product tags contains ALL of these  (comma separated)
         */
        childrenTagsCodeAll?: string,
        /**
         * [CHILDREN] product tags contains ANY of these  (comma separated)
         */
        childrenTagsCodeAny?: string,
        /**
         * [CHILDREN] product tags contains ANY of these  (comma separated)
         */
        childrenTagsCodeExclAny?: string,
        /**
         * [CHILDREN] product tags contains ANY of these  (comma separated)
         */
        childrenTagsExclAny?: number,
        /**
         * [CHILDREN] Product that are about to tip to the superior score
         */
        childrenTippingEcoscore?: 'yes' | 'no',
        /**
         * [CHILDREN] Product that are about to tip to the superior score
         */
        childrenTippingNutriscore?: 'yes' | 'no',
        /**
         * [CHILDREN] product type  (comma separated)
         */
        childrenType?: 'P' | 'R' | 'FT' | 'YMP' | '!P' | '!R' | '!FT' | '!YMP',
        /**
         * [CHILDREN] Retrieve all products used by this menu ids (comma separated)
         */
        childrenUsedByMenu?: number,
        /**
         * [CHILDREN] Retrieve all products used by this product ids (comma separated)
         */
        childrenUsedByProduct?: number,
        /**
         * [CHILDREN] Retrieve all product using this product ids (comma separated)
         */
        childrenUsingProduct?: number,
        /**
         * comment contains (comma separated)
         */
        comment?: string,
        /**
         * Id(s) of products company (comma separated)
         */
        companyId?: number,
        /**
         * name of product company  (comma separated)
         */
        companyName?: string,
        /**
         * start path of product company, the request can be negated with !
         */
        companyPath?: string,
        /**
         * Is the conservation field equal to ... ?
         */
        conservation?: any,
        /**
         * IngredientId contained in this product (comma separated)
         */
        containsIngredient?: number,
        /**
         * is there an EAN specified in productLinks
         */
        couplingHasEan?: 'yes' | 'no',
        /**
         * is there a reference specified in productLinks
         */
        couplingHasRef?: 'yes' | 'no',
        /**
         * is there a reference specified in productLinks
         */
        couplingIsEmpty?: 'yes' | 'no',
        /**
         * show deleted objects
         */
        deleted?: 'yes' | 'no',
        /**
         * subtitles exists for these langs contains (comma separated)
         */
        ecoscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * filter on environments (comma separated).  Syntax is: envKey1:low,envKey2:high,,envKey3:med
         */
        environmentLevels?: string,
        /**
         * filter on environments (comma separated).  Syntax is: envKey1:minValue|maxValue,envKey2:minValue|maxValue
         */
        environments?: string,
        /**
         * generic vs comercial
         */
        generic?: 'true' | 'false' | 'unknown',
        /**
         * GS1 GLN Number
         */
        gln?: string,
        /**
         * has product coupled to it ?
         */
        hasChild?: 'yes' | 'no',
        /**
         * is there a cooked factors ?
         */
        hasFactorCooked?: 'yes' | 'no',
        /**
         * is there a raw factors ?
         */
        hasFactorRaw?: 'yes' | 'no',
        /**
         * is there an attachement ?
         */
        hasFile?: 'yes' | 'no',
        /**
         * is it associated to an ingredient ?
         */
        hasIngredient?: 'yes' | 'no',
        /**
         * properties of the product LIT (comma separated)
         */
        hasLit?: string,
        /**
         * is it coupled ?
         */
        hasParent?: 'yes' | 'no',
        /**
         * Is there at least one label used in ecoscore for this product ?
         */
        hasAnyLabelEcoscore?: 'yes' | 'no',
        /**
         * is there a file from a specific type attached to the product ?
         */
        hasFileType?: string,
        /**
         * Does the recipes has a fryed ingredients ?
         */
        hasFryedIngredients?: 'yes' | 'no',
        /**
         * Does the parent has the factor (fryed, cooked) when the productlink is fryed ?
         */
        hasFryedProductLinkFactor?: string,
        /**
         * Does the recipe has a frying bath ?
         */
        hasFryingBath?: 'yes' | 'no',
        /**
         * Does the product has a high frying bath quantity?
         */
        hasHighFryingBathQuantity?: 'yes' | 'no',
        /**
         * is there a reference on all productLinks ?
         */
        hasProductLink?: 'yes' | 'no',
        /**
         * product productlink raw parent Factor is not checked
         */
        hasProductLinkFactorRaw?: 'yes' | 'no',
        /**
         * is there a reference on all productLinks ?
         */
        hasProductLinkParent?: 'yes' | 'no',
        /**
         * is there a ymid on productLinks ?
         */
        hasProductLinkYmid?: 'yes' | 'no',
        /**
         * Is there a unknown recipe in the LITs ?
         */
        hasUnkownRecipe?: 'yes' | 'no',
        /**
         * is there an url on product ?
         */
        hasUrl?: 'yes' | 'no',
        /**
         * filter on ingredient menu offer with high environment foot print.  Syntax is: envKey
         */
        highEnvironmentOfferIngredients?: string,
        /**
         * filter on recipe menu offer with high environment foot print.  Syntax is: envKey
         */
        highEnvironmentOfferRecipes?: string,
        /**
         * filter on ingredient menu offer with high nutrient quantities.  Syntax is: nutKey
         */
        highNutrientOfferIngredients?: string,
        /**
         * filter on recipe menu offer with high nutrient quantities.  Syntax is: nutKey
         */
        highNutrientOfferRecipes?: string,
        /**
         * filter on ingredient menu offer with high purchase prices.  Syntax is: price_per_gr
         */
        highPurchasePriceOfferIngredients?: string,
        /**
         * filter on recipe menu offer with high prices.  Syntax is: price
         */
        highPurchasePriceOfferRecipes?: string,
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * object Id (comma separated). Return a list view not a detailed view
         */
        idsList?: string,
        /**
         * is the price per gramm null or not? is_price_per_gr=null or is_price_per_gr=!null
         */
        isPricePerGr?: string,
        /**
         * product productlink raw parent Cooked is not checked
         */
        isProductLinkCooked?: 'yes' | 'no',
        /**
         * is the product veggie (vegan included) ?
         */
        isVeggie?: 'yes' | 'no',
        /**
         * filter on labels (all of the labels must be higher than threshold) (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labels?: string,
        /**
         * filter on labels (any of the labels can be higher than threshold) (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsAny?: string,
        /**
         * filter on labels (any of the labels can be lower than threshold) (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsAnyExcl?: string,
        /**
         * filter on labels (exclude them) (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsExcl?: string,
        /**
         * Exclude produts that have not the label key (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsExclNoKeys?: string,
        /**
         * filter on labels (all of the labels must be present) (comma separated).  Syntax is: labelKey1,labelKey2
         */
        labelsHasAny?: string,
        /**
         * object level (comma separated)
         */
        level?: number,
        /**
         * Limit the number of results
         */
        limit?: number,
        /**
         * Is the origin of the product : unknown, subregion, region, country, continent ?
         */
        locality?: 'unknown' | 'subregion' | 'region' | 'country' | 'bordering' | 'continent' | 'world',
        /**
         * filter on recipe menu offer with low margin.  Syntax is: price
         */
        lowMarginOfferRecipes?: string,
        /**
         * filter on recipe menu offer with low nutrient quantities.  Syntax is: nutKey
         */
        lowNutrientOfferIngredients?: string,
        /**
         * filter on recipe menu offer with low nutrient quantities.  Syntax is: nutKey
         */
        lowNutrientOfferRecipes?: string,
        /**
         * filter on margin price.  Syntax is: minValue|maxValue
         */
        margin?: string,
        /**
         * GS1 Target Market (comma separated)
         */
        markets?: number,
        /**
         * ID of the company the menu is belonging to
         */
        menuCompanyId?: string,
        /**
         * Start validity date of the menu
         */
        menuDate?: string,
        /**
         * Retrieve all products used by this menu id
         */
        menuId?: number,
        /**
         * Menu started after a given date
         */
        menusAfter?: string,
        /**
         * Menu started before to a given date
         */
        menusBefore?: string,
        /**
         * Id(s) of menu company (comma separated)
         */
        menusCompanyId?: number,
        /**
         * Menus flat started after a given date
         */
        menusFlatAfter?: string,
        /**
         * Menus flat started before to a given date
         */
        menusFlatBefore?: string,
        /**
         * Id(s) of flat menu company (comma separated)
         */
        menusFlatCompanyId?: number,
        /**
         * Missing LITs in products
         */
        missingLiTs?: 'yes' | 'no',
        /**
         * missing_conversion
         */
        missingConversion?: 'yes' | 'no',
        /**
         * Missing Names in products
         */
        missingNames?: 'yes' | 'no',
        /**
         * Missing Titles in products
         */
        missingTitles?: 'yes' | 'no',
        /**
         * Product Name
         */
        names?: string,
        /**
         * names exists for these langs contains (comma separated)
         */
        namesLang?: string,
        /**
         * naturality
         */
        natural?: 'yes' | 'no' | 'unknown',
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1,nutrientKey2,nutrientKey3,...
         */
        nutrientAllMissing?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1,nutrientKey2,nutrientKey3
         */
        nutrientHasAll?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1,nutrientKey2,nutrientKey3
         */
        nutrientHasAny?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1:low,nutrientKey2:high,,nutrientKey3:med
         */
        nutrientLevels?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1,nutrientKey2,nutrientKey3,...
         */
        nutrientNotAllMissing?: string,
        /**
         * filter on nutrients (comma separated).  Syntax is: nutrientKey1:minValue|maxValue,nutrientKey2:minValue|maxValue
         */
        nutrients?: string,
        /**
         * subtitles exists for these langs contains (comma separated)
         */
        nutriscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * Is there a difference between youmeal and computed nutriscore ?
         */
        nutriscoreDiff?: 'yes' | 'no',
        /**
         * subtitles exists for these langs contains (comma separated)
         */
        nutriscoreRaw?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * ordered object Id (comma separated)
         */
        oidsList?: string,
        /**
         * Filter product (directly 0 or indirectly 1) in menus between date_from and date_to for a certain company. syntax is : company_id:date_from|date_to|(0 or 1)
         */
        onTheMenu?: string,
        /**
         * Order result by food cost of ingredient sold in menuEntries
         */
        orderByIngrFoodCost?: 'price_per_gr',
        /**
         * Order result by food cost of ingredient sold in menuEntries
         */
        orderByIngrQuantitySold?: 'asc' | 'desc',
        /**
         * Order result by quantity of recipe sold in menuEntries (ascending or descending)
         */
        orderByMenuEntriesQuantity?: 'desc' | 'asc',
        /**
         * Order result by product link parent factor cooked (ascending or descending)
         */
        orderByProductLinkFactorCooked?: 'desc' | 'asc',
        /**
         * Order result by product link parent factor raw (ascending or descending)
         */
        orderByProductLinkFactorRaw?: 'desc' | 'asc',
        /**
         * Order result by food cost of recipes sold in menuEntries
         */
        orderByRecQuantitySold?: 'asc' | 'desc',
        /**
         * Order result by food cost of ingredient sold in menuEntries
         */
        orderByRecipFoodCost?: 'price',
        /**
         * Which field to use when ordering the results.  Multiple valuees might be separated by coma
         */
        ordering?: string,
        /**
         * Is the origin field an empty list or not? origin=[] or origin=![]
         */
        origin?: '[]',
        owned?: boolean,
        /**
         * Is the packaging field an empty list or not?  packaging=[] or packaging=![]
         */
        packaging?: '[]',
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * [PARENTS] product categories contains ANY of these  (comma separated)
         */
        parentsCategory?: number,
        /**
         * [PARENTS] Id(s) of products company (comma separated)
         */
        parentsCompanyId?: number,
        /**
         * [PARENTS] name of product company  (comma separated)
         */
        parentsCompanyName?: string,
        /**
         * [PARENTS] start path of product company, the request can be negated with !
         */
        parentsCompanyPath?: string,
        /**
         * [PARENTS] IngredientId contained in this product (comma separated)
         */
        parentsContainsIngredient?: number,
        /**
         * [PARENTS] subtitles exists for these langs contains (comma separated)
         */
        parentsEcoscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [PARENTS] has product coupled to it ?
         */
        parentsHasChild?: 'yes' | 'no',
        /**
         * [PARENTS] is there an attachement ?
         */
        parentsHasFile?: 'yes' | 'no',
        /**
         * [PARENTS] is it associated to an ingredient ?
         */
        parentsHasIngredient?: 'yes' | 'no',
        /**
         * [PARENTS] properties of the product LIT (comma separated)
         */
        parentsHasLit?: string,
        /**
         * [PARENTS] is it coupled ?
         */
        parentsHasParent?: 'yes' | 'no',
        /**
         * [PARENTS] Is there at least one label used in ecoscore for this product ?
         */
        parentsHasAnyLabelEcoscore?: 'yes' | 'no',
        /**
         * [PARENTS] Is there a unknown recipe in the LITs ?
         */
        parentsHasUnkownRecipe?: 'yes' | 'no',
        /**
         * [PARENTS] object Id (comma separated)
         */
        parentsIds?: string,
        /**
         * [PARENTS] object Id (comma separated). Return a list view not a detailed view
         */
        parentsIdsList?: string,
        /**
         * [PARENTS] is the price per gramm null or not? is_price_per_gr=null or is_price_per_gr=!null
         */
        parentsIsPricePerGr?: string,
        /**
         * [PARENTS] object level (comma separated)
         */
        parentsLevel?: number,
        /**
         * [PARENTS] Retrieve all products used by this menu id
         */
        parentsMenuId?: number,
        /**
         * [PARENTS] Menu started after a given date
         */
        parentsMenusAfter?: string,
        /**
         * [PARENTS] Menu started before to a given date
         */
        parentsMenusBefore?: string,
        /**
         * [PARENTS] Id(s) of menu company (comma separated)
         */
        parentsMenusCompanyId?: number,
        /**
         * [PARENTS] Menus flat started after a given date
         */
        parentsMenusFlatAfter?: string,
        /**
         * [PARENTS] Menus flat started before to a given date
         */
        parentsMenusFlatBefore?: string,
        /**
         * [PARENTS] Id(s) of flat menu company (comma separated)
         */
        parentsMenusFlatCompanyId?: number,
        /**
         * [PARENTS] Missing LITs in products
         */
        parentsMissingLiTs?: 'yes' | 'no',
        /**
         * [PARENTS] Missing Names in products
         */
        parentsMissingNames?: 'yes' | 'no',
        /**
         * [PARENTS] Missing Titles in products
         */
        parentsMissingTitles?: 'yes' | 'no',
        /**
         * [PARENTS] naturality
         */
        parentsNatural?: 'yes' | 'no' | 'unknown',
        /**
         * [PARENTS] subtitles exists for these langs contains (comma separated)
         */
        parentsNutriscore?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [PARENTS] Is there a difference between youmeal and computed nutriscore ?
         */
        parentsNutriscoreDiff?: 'yes' | 'no',
        /**
         * [PARENTS] subtitles exists for these langs contains (comma separated)
         */
        parentsNutriscoreRaw?: 'A' | 'B' | 'C' | 'D' | 'E' | '!A' | '!B' | '!C' | '!D' | '!E' | 'unknown',
        /**
         * [PARENTS] ordered object Id (comma separated)
         */
        parentsOidsList?: string,
        /**
         * [PARENTS] Filter product (directly 0 or indirectly 1) in menus between date_from and date_to for a certain company. syntax is : company_id:date_from|date_to|(0 or 1)
         */
        parentsOnTheMenu?: string,
        /**
         * [PARENTS] Order result by food cost of ingredient sold in menuEntries
         */
        parentsOrderByIngrFoodCost?: 'price_per_gr',
        /**
         * [PARENTS] Order result by food cost of ingredient sold in menuEntries
         */
        parentsOrderByIngrQuantitySold?: 'asc' | 'desc',
        /**
         * [PARENTS] Order result by quantity of recipe sold in menuEntries (ascending or descending)
         */
        parentsOrderByMenuEntriesQuantity?: 'desc' | 'asc',
        /**
         * [PARENTS] Order result by product link parent factor cooked (ascending or descending)
         */
        parentsOrderByProductLinkFactorCooked?: 'desc' | 'asc',
        /**
         * [PARENTS] Order result by product link parent factor raw (ascending or descending)
         */
        parentsOrderByProductLinkFactorRaw?: 'desc' | 'asc',
        /**
         * [PARENTS] Order result by food cost of recipes sold in menuEntries
         */
        parentsOrderByRecQuantitySold?: 'asc' | 'desc',
        /**
         * [PARENTS] Order result by food cost of ingredient sold in menuEntries
         */
        parentsOrderByRecipFoodCost?: 'price',
        /**
         * [PARENTS] language used for parsing (comma separated)
         */
        parentsParsingLang?: 'fr' | 'nl' | 'en' | 'de' | 'sv' | 'pt' | 'es' | 'it' | 'ru' | 'kk' | 'unknown',
        /**
         * [PARENTS] lang with missing (generated) translations (comma separated)
         */
        parentsParsingTrans?: 'fr' | 'en' | 'nl' | 'de' | 'sv' | 'pt' | 'it' | 'es' | 'ru' | 'kk',
        /**
         * [PARENTS] is the local purchase price empty? purchase_price_local={} or purchase_price_local=!{}
         */
        parentsPurchasePriceLocal?: '{}',
        /**
         * [PARENTS] source
         */
        parentsSource?: string,
        /**
         * [PARENTS] global status
         */
        parentsStatus?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients status
         */
        parentsStatusLiTs?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] allergens legal status
         */
        parentsStatusAllergens?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] allergens status
         */
        parentsStatusAllergensAnimal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] allergens status
         */
        parentsStatusAllergensLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] costs status
         */
        parentsStatusCosts?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] ecoscore status
         */
        parentsStatusEcoscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] environments status
         */
        parentsStatusEnvironments?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] environments status
         */
        parentsStatusEnvironmentsCo2?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] environments status
         */
        parentsStatusEnvironmentsGnd?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] environments status
         */
        parentsStatusEnvironmentsH2Oc?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] environments status
         */
        parentsStatusEnvironmentsPefScore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] Labels status
         */
        parentsStatusLabels?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients status
         */
        parentsStatusNames?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients status
         */
        parentsStatusNutrients?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients legal status
         */
        parentsStatusNutrientsLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients used in nutriscore status
         */
        parentsStatusNutrientsNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutrients not legal status
         */
        parentsStatusNutrientsOther?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] nutriscore status
         */
        parentsStatusNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] Origin status
         */
        parentsStatusOrigin?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] parsing status (comma separated)
         */
        parentsStatusParsing?: 'valid' | 'invalid',
        /**
         * [PARENTS] qunc status
         */
        parentsStatusQunc?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] Seasonality status
         */
        parentsStatusSeasonality?: 'valid' | 'invalid' | 'unknown',
        /**
         * [PARENTS] translation status (comma separated)
         */
        parentsStatusTranslations?: string,
        /**
         * [PARENTS] product update status (comma separated)
         */
        parentsStatusUpdate?: 'undefined' | 'pending' | 'notified' | 'errors' | 'success' | '!undefined' | '!pending' | '!notified' | '!errors' | '!success',
        /**
         * [PARENTS] product tags contains ALL of these  (comma separated)
         */
        parentsTagsAll?: number,
        /**
         * [PARENTS] product tags contains ANY of these  (comma separated)
         */
        parentsTagsAny?: number,
        /**
         * [PARENTS] product tags contains ALL of these  (comma separated)
         */
        parentsTagsCodeAll?: string,
        /**
         * [PARENTS] product tags contains ANY of these  (comma separated)
         */
        parentsTagsCodeAny?: string,
        /**
         * [PARENTS] product tags contains ANY of these  (comma separated)
         */
        parentsTagsCodeExclAny?: string,
        /**
         * [PARENTS] product tags contains ANY of these  (comma separated)
         */
        parentsTagsExclAny?: number,
        /**
         * [PARENTS] Product that are about to tip to the superior score
         */
        parentsTippingEcoscore?: 'yes' | 'no',
        /**
         * [PARENTS] Product that are about to tip to the superior score
         */
        parentsTippingNutriscore?: 'yes' | 'no',
        /**
         * [PARENTS] product type  (comma separated)
         */
        parentsType?: 'P' | 'R' | 'FT' | 'YMP' | '!P' | '!R' | '!FT' | '!YMP',
        /**
         * [PARENTS] Retrieve all products used by this menu ids (comma separated)
         */
        parentsUsedByMenu?: number,
        /**
         * [PARENTS] Retrieve all products used by this product ids (comma separated)
         */
        parentsUsedByProduct?: number,
        /**
         * [PARENTS] Retrieve all product using this product ids (comma separated)
         */
        parentsUsingProduct?: number,
        /**
         * language used for parsing (comma separated)
         */
        parsingLang?: 'fr' | 'nl' | 'en' | 'de' | 'sv' | 'pt' | 'es' | 'it' | 'ru' | 'kk' | 'unknown',
        /**
         * lang with missing (generated) translations (comma separated)
         */
        parsingTrans?: 'fr' | 'en' | 'nl' | 'de' | 'sv' | 'pt' | 'it' | 'es' | 'ru' | 'kk',
        /**
         * filter on prices.  Syntax is: minValue|maxValue
         */
        prices?: string,
        /**
         * Product productlink has a cooked parent factor lower than a value
         */
        productLinkFactorCookedGreaterThan?: number,
        /**
         * Product productlink has a cooked parent factor lower than a value
         */
        productLinkFactorCookedLowerThan?: number,
        /**
         * product productlink has a parent raw Factor lower than a value
         */
        productLinkFactorRawGreaterThan?: number,
        /**
         * product productlink has a parent raw Factor lower than a value
         */
        productLinkFactorRawLowerThan?: number,
        /**
         * filter on purchase_price.  Syntax is: unit:minValue|maxValue
         */
        purchasePrice?: string,
        /**
         * filter on purchase_price based on threshold.  Value is: low, med or high
         */
        purchasePriceLevel?: string,
        /**
         * is the local purchase price empty? purchase_price_local={} or purchase_price_local=!{}
         */
        purchasePriceLocal?: '{}',
        /**
         * Filter on product reference (comma separated)
         */
        reference?: string,
        /**
         * Is the origin country the same as the company location?
         */
        sameCountry?: 'yes' | 'no',
        /**
         * Is the origin region the same as the company location?
         */
        sameRegion?: 'yes' | 'no',
        /**
         * Is the origin subregion the same as the company location?
         */
        sameSubregion?: 'yes' | 'no',
        search?: string,
        /**
         * seasonal
         */
        seasonal?: string,
        /**
         * Is there month in seasonality field ? seasonality={} or seasonality=!{}
         */
        seasonality?: '{}',
        /**
         * Is the seasonality field not applicable for this product ?
         */
        seasonalityNa?: 'yes' | 'no',
        /**
         * Is it a season product for month x,y, ... (comma separeted value) ?
         */
        seasonalityRecipeMonth?: string,
        /**
         * source
         */
        source?: string,
        /**
         * splitCount
         */
        splitCount?: number,
        /**
         * splitIndex
         */
        splitIndex?: number,
        /**
         * global status
         */
        status?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusLiTs?: 'valid' | 'invalid' | 'unknown',
        /**
         * allergens legal status
         */
        statusAllergens?: 'valid' | 'invalid' | 'unknown',
        /**
         * allergens status
         */
        statusAllergensAnimal?: 'valid' | 'invalid' | 'unknown',
        /**
         * allergens status
         */
        statusAllergensLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * costs status
         */
        statusCosts?: 'valid' | 'invalid' | 'unknown',
        /**
         * ecoscore status
         */
        statusEcoscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironments?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironmentsCo2?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironmentsGnd?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironmentsH2Oc?: 'valid' | 'invalid' | 'unknown',
        /**
         * environments status
         */
        statusEnvironmentsPefScore?: 'valid' | 'invalid' | 'unknown',
        /**
         * Labels status
         */
        statusLabels?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusNames?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients status
         */
        statusNutrients?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients legal status
         */
        statusNutrientsLegal?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients used in nutriscore status
         */
        statusNutrientsNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutrients not legal status
         */
        statusNutrientsOther?: 'valid' | 'invalid' | 'unknown',
        /**
         * nutriscore status
         */
        statusNutriscore?: 'valid' | 'invalid' | 'unknown',
        /**
         * Origin status
         */
        statusOrigin?: 'valid' | 'invalid' | 'unknown',
        /**
         * parsing status (comma separated)
         */
        statusParsing?: 'valid' | 'invalid',
        /**
         * qunc status
         */
        statusQunc?: 'valid' | 'invalid' | 'unknown',
        /**
         * Seasonality status
         */
        statusSeasonality?: 'valid' | 'invalid' | 'unknown',
        /**
         * translation status (comma separated)
         */
        statusTranslations?: string,
        /**
         * product update status (comma separated)
         */
        statusUpdate?: 'undefined' | 'pending' | 'notified' | 'errors' | 'success' | '!undefined' | '!pending' | '!notified' | '!errors' | '!success',
        /**
         * Product Subtitle
         */
        subtitles?: string,
        /**
         * subtitles exists for these langs contains (comma separated)
         */
        subtitlesLang?: string,
        /**
         * product tags contains ALL of these  (comma separated)
         */
        tagsAll?: number,
        /**
         * product tags contains ANY of these  (comma separated)
         */
        tagsAny?: number,
        /**
         * product tags contains ALL of these  (comma separated)
         */
        tagsCodeAll?: string,
        /**
         * product tags contains ANY of these  (comma separated)
         */
        tagsCodeAny?: string,
        /**
         * product tags contains ANY of these  (comma separated)
         */
        tagsCodeExclAny?: string,
        /**
         * product tags contains ANY of these  (comma separated)
         */
        tagsExclAny?: number,
        /**
         * Product that are about to tip to the superior score
         */
        tippingEcoscore?: 'yes' | 'no',
        /**
         * Product that are about to tip to the superior score
         */
        tippingNutriscore?: 'yes' | 'no',
        /**
         * Product Title
         */
        titles?: string,
        /**
         * titles exists for these langs contains (comma separated)
         */
        titlesLang?: string,
        /**
         * product type  (comma separated)
         */
        type?: 'P' | 'R' | 'FT' | 'YMP' | '!P' | '!R' | '!FT' | '!YMP',
        /**
         * Product that have been updated since a given date
         */
        updatedAfter?: string,
        /**
         * Product that have been updated prior to a given date
         */
        updatedBefore?: string,
        /**
         * Retrieve all products used by this menu ids (comma separated)
         */
        usedByMenu?: number,
        /**
         * Retrieve all products used by this product ids (comma separated)
         */
        usedByProduct?: number,
        /**
         * Retrieve all product using this product ids (comma separated)
         */
        usingProduct?: number,
        /**
         * object UUID
         */
        uuid?: string,
        /**
         * filter on parsing with a specific concept group
         */
        withConceptGroup?: number,
    }): CancelablePromise<PaginatedProductSimpleList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/',
            query: {
                'EAN': ean,
                'allegations': allegations,
                'allegations_any': allegationsAny,
                'allegations_any_excl': allegationsAnyExcl,
                'allegations_excl': allegationsExcl,
                'allergens': allergens,
                'allergens_changes': allergensChanges,
                'allergens_potential_missing': allergensPotentialMissing,
                'available': available,
                'category': category,
                'children_category': childrenCategory,
                'children_company_id': childrenCompanyId,
                'children_company_name': childrenCompanyName,
                'children_company_path': childrenCompanyPath,
                'children_containsIngredient': childrenContainsIngredient,
                'children_deleted': childrenDeleted,
                'children_ecoscore': childrenEcoscore,
                'children_hasChild': childrenHasChild,
                'children_hasFile': childrenHasFile,
                'children_hasIngredient': childrenHasIngredient,
                'children_hasLIT': childrenHasLit,
                'children_hasParent': childrenHasParent,
                'children_has_any_label_ecoscore': childrenHasAnyLabelEcoscore,
                'children_has_unkownRecipe': childrenHasUnkownRecipe,
                'children_ids': childrenIds,
                'children_ids_list': childrenIdsList,
                'children_is_price_per_gr': childrenIsPricePerGr,
                'children_level': childrenLevel,
                'children_menu_id': childrenMenuId,
                'children_menus_after': childrenMenusAfter,
                'children_menus_before': childrenMenusBefore,
                'children_menus_company_id': childrenMenusCompanyId,
                'children_menus_flat_after': childrenMenusFlatAfter,
                'children_menus_flat_before': childrenMenusFlatBefore,
                'children_menus_flat_company_id': childrenMenusFlatCompanyId,
                'children_missing_LITs': childrenMissingLiTs,
                'children_missing_names': childrenMissingNames,
                'children_missing_titles': childrenMissingTitles,
                'children_natural': childrenNatural,
                'children_nutriscore': childrenNutriscore,
                'children_nutriscore_diff': childrenNutriscoreDiff,
                'children_nutriscore_raw': childrenNutriscoreRaw,
                'children_oids_list': childrenOidsList,
                'children_on_the_menu': childrenOnTheMenu,
                'children_order_by_ingr_food_cost': childrenOrderByIngrFoodCost,
                'children_order_by_ingr_quantity_sold': childrenOrderByIngrQuantitySold,
                'children_order_by_menuEntries_quantity': childrenOrderByMenuEntriesQuantity,
                'children_order_by_productLink_factorCooked': childrenOrderByProductLinkFactorCooked,
                'children_order_by_productLink_factorRaw': childrenOrderByProductLinkFactorRaw,
                'children_order_by_rec_quantity_sold': childrenOrderByRecQuantitySold,
                'children_order_by_recip_food_cost': childrenOrderByRecipFoodCost,
                'children_parsing_lang': childrenParsingLang,
                'children_parsing_trans': childrenParsingTrans,
                'children_purchase_price_local': childrenPurchasePriceLocal,
                'children_source': childrenSource,
                'children_status': childrenStatus,
                'children_status_LITs': childrenStatusLiTs,
                'children_status_allergens': childrenStatusAllergens,
                'children_status_allergens_animal': childrenStatusAllergensAnimal,
                'children_status_allergens_legal': childrenStatusAllergensLegal,
                'children_status_costs': childrenStatusCosts,
                'children_status_ecoscore': childrenStatusEcoscore,
                'children_status_environments': childrenStatusEnvironments,
                'children_status_environments_CO2': childrenStatusEnvironmentsCo2,
                'children_status_environments_GND': childrenStatusEnvironmentsGnd,
                'children_status_environments_H2OC': childrenStatusEnvironmentsH2Oc,
                'children_status_environments_PEF_SCORE': childrenStatusEnvironmentsPefScore,
                'children_status_labels': childrenStatusLabels,
                'children_status_names': childrenStatusNames,
                'children_status_nutrients': childrenStatusNutrients,
                'children_status_nutrients_legal': childrenStatusNutrientsLegal,
                'children_status_nutrients_nutriscore': childrenStatusNutrientsNutriscore,
                'children_status_nutrients_other': childrenStatusNutrientsOther,
                'children_status_nutriscore': childrenStatusNutriscore,
                'children_status_origin': childrenStatusOrigin,
                'children_status_parsing': childrenStatusParsing,
                'children_status_qunc': childrenStatusQunc,
                'children_status_seasonality': childrenStatusSeasonality,
                'children_status_translations': childrenStatusTranslations,
                'children_status_update': childrenStatusUpdate,
                'children_tags_all': childrenTagsAll,
                'children_tags_any': childrenTagsAny,
                'children_tags_code_all': childrenTagsCodeAll,
                'children_tags_code_any': childrenTagsCodeAny,
                'children_tags_code_excl_any': childrenTagsCodeExclAny,
                'children_tags_excl_any': childrenTagsExclAny,
                'children_tipping_ecoscore': childrenTippingEcoscore,
                'children_tipping_nutriscore': childrenTippingNutriscore,
                'children_type': childrenType,
                'children_usedByMenu': childrenUsedByMenu,
                'children_usedByProduct': childrenUsedByProduct,
                'children_usingProduct': childrenUsingProduct,
                'comment': comment,
                'company_id': companyId,
                'company_name': companyName,
                'company_path': companyPath,
                'conservation': conservation,
                'containsIngredient': containsIngredient,
                'coupling_has_EAN': couplingHasEan,
                'coupling_has_REF': couplingHasRef,
                'coupling_is_empty': couplingIsEmpty,
                'deleted': deleted,
                'ecoscore': ecoscore,
                'environment_levels': environmentLevels,
                'environments': environments,
                'generic': generic,
                'gln': gln,
                'hasChild': hasChild,
                'hasFactorCooked': hasFactorCooked,
                'hasFactorRaw': hasFactorRaw,
                'hasFile': hasFile,
                'hasIngredient': hasIngredient,
                'hasLIT': hasLit,
                'hasParent': hasParent,
                'has_any_label_ecoscore': hasAnyLabelEcoscore,
                'has_file_type': hasFileType,
                'has_fryed_ingredients': hasFryedIngredients,
                'has_fryed_productLink_factor': hasFryedProductLinkFactor,
                'has_frying_bath': hasFryingBath,
                'has_high_frying_bath_quantity': hasHighFryingBathQuantity,
                'has_productLink': hasProductLink,
                'has_productLink_factorRaw': hasProductLinkFactorRaw,
                'has_productLink_parent': hasProductLinkParent,
                'has_productLink_ymid': hasProductLinkYmid,
                'has_unkownRecipe': hasUnkownRecipe,
                'has_url': hasUrl,
                'high_environment_offer_ingredients': highEnvironmentOfferIngredients,
                'high_environment_offer_recipes': highEnvironmentOfferRecipes,
                'high_nutrient_offer_ingredients': highNutrientOfferIngredients,
                'high_nutrient_offer_recipes': highNutrientOfferRecipes,
                'high_purchase_price_offer_ingredients': highPurchasePriceOfferIngredients,
                'high_purchase_price_offer_recipes': highPurchasePriceOfferRecipes,
                'id': id,
                'ids': ids,
                'ids_list': idsList,
                'is_price_per_gr': isPricePerGr,
                'is_productLink_cooked': isProductLinkCooked,
                'is_veggie': isVeggie,
                'labels': labels,
                'labels_any': labelsAny,
                'labels_any_excl': labelsAnyExcl,
                'labels_excl': labelsExcl,
                'labels_excl_no_keys': labelsExclNoKeys,
                'labels_has_any': labelsHasAny,
                'level': level,
                'limit': limit,
                'locality': locality,
                'low_margin_offer_recipes': lowMarginOfferRecipes,
                'low_nutrient_offer_ingredients': lowNutrientOfferIngredients,
                'low_nutrient_offer_recipes': lowNutrientOfferRecipes,
                'margin': margin,
                'markets': markets,
                'menu_company_id': menuCompanyId,
                'menu_date': menuDate,
                'menu_id': menuId,
                'menus_after': menusAfter,
                'menus_before': menusBefore,
                'menus_company_id': menusCompanyId,
                'menus_flat_after': menusFlatAfter,
                'menus_flat_before': menusFlatBefore,
                'menus_flat_company_id': menusFlatCompanyId,
                'missing_LITs': missingLiTs,
                'missing_conversion': missingConversion,
                'missing_names': missingNames,
                'missing_titles': missingTitles,
                'names': names,
                'names_lang': namesLang,
                'natural': natural,
                'nutrient_all_missing': nutrientAllMissing,
                'nutrient_has_all': nutrientHasAll,
                'nutrient_has_any': nutrientHasAny,
                'nutrient_levels': nutrientLevels,
                'nutrient_not_all_missing': nutrientNotAllMissing,
                'nutrients': nutrients,
                'nutriscore': nutriscore,
                'nutriscore_diff': nutriscoreDiff,
                'nutriscore_raw': nutriscoreRaw,
                'oids_list': oidsList,
                'on_the_menu': onTheMenu,
                'order_by_ingr_food_cost': orderByIngrFoodCost,
                'order_by_ingr_quantity_sold': orderByIngrQuantitySold,
                'order_by_menuEntries_quantity': orderByMenuEntriesQuantity,
                'order_by_productLink_factorCooked': orderByProductLinkFactorCooked,
                'order_by_productLink_factorRaw': orderByProductLinkFactorRaw,
                'order_by_rec_quantity_sold': orderByRecQuantitySold,
                'order_by_recip_food_cost': orderByRecipFoodCost,
                'ordering': ordering,
                'origin': origin,
                'owned': owned,
                'packaging': packaging,
                'page': page,
                'page_size': pageSize,
                'parents_category': parentsCategory,
                'parents_company_id': parentsCompanyId,
                'parents_company_name': parentsCompanyName,
                'parents_company_path': parentsCompanyPath,
                'parents_containsIngredient': parentsContainsIngredient,
                'parents_ecoscore': parentsEcoscore,
                'parents_hasChild': parentsHasChild,
                'parents_hasFile': parentsHasFile,
                'parents_hasIngredient': parentsHasIngredient,
                'parents_hasLIT': parentsHasLit,
                'parents_hasParent': parentsHasParent,
                'parents_has_any_label_ecoscore': parentsHasAnyLabelEcoscore,
                'parents_has_unkownRecipe': parentsHasUnkownRecipe,
                'parents_ids': parentsIds,
                'parents_ids_list': parentsIdsList,
                'parents_is_price_per_gr': parentsIsPricePerGr,
                'parents_level': parentsLevel,
                'parents_menu_id': parentsMenuId,
                'parents_menus_after': parentsMenusAfter,
                'parents_menus_before': parentsMenusBefore,
                'parents_menus_company_id': parentsMenusCompanyId,
                'parents_menus_flat_after': parentsMenusFlatAfter,
                'parents_menus_flat_before': parentsMenusFlatBefore,
                'parents_menus_flat_company_id': parentsMenusFlatCompanyId,
                'parents_missing_LITs': parentsMissingLiTs,
                'parents_missing_names': parentsMissingNames,
                'parents_missing_titles': parentsMissingTitles,
                'parents_natural': parentsNatural,
                'parents_nutriscore': parentsNutriscore,
                'parents_nutriscore_diff': parentsNutriscoreDiff,
                'parents_nutriscore_raw': parentsNutriscoreRaw,
                'parents_oids_list': parentsOidsList,
                'parents_on_the_menu': parentsOnTheMenu,
                'parents_order_by_ingr_food_cost': parentsOrderByIngrFoodCost,
                'parents_order_by_ingr_quantity_sold': parentsOrderByIngrQuantitySold,
                'parents_order_by_menuEntries_quantity': parentsOrderByMenuEntriesQuantity,
                'parents_order_by_productLink_factorCooked': parentsOrderByProductLinkFactorCooked,
                'parents_order_by_productLink_factorRaw': parentsOrderByProductLinkFactorRaw,
                'parents_order_by_rec_quantity_sold': parentsOrderByRecQuantitySold,
                'parents_order_by_recip_food_cost': parentsOrderByRecipFoodCost,
                'parents_parsing_lang': parentsParsingLang,
                'parents_parsing_trans': parentsParsingTrans,
                'parents_purchase_price_local': parentsPurchasePriceLocal,
                'parents_source': parentsSource,
                'parents_status': parentsStatus,
                'parents_status_LITs': parentsStatusLiTs,
                'parents_status_allergens': parentsStatusAllergens,
                'parents_status_allergens_animal': parentsStatusAllergensAnimal,
                'parents_status_allergens_legal': parentsStatusAllergensLegal,
                'parents_status_costs': parentsStatusCosts,
                'parents_status_ecoscore': parentsStatusEcoscore,
                'parents_status_environments': parentsStatusEnvironments,
                'parents_status_environments_CO2': parentsStatusEnvironmentsCo2,
                'parents_status_environments_GND': parentsStatusEnvironmentsGnd,
                'parents_status_environments_H2OC': parentsStatusEnvironmentsH2Oc,
                'parents_status_environments_PEF_SCORE': parentsStatusEnvironmentsPefScore,
                'parents_status_labels': parentsStatusLabels,
                'parents_status_names': parentsStatusNames,
                'parents_status_nutrients': parentsStatusNutrients,
                'parents_status_nutrients_legal': parentsStatusNutrientsLegal,
                'parents_status_nutrients_nutriscore': parentsStatusNutrientsNutriscore,
                'parents_status_nutrients_other': parentsStatusNutrientsOther,
                'parents_status_nutriscore': parentsStatusNutriscore,
                'parents_status_origin': parentsStatusOrigin,
                'parents_status_parsing': parentsStatusParsing,
                'parents_status_qunc': parentsStatusQunc,
                'parents_status_seasonality': parentsStatusSeasonality,
                'parents_status_translations': parentsStatusTranslations,
                'parents_status_update': parentsStatusUpdate,
                'parents_tags_all': parentsTagsAll,
                'parents_tags_any': parentsTagsAny,
                'parents_tags_code_all': parentsTagsCodeAll,
                'parents_tags_code_any': parentsTagsCodeAny,
                'parents_tags_code_excl_any': parentsTagsCodeExclAny,
                'parents_tags_excl_any': parentsTagsExclAny,
                'parents_tipping_ecoscore': parentsTippingEcoscore,
                'parents_tipping_nutriscore': parentsTippingNutriscore,
                'parents_type': parentsType,
                'parents_usedByMenu': parentsUsedByMenu,
                'parents_usedByProduct': parentsUsedByProduct,
                'parents_usingProduct': parentsUsingProduct,
                'parsing_lang': parsingLang,
                'parsing_trans': parsingTrans,
                'prices': prices,
                'productLink_factorCooked_greaterThan': productLinkFactorCookedGreaterThan,
                'productLink_factorCooked_lowerThan': productLinkFactorCookedLowerThan,
                'productLink_factorRaw_greaterThan': productLinkFactorRawGreaterThan,
                'productLink_factorRaw_lowerThan': productLinkFactorRawLowerThan,
                'purchase_price': purchasePrice,
                'purchase_price_level': purchasePriceLevel,
                'purchase_price_local': purchasePriceLocal,
                'reference': reference,
                'same_country': sameCountry,
                'same_region': sameRegion,
                'same_subregion': sameSubregion,
                'search': search,
                'seasonal': seasonal,
                'seasonality': seasonality,
                'seasonality_na': seasonalityNa,
                'seasonality_recipe_month': seasonalityRecipeMonth,
                'source': source,
                'splitCount': splitCount,
                'splitIndex': splitIndex,
                'status': status,
                'status_LITs': statusLiTs,
                'status_allergens': statusAllergens,
                'status_allergens_animal': statusAllergensAnimal,
                'status_allergens_legal': statusAllergensLegal,
                'status_costs': statusCosts,
                'status_ecoscore': statusEcoscore,
                'status_environments': statusEnvironments,
                'status_environments_CO2': statusEnvironmentsCo2,
                'status_environments_GND': statusEnvironmentsGnd,
                'status_environments_H2OC': statusEnvironmentsH2Oc,
                'status_environments_PEF_SCORE': statusEnvironmentsPefScore,
                'status_labels': statusLabels,
                'status_names': statusNames,
                'status_nutrients': statusNutrients,
                'status_nutrients_legal': statusNutrientsLegal,
                'status_nutrients_nutriscore': statusNutrientsNutriscore,
                'status_nutrients_other': statusNutrientsOther,
                'status_nutriscore': statusNutriscore,
                'status_origin': statusOrigin,
                'status_parsing': statusParsing,
                'status_qunc': statusQunc,
                'status_seasonality': statusSeasonality,
                'status_translations': statusTranslations,
                'status_update': statusUpdate,
                'subtitles': subtitles,
                'subtitles_lang': subtitlesLang,
                'tags_all': tagsAll,
                'tags_any': tagsAny,
                'tags_code_all': tagsCodeAll,
                'tags_code_any': tagsCodeAny,
                'tags_code_excl_any': tagsCodeExclAny,
                'tags_excl_any': tagsExclAny,
                'tipping_ecoscore': tippingEcoscore,
                'tipping_nutriscore': tippingNutriscore,
                'titles': titles,
                'titles_lang': titlesLang,
                'type': type,
                'updated_after': updatedAfter,
                'updated_before': updatedBefore,
                'usedByMenu': usedByMenu,
                'usedByProduct': usedByProduct,
                'usingProduct': usingProduct,
                'uuid': uuid,
                'with_conceptGroup': withConceptGroup,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiCreate({
        requestBody,
    }: {
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/productAPI/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3ProductApiDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/productAPI/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiArchiveCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/{id}/archive/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiComputeQuantitiesCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/{id}/compute_quantities/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiCopyCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/{id}/copy/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiCoupleCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/{id}/couple/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductLink
     * @throws ApiError
     */
    public static apiV3ProductApiCoupleToNewCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody?: ProductLinkRequest,
    }): CancelablePromise<ProductLink> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/{id}/couple_to_new/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportExcelRetrieveDetail({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/{id}/export/excel/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportJpegRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/{id}/export/jpeg/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportLegalExcelRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/{id}/export/legal/excel/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportPdfRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/{id}/export/pdf/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportPngRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/{id}/export/png/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportPurchaseListRetrieve({
        id,
        expandRecipe,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        expandRecipe?: boolean,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/{id}/export/purchase_list/',
            path: {
                'id': id,
            },
            query: {
                'expandRecipe': expandRecipe,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiMoveCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/{id}/move/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiNutriscoreTippingPointsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/{id}/nutriscore/tippingPoints/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiRefreshCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/{id}/refresh/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiReplaceIngredientCreate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/{id}/replace_ingredient/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiVerifDeleteRetrieveDetail({
        id,
    }: {
        /**
         * A unique integer value identifying this product.
         */
        id: number,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/{id}/verif_delete/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiArchiveMultiCreate({
        ids,
        requestBody,
    }: {
        /**
         * comma separated list of product ids
         */
        ids: string,
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/archive_multi/',
            query: {
                'ids': ids,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiCopyMultiCreate({
        requestBody,
    }: {
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/copy_multi/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3ProductApiDeleteMultiDestroy({
        ids,
    }: {
        /**
         * comma separated list of product ids
         */
        ids: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/productAPI/delete_multi/',
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportExcelRetrieve({
        fieldsToExclude,
        includeIngredients,
        langsToExclude,
    }: {
        /**
         * comma separated list of fields to exclude in export
         */
        fieldsToExclude?: string,
        includeIngredients?: boolean,
        /**
         * comma separated list of lang to exclude in export
         */
        langsToExclude?: string,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/export/excel/',
            query: {
                'fieldsToExclude': fieldsToExclude,
                'includeIngredients': includeIngredients,
                'langsToExclude': langsToExclude,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportExcelEgalimLabelRetrieve(): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/export/excel_egalim_label/',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportExcelMenuDiversityRetrieve(): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/export/excel_menu_diversity/',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportExcelMenuOccurencesRetrieve(): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/export/excel_menu_occurences/',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportExcelProductDiversityRetrieve(): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/export/excel_product_diversity/',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiExportExcelReferencesRetrieve(): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/export/excel_references/',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiGetProductsCreate({
        requestBody,
    }: {
        requestBody: GetProductsRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/get_products/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiMoveMultiCreate({
        requestBody,
    }: {
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/move_multi/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiParseCreate({
        requestBody,
    }: {
        requestBody: ProductSimpleRequest,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productAPI/parse/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductAllUnavailability
     * @throws ApiError
     */
    public static apiV3ProductApiUnavailabilitiesRetrieve({
        ids,
        after,
    }: {
        /**
         * comma separated list of product ids
         */
        ids: string,
        /**
         * end of unavailability after a given date
         */
        after?: string,
    }): CancelablePromise<ProductAllUnavailability> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/unavailabilities/',
            query: {
                'after': after,
                'ids': ids,
            },
        });
    }

    /**
     * @returns ProductSimple
     * @throws ApiError
     */
    public static apiV3ProductApiVerifDeleteRetrieve({
        ids,
    }: {
        /**
         * comma separated list of product ids
         */
        ids: string,
    }): CancelablePromise<ProductSimple> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productAPI/verif_delete/',
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * @returns PaginatedProductCategoryList
     * @throws ApiError
     */
    public static apiV3ProductCategoryList({
        ids,
        ordering,
        page,
        pageSize,
    }: {
        /**
         * Multiple values may be separated by commas.
         */
        ids?: Array<string>,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedProductCategoryList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productCategory/',
            query: {
                'ids': ids,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns ProductCategory
     * @throws ApiError
     */
    public static apiV3ProductCategoryCreate({
        requestBody,
    }: {
        requestBody: ProductCategoryRequest,
    }): CancelablePromise<ProductCategory> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productCategory/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductCategory
     * @throws ApiError
     */
    public static apiV3ProductCategoryRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this product category.
         */
        id: number,
    }): CancelablePromise<ProductCategory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productCategory/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProductCategory
     * @throws ApiError
     */
    public static apiV3ProductCategoryPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product category.
         */
        id: number,
        requestBody: ProductCategoryRequest,
    }): CancelablePromise<ProductCategory> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/productCategory/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3ProductCategoryDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product category.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/productCategory/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of files associated to a product (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific file associated to a product.
     *
     * create:
     * Create a new file associated to a product.
     * Data must be encoded in multipart/form-data
     *
     * delete:
     * Remove an existing file associated to a product.
     *
     * partial_update:
     * Update one or more fields on an existing file associated to a product.
     * Data must be encoded in multipart/form-data
     *
     * update:
     * Update an file associated to a product.
     * Data must be encoded in multipart/form-data
     * @returns PaginatedProductFileList
     * @throws ApiError
     */
    public static apiV3ProductFileList({
        owned,
        page,
        pageSize,
        productIds,
    }: {
        owned?: boolean,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * object Id (comma separated)
         */
        productIds?: string,
    }): CancelablePromise<PaginatedProductFileList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productFile/',
            query: {
                'owned': owned,
                'page': page,
                'page_size': pageSize,
                'product_ids': productIds,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of files associated to a product (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific file associated to a product.
     *
     * create:
     * Create a new file associated to a product.
     * Data must be encoded in multipart/form-data
     *
     * delete:
     * Remove an existing file associated to a product.
     *
     * partial_update:
     * Update one or more fields on an existing file associated to a product.
     * Data must be encoded in multipart/form-data
     *
     * update:
     * Update an file associated to a product.
     * Data must be encoded in multipart/form-data
     * @returns ProductFile
     * @throws ApiError
     */
    public static apiV3ProductFileCreate({
        formData,
    }: {
        formData: ProductFileRequest,
    }): CancelablePromise<ProductFile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productFile/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * list:
     * Retrieve the list of files associated to a product (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific file associated to a product.
     *
     * create:
     * Create a new file associated to a product.
     * Data must be encoded in multipart/form-data
     *
     * delete:
     * Remove an existing file associated to a product.
     *
     * partial_update:
     * Update one or more fields on an existing file associated to a product.
     * Data must be encoded in multipart/form-data
     *
     * update:
     * Update an file associated to a product.
     * Data must be encoded in multipart/form-data
     * @returns ProductFile
     * @throws ApiError
     */
    public static apiV3ProductFilePartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this product file.
         */
        id: number,
        formData: ProductFileRequest,
    }): CancelablePromise<ProductFile> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/productFile/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * list:
     * Retrieve the list of files associated to a product (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific file associated to a product.
     *
     * create:
     * Create a new file associated to a product.
     * Data must be encoded in multipart/form-data
     *
     * delete:
     * Remove an existing file associated to a product.
     *
     * partial_update:
     * Update one or more fields on an existing file associated to a product.
     * Data must be encoded in multipart/form-data
     *
     * update:
     * Update an file associated to a product.
     * Data must be encoded in multipart/form-data
     * @returns void
     * @throws ApiError
     */
    public static apiV3ProductFileDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product file.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/productFile/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityCostsRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/costs/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityCostsCompleteRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/costs/complete/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityCostsMarginRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/costs/margin/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityCostsPurchasePriceRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/costs/purchase_price/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDataQualityRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/data_quality/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDataQualityAllergensRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/data_quality/allergens/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDataQualityEnvironmentsRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/data_quality/environments/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDataQualityMenusRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/data_quality/menus/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDataQualityNamesRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/data_quality/names/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDataQualityNutrientsRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/data_quality/nutrients/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDataQualityProductsRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/data_quality/products/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDataQualityRecipesRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/data_quality/recipes/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDataQualityUnitsInGramsRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/data_quality/units_in_grams/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDiversityRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/diversity/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDiversityAllergensRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/diversity/allergens/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDiversityCharacteristicsRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/diversity/characteristics/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDiversityLabelsRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/diversity/labels/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityDiversityOccurencesRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/diversity/occurences/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityEgalimRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/egalim/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityEgalimInformRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/egalim/inform/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityEgalimLabelsRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/egalim/labels/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityEgalimVeggieRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/egalim/veggie/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityEnvironmentsRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/environments/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityEnvironmentsCo2Retrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/environments/CO2/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityEnvironmentsGndRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/environments/GND/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityEnvironmentsH2OcRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/environments/H2OC/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityEnvironmentsEcoscoreRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/environments/ecoscore/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityGemrcnRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/gemrcn/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityGemrcnCategorizeRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/gemrcn/categorize/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityGemrcnImproveRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/gemrcn/improve/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityGemrcnPortionsRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/gemrcn/portions/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityGlobalStatusRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/global_status/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityNutrientsRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/nutrients/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityNutrientsFasatRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/nutrients/FASAT/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityNutrientsProcntRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/nutrients/PROCNT/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityNutrientsSalteqRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/nutrients/SALTEQ/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityNutrientsSugarRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/nutrients/SUGAR/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityNutrientsNutriscoreRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/nutrients/nutriscore/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityOriginRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/origin/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityOriginDocumentRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/origin/document/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityOriginImproveRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/origin/improve/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityScopeRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/scope/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualitySeasonalityRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/seasonality/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualitySeasonalityDocumentRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/seasonality/document/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualitySeasonalityImproveRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/seasonality/improve/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityUnavailabilityRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/unavailability/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityUnavailabilityImproveRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/unavailability/improve/',
        });
    }

    /**
     * @returns Menu
     * @throws ApiError
     */
    public static apiV3ProductQualityUnavailabilityVerifyRetrieve(): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productQuality/unavailability/verify/',
        });
    }

    /**
     * list:
     * Retrieve the list of tags (possibly filtered, ordered and paginated by query parameters).
     *
     * retrieve:
     * Retrieve all information about a specific tags.
     *
     * create:
     * Create a new tags.
     *
     * delete:
     * Remove an existing tags.
     *
     * partial_update:
     * Update one or more fields on an existing tags.
     *
     * update:
     * Update an tags.
     * @returns PaginatedProductTagList
     * @throws ApiError
     */
    public static apiV3ProductTagList({
        code,
        codeStartswith,
        forPref,
        guestSpec,
        id,
        ids,
        kind,
        ordering,
        owned,
        page,
        pageSize,
        search,
    }: {
        /**
         * code associated to the tag (comma separated)
         */
        code?: string,
        /**
         * code associated to the tag (comma separated)
         */
        codeStartswith?: string,
        /**
         * for pref tags
         */
        forPref?: 'true' | 'false' | 'unknown',
        /**
         * Filter tags belonging to a specific guest specification : guest_spec=gemrcn
         */
        guestSpec?: string,
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * kind of tag (comma separated)
         */
        kind?: any,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        owned?: boolean,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedProductTagList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productTag/',
            query: {
                'code': code,
                'code__startswith': codeStartswith,
                'for_pref': forPref,
                'guest_spec': guestSpec,
                'id': id,
                'ids': ids,
                'kind': kind,
                'ordering': ordering,
                'owned': owned,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of product unavailabilities (possibly filtered, ordered and paginated by query parameters).
     *
     * create:
     * Create a new product unavailability.
     *
     * delete:
     * Remove an existing product unavailability.
     *
     * update:
     * Update an product unavailabilities.
     * @returns ProductUnavailability
     * @throws ApiError
     */
    public static apiV3ProductUnavailabilityList({
        after,
        owned,
        product,
        productIds,
    }: {
        /**
         * end of unavailability after a given date
         */
        after?: string,
        owned?: boolean,
        /**
         * filter unavailabilities of a product
         */
        product?: string,
        /**
         * filter unavailabilities of a product (comma separated)
         */
        productIds?: string,
    }): CancelablePromise<Array<ProductUnavailability>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/productUnavailability/',
            query: {
                'after': after,
                'owned': owned,
                'product': product,
                'product_ids': productIds,
            },
        });
    }

    /**
     * list:
     * Retrieve the list of product unavailabilities (possibly filtered, ordered and paginated by query parameters).
     *
     * create:
     * Create a new product unavailability.
     *
     * delete:
     * Remove an existing product unavailability.
     *
     * update:
     * Update an product unavailabilities.
     * @returns ProductUnavailability
     * @throws ApiError
     */
    public static apiV3ProductUnavailabilityCreate({
        requestBody,
    }: {
        requestBody: ProductUnavailabilityRequest,
    }): CancelablePromise<ProductUnavailability> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/productUnavailability/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * list:
     * Retrieve the list of product unavailabilities (possibly filtered, ordered and paginated by query parameters).
     *
     * create:
     * Create a new product unavailability.
     *
     * delete:
     * Remove an existing product unavailability.
     *
     * update:
     * Update an product unavailabilities.
     * @returns ProductUnavailability
     * @throws ApiError
     */
    public static apiV3ProductUnavailabilityPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this product unavailability.
         */
        id: number,
        requestBody: ProductUnavailabilityRequest,
    }): CancelablePromise<ProductUnavailability> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/productUnavailability/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * list:
     * Retrieve the list of product unavailabilities (possibly filtered, ordered and paginated by query parameters).
     *
     * create:
     * Create a new product unavailability.
     *
     * delete:
     * Remove an existing product unavailability.
     *
     * update:
     * Update an product unavailabilities.
     * @returns void
     * @throws ApiError
     */
    public static apiV3ProductUnavailabilityDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this product unavailability.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/productUnavailability/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PublicCompany
     * @throws ApiError
     */
    public static apiV3PublicCompanyList({
        uuid,
    }: {
        uuid: string,
    }): CancelablePromise<Array<PublicCompany>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/public/company/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns PublicMenu
     * @throws ApiError
     */
    public static apiV3PublicMenuRetrieve({
        uuid,
    }: {
        uuid: string,
    }): CancelablePromise<PublicMenu> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/public/menu/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns PublicMenu
     * @throws ApiError
     */
    public static apiV3PublicMenuCompanyList({
        uuid,
        date,
    }: {
        uuid: string,
        /**
         * retrieve menu around this date
         */
        date?: string,
    }): CancelablePromise<Array<PublicMenu>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/public/menu/{uuid}/company/',
            path: {
                'uuid': uuid,
            },
            query: {
                'date': date,
            },
        });
    }

    /**
     * @returns Message
     * @throws ApiError
     */
    public static apiV3PublicMenuDishCreate({
        uuid,
        requestBody,
    }: {
        uuid: string,
        requestBody: PublicDishRequest,
    }): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/public/menu/{uuid}/dish/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Message
     * @throws ApiError
     */
    public static apiV3PublicMenuOrderCreate({
        uuid,
        requestBody,
    }: {
        uuid: string,
        requestBody: PublicOrderRequest,
    }): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/public/menu/{uuid}/order/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PublicDishAndOrder
     * @throws ApiError
     */
    public static apiV3PublicMenuDishesAndOrdersCreate({
        requestBody,
    }: {
        requestBody: MenuIdsRequest,
    }): CancelablePromise<PublicDishAndOrder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/public/menu/dishesAndOrders/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PublicProduct
     * @throws ApiError
     */
    public static apiV3PublicProductRetrieve({
        uuid,
    }: {
        uuid: string,
    }): CancelablePromise<PublicProduct> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/public/product/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns PaginatedPurchasePriceList
     * @throws ApiError
     */
    public static apiV3PurchasePriceList({
        id,
        ids,
        names,
        ordering,
        page,
        pageSize,
        search,
    }: {
        id?: number,
        /**
         * Multiple values may be separated by commas.
         */
        ids?: Array<string>,
        names?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedPurchasePriceList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/purchasePrice/',
            query: {
                'id': id,
                'ids': ids,
                'names': names,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns PaginatedPurchasePriceGroupList
     * @throws ApiError
     */
    public static apiV3PurchasePriceGroupList({
        id,
        ids,
        ordering,
        page,
        pageSize,
        search,
        source,
        status,
        type,
    }: {
        /**
         * object Id
         */
        id?: string,
        /**
         * object Id (comma separated)
         */
        ids?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
        /**
         * Filter on source (comma separated)
         */
        source?: string,
        /**
         * Filter on status (comma separated)
         */
        status?: string,
        /**
         * Filter on type (comma separated)
         */
        type?: string,
    }): CancelablePromise<PaginatedPurchasePriceGroupList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/purchasePriceGroup/',
            query: {
                'id': id,
                'ids': ids,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
                'source': source,
                'status': status,
                'type': type,
            },
        });
    }

    /**
     * @returns PurchasePriceGroup
     * @throws ApiError
     */
    public static apiV3PurchasePriceGroupCreate({
        requestBody,
    }: {
        requestBody?: PurchasePriceGroupRequest,
    }): CancelablePromise<PurchasePriceGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/purchasePriceGroup/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PurchasePriceGroup
     * @throws ApiError
     */
    public static apiV3PurchasePriceGroupRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this purchase price group.
         */
        id: number,
    }): CancelablePromise<PurchasePriceGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/purchasePriceGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PurchasePriceGroup
     * @throws ApiError
     */
    public static apiV3PurchasePriceGroupPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this purchase price group.
         */
        id: number,
        requestBody?: PurchasePriceGroupRequest,
    }): CancelablePromise<PurchasePriceGroup> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/purchasePriceGroup/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3PurchasePriceGroupDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this purchase price group.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/purchasePriceGroup/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedTaxonomyList
     * @throws ApiError
     */
    public static apiV3TaxonomyList({
        ids,
        name,
        ordering,
        page,
        pageSize,
        search,
    }: {
        /**
         * Multiple values may be separated by commas.
         */
        ids?: Array<string>,
        name?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedTaxonomyList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/taxonomy/',
            query: {
                'ids': ids,
                'name': name,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns Taxonomy
     * @throws ApiError
     */
    public static apiV3TaxonomyCreate({
        requestBody,
    }: {
        requestBody: TaxonomyRequest,
    }): CancelablePromise<Taxonomy> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/taxonomy/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Taxonomy
     * @throws ApiError
     */
    public static apiV3TaxonomyRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this taxonomy.
         */
        id: number,
    }): CancelablePromise<Taxonomy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/taxonomy/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Taxonomy
     * @throws ApiError
     */
    public static apiV3TaxonomyPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this taxonomy.
         */
        id: number,
        requestBody: TaxonomyRequest,
    }): CancelablePromise<Taxonomy> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/taxonomy/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3TaxonomyDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this taxonomy.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/taxonomy/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Taxonomy
     * @throws ApiError
     */
    public static apiV3TaxonomyM2MPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this taxonomy.
         */
        id: number,
        requestBody: TaxonomyRequest,
    }): CancelablePromise<Taxonomy> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/taxonomy/{id}/M2M/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Taxonomy
     * @throws ApiError
     */
    public static apiV3TaxonomyAllergensRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this taxonomy.
         */
        id: number,
    }): CancelablePromise<Taxonomy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/taxonomy/{id}/allergens/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Taxonomy
     * @throws ApiError
     */
    public static apiV3TaxonomyMandatoryTypesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this taxonomy.
         */
        id: number,
    }): CancelablePromise<Taxonomy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/taxonomy/{id}/mandatoryTypes/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Taxonomy
     * @throws ApiError
     */
    public static apiV3TaxonomyRoutingsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this taxonomy.
         */
        id: number,
    }): CancelablePromise<Taxonomy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/taxonomy/{id}/routings/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Taxonomy
     * @throws ApiError
     */
    public static apiV3TaxonomyStateRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this taxonomy.
         */
        id: number,
    }): CancelablePromise<Taxonomy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/taxonomy/{id}/state/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TrackAction
     * @throws ApiError
     */
    public static apiV3TrackActionCreate({
        requestBody,
    }: {
        requestBody: TrackActionRequest,
    }): CancelablePromise<TrackAction> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/track/action/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PaginatedTranslationsList
     * @throws ApiError
     */
    public static apiV3TranslationsList({
        ordering,
        page,
        pageSize,
        search,
    }: {
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedTranslationsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/translations/',
            query: {
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns Translations
     * @throws ApiError
     */
    public static apiV3TranslationsCreate({
        requestBody,
    }: {
        requestBody: TranslationsRequest,
    }): CancelablePromise<Translations> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/translations/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Translations
     * @throws ApiError
     */
    public static apiV3TranslationsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this translations.
         */
        id: number,
    }): CancelablePromise<Translations> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/translations/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Translations
     * @throws ApiError
     */
    public static apiV3TranslationsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this translations.
         */
        id: number,
        requestBody: TranslationsRequest,
    }): CancelablePromise<Translations> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/translations/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3TranslationsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this translations.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/translations/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Translations
     * @throws ApiError
     */
    public static apiV3TranslationsExportRetrieve({
        transLang,
    }: {
        transLang: string,
    }): CancelablePromise<Translations> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/translations/export/{trans_lang}/',
            path: {
                'trans_lang': transLang,
            },
        });
    }

    /**
     * @returns TranslationsImport
     * @throws ApiError
     */
    public static apiV3TranslationsImportCreate({
        formData,
    }: {
        formData: TranslationsImportRequest,
    }): CancelablePromise<TranslationsImport> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/translations/import/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns PaginatedUnitList
     * @throws ApiError
     */
    public static apiV3UnitList({
        forConversion,
        forPurchase,
        ids,
        name,
        ordering,
        page,
        pageSize,
        search,
    }: {
        forConversion?: boolean,
        forPurchase?: boolean,
        /**
         * Multiple values may be separated by commas.
         */
        ids?: Array<string>,
        name?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedUnitList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/unit/',
            query: {
                'for_conversion': forConversion,
                'for_purchase': forPurchase,
                'ids': ids,
                'name': name,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns Unit
     * @throws ApiError
     */
    public static apiV3UnitCreate({
        requestBody,
    }: {
        requestBody: UnitRequest,
    }): CancelablePromise<Unit> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/unit/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Unit
     * @throws ApiError
     */
    public static apiV3UnitRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this unit.
         */
        id: number,
    }): CancelablePromise<Unit> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/unit/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Unit
     * @throws ApiError
     */
    public static apiV3UnitPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this unit.
         */
        id: number,
        requestBody: UnitRequest,
    }): CancelablePromise<Unit> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/unit/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static apiV3UnitDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this unit.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/unit/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
