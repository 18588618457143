export function global_set(stateLoc: string | Array<string>, data: any) {
  return { type: "GLOBAL_SET", stateLoc: stateLoc, data: data };
}

export function global_del(stateLoc: string | Array<string>) {
  return { type: "GLOBAL_DEL", stateLoc: stateLoc };
}

export function global_merge(stateLoc: string | Array<string>, data: object) {
  return { type: "GLOBAL_MERGE", stateLoc: stateLoc, data: data };
}
