import { useEffect, useCallback } from "react";
import { openDB, DBSchema } from "idb";
import { useSelector, useDispatch } from "react-redux";
import { global_set } from "store/actions";
import { RootState } from "store/state";
import * as _ from "lodash";

interface MyDB extends DBSchema {
  objects: {
    value: any;
    key: string;
    //indexes: { 'by-price': number };
  };
}

async function db_init() {
  let dbp = await openDB<MyDB>("my-db", 1.1, {
    upgrade(db) {
      db.createObjectStore("objects");
      //const objectStore = db.createObjectStore('objects');
      //objectStore.createIndex('by-price', 'price');
    },
  });
  return dbp;
}

let dbp = db_init();

// Hook
export const usePersistentState = function <T>(
  key: string,
  initialState: T | undefined = undefined
) {
  let dispatch = useDispatch();
  let item: T | undefined = useSelector(
    (state: RootState) => state?.pstate?.[key],
    _.isEqual
  ) as T | undefined;

  useEffect(() => {
    const asyncFunc = async function () {
      const db = await dbp;
      const value: T | undefined = await db.get("objects", key);
      if (value === undefined) {
        dispatch(global_set(["pstate", key], initialState));
      } else {
        dispatch(global_set(["pstate", key], value));
      }
      return value;
    };
    asyncFunc();
  }, [key, initialState, dispatch]);

  const setItem = useCallback(
    async function (value: T | undefined) {
      dispatch(global_set(["pstate", key], value));
      const db = await dbp;
      if (value !== undefined) {
        return await db.put("objects", value, key);
      } else {
        return await db.delete("objects", key);
      }
    },
    [key, dispatch]
  );

  return {
    item,
    setItem,
  };
};

export default usePersistentState;
